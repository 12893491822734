import {createApp} from 'vue'
import App from './App.vue'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import store from './store'
import {createGtm} from "vue-gtm";

import '@/assets/style/main.css'
const app = createApp(App)
app.use(store)
app.use(createGtm({id: 'GTM-5K97RBN'}))
app.mount('#app')
