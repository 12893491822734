export default class MilenaMeeting {
	constructor (gameData) {
		this.gameData = gameData
	}

	events () {
		return [
			{
				id: 'start',
				speaker: 'Meeting Room',
				text: ['You enter the meeting room where you are scheduled to meet with the client at 11:00 AM. Roger seems to be already here.'],
				choices: [
					{
						id: 'wait',
						name: 'Enter the meeting room and wait with Roger',
					},
					{
						id: 'go_back',
						name: 'Go back',
					},
				],
			},

			{
				id: 'wait',
				speaker: 'Roger',
				text: [
					'He gives you a smile as you find yourself a seat'
				],
				continue_to: 'sitting_down',
			},
			{
				id: 'sitting_down',
				speaker: 'Roger',
				text: ['I hope you are prepared for this meeting. I had to pull a lot of strings with the other partners to keep you in the team after what happened at Life Labs.'],
				continue_to: 'life_labs_memory'
			},
			{
				id: 'life_labs_memory',
				speaker: 'Memory',
				text: [
					'Life Labs Inc. is the name of one of the firm\'s most important and lucrative clients',
					'You were supposed to be the leading the marketing efforts on their new life extension project.',
					'You were going to pitch your best ideas and win this large project for the firm.'
				],
				continue_to: 'fucked_it_up'
			},
			{
				id: 'fucked_it_up',
				speaker: 'Memory',
				text: [
					'And you fucked it up.',
					'On the day of the big presentation, you disappeared.'
				],
				choices: [{
					id: 'how_disappear',
					name: 'How can I just disappear?'
				}]
			},
			{
				id: 'how_disappear',
				speaker: 'Memory',
				text: [
					'You entered the office with Mr. Slattery and Mr. Morse and the rest of your colleagues.',
					'You probably drank a lot of water, so you excused yourself to use the restroom right before the pitch meeting was about to start',
					'And then you never showed',
				],
				continue_to: 'passed_out'
			},
			{
				id: 'passed_out',
				speaker: 'Memory',
				text: [
					'You were found passed out in one of the hallways 2 hours later with no recollection of what happened since you arrived.',
					'Regardless, the damage was done. Roger had to do the presentation that he was unprepared for and the firm never landed the project.'
				],
				choices: [
					{
						id: 'what_happened_after',
						name: 'What happened after that?'
					}
				]
			},
			{
				id: 'what_happened_after',
				speaker: 'Memory',
				text: [
					'Mr. Morse and the other partners wanted you fired for not showing up. But Roger vetoed them and has given you one last chance for you to get back on your feet',
				],
				continue_to: 'analysis_on_roger'
			},
			{
				id: 'analysis_on_roger',
				speaker: 'Analysis',
				text: [
					'Roger has seen your work in the past and knows about what happened with Amanda and how that affected you.',
					'He is betting on you hoping you would overcome your past and get back on your feet. This new client that you are about to pitch to is your last chance.',
					'Today is the day that your fate in this company is decided.'
				],
				continue_to: 'time_to_answer'
			},
			{
				id: 'time_to_answer',
				speaker: 'Roger',
				text: [
					'Time to answer his question: Have you prepared for the meeting?'
				],
				choices: [
					{
						id: 'yes',
						name: '"Yes I have. We are going to land this client"'
					},
					{
						id: 'no',
						name: '"Not really no. I was going to get my information directly from the source."'
					}
				]
			},
			{
				id: 'yes',
				speaker: 'Roger',
				set_game_data: {
					roger_backstory_conversation_completed: true,
					xp_gain: 5,
				},
				text: [
					'"Great. Should this meeting go well, I\'ll arrange for some new work with Life Labs. I still have some connections there that trust me."'
				],
				continue_to: 'wait_here'
			},

			{
				id: 'no',
				speaker: 'Roger',
				set_game_data: {
					roger_backstory_conversation_completed: true,
					xp_gain: 5,
				},
				text: [
					'"What? That sounds awfully reckless. I hope you realize your career with the firm is on the line."',
				],
				continue_to: 'wait_here'
			},
			{
				id: 'wait_here',
				speaker: 'Meeting Room',
				advance_time: this.timeToStart(),
				text: ['It\'s time for the meeting'],
				continue_to: 'meeting_start',
			},
			{
				id: 'go_back',
				speaker: 'Meeting Room',
				text: ['You leave the meeting room'],
				continue_to: 'the_end'
			},
			{
				id: 'meeting_start',
				speaker: 'Meeting Room',
				text: ['As you and your team awaits the client, Roger\'s assistant brings Milena and another young woman into the meeting room and helps them to their seats'],
				choices: [
					{
						id: 'handshake',
						name: '"You must be Ms. Milena. I am Nathan Spade. This is Brenda and Jonah, my team." (Reach out and shake her hands)',
					},
				],
			},
			{
				id: 'handshake',
				speaker: 'Milena',
				text: ['"Yes. This is Rachel my assistant.", she says giving you firm confident handshake.'],
				choices: [
					{
						id: 'hey_rachel',
						name: '"Hey Rachel"',
					},
				],
			},
			{
				id: 'hey_rachel',
				speaker: 'Rachel',
				text: ['"Hi Nathan. Hello Brenda. Hi Jonah", she says.'],
				continue_to: 'roger_intro'
			},
			{
				id: 'roger_intro',
				speaker: 'Roger',
				text: ['"Now that\'s everyone settled in, Nathan, why don\'t we start?"'],
				choices: this.meetingStartChoices(),
			},
			{
				id: 'present_the_case',
				speaker: 'Roger',
				text: ['Roger stares at you realizing that you haven\'t seen the profile yet'],
				continue_to: 'milena_presents'
			},
			{
				id: 'milena_presents',
				speaker: 'Milena',
				text: ['"Rachel, can you explain to the gentlemen and the lady about our business?"'],
				continue_to: 'rachel_presents'
			},
			{
				id: 'rachel_presents',
				speaker: 'Rachel',
				text: [
					'"Sure Milena. Our company is called Sensual Explorations, founded about 3 years ago by Milena here. Her husband Mr. Milton Hayes is a silent partner in the company."',
				],
				continue_to: 'rachel_presents_1'
			},
			{
				id: 'rachel_presents_1',
				speaker: 'Rachel',
				text: [
					'"Over the last 2 years, our design team has come up with 2 very good products called Aphrodite and Hermes"',
				],
				continue_to: 'rachel_presents_2'
			},
			{
				id: 'rachel_presents_2',
				speaker: 'Rachel',
				text: [
					'"Both of our products have tested very well in terms of user satisfaction in early customer reviews."',
				],
				continue_to: 'rachel_presents_3'
			},
			{
				id: 'rachel_presents_3',
				speaker: 'Rachel',
				text: [
					'"Aphrodite is a remote controlled vibrator-cum-massager for women. Hermes is a prostate massager for men."',
				],
				continue_to: 'rachel_presents_4'
			},
			{
				id: 'rachel_presents_4',
				speaker: 'Rachel',
				text: [
					'"What we want from your company, is a go-to-market strategy to make our products successful"',
				],
				continue_to: 'analysis_gtm'
			},
			{
				id: 'analysis_gtm',
				skill_required: 'mental.analysis',
				score_required: 9,
				check_type: 'passive',
				fail_node: 'jonah_prods',
				text: [
					'There\'s an important detail she left out',
					'What are they going to charge for their products?',
					'It\'s a critical piece of the puzzle.'
				],
				speaker: 'Analysis',
				choices: [
					{
						id: 'ask_about_pricing',
						name: '"You didn\'t mention anything about the prices you want to charge?"'
					}
				]
			},
			{
				id: 'jonah_prods',
				speaker: 'Jonah',
				text: ['Jonah, who up until now was taking diligent notes, slightly prods you with his elbow to get your attention to the screen of his laptop'],
				choices: [
					{
						id: 'look_at_the_screen',
						name: 'Look at the screen'
					}
				]
			},
			{
				id: 'look_at_the_screen',
				speaker: 'Jonah\'s Laptop',
				text: [
					'His screen reads in all caps, "WHAT ABOUT PRICING?"',
				],
				choices: [
					{
						id: 'ask_about_pricing',
						name: '"You didn\'t mention anything about the prices you want to charge?"'
					}
				]
			},
			{
				id: 'ask_about_pricing',
				speaker: 'Milena',
				text: ['"At the moment, we don\'t have anything concrete. We were hoping you could help us."'],
				choices: [
					{
						id: 'ideal_cust',
						name: '"Of course we can. What can you tell us about your ideal customer?"',
					},
					{
						id: 'premium_prices',
						name: '"Based on your product, I can surmise that it\'s designed rather tastefully for the modern individual. Therefore, you should charge premium prices - highest in the segment."',
					},
				],
			},
			{
				id: 'ideal_cust',
				speaker: 'Milena',
				text: ['"We designed it for ourselves. Something that we would like to use. But we want the rest of the world enjoy it as well. That\'s why we made it."'],
				choices: [
					{
						id: 'rich_customer',
						name: '"That means the ideal customer is confident, has a taste of art and unapologetic in pursuing their desires"',
					},
				],
			},
			{
				id: 'rich_customer',
				speaker: 'Milena',
				text: ['"You could say that."'],
				choices: [
					{
						id: 'buy_because_expensive',
						name: '"That means your product isn\'t be about pleasure. Your product is about the customer knowing he or she is getting the best and not something that is average. That\'s what you are selling. Therefore, you should charge premium prices - highest in the segment"'
					},
				],
			},
			{
				id: 'premium_prices',
				speaker: 'Milena',
				text: ['"Please elaborate."'],
				choices: [
					{
						id: 'buy_because_expensive',
						name: '"Your product isn\'t be about pleasure. Your product is about the customer knowing he or she is getting the best and not something that is average. That\'s what we are selling"',
					},
				],
			},
			{
				id: 'buy_because_expensive',
				speaker: 'Milena',
				text: ['So, you\'re telling me that people will buy these products because they are expensive?'],
				choices: [
					{
						id: 'modern_man',
						name: '"Yes. I am."',
					},
				],
			},
			{
				id: 'modern_man',
				speaker: 'Milena',
				text: ['"Tell me, Mr. Spade. Do you consider yourself a modern man?"'],
				choices: [
					{
						id: 'iam_modern',
						name: '"I am"',
					},
					{
						id: 'question_judgment',
						name: '"No. I am a very old fashioned, traditional type of man."',
					},
				],
			},
			{
				id: 'iam_modern',
				speaker: 'Milena',
				text: ['"In that case, may I ask how many sex toys you own?"'],
				choices: [
					{
						id: 'question_judgment',
						name: '"None" (Admit the truth)',
					},
					{
						id: 'question_judgment',
						name: '"That\'s a personal question" (Avoid the question)',
					},
				],
			},
			{
				id: 'question_judgment',
				speaker: 'Milena',
				text: ['"How can I then rely on your judgement on this matter?"'],
				choices: [
					{
						id: 'come_up_with_explanation',
						name: '"Come up with an explanation"',
					},
				],
			},
			{
				id: 'come_up_with_explanation',
				skill_required: 'social.persuasion',
				score_required: 8,
				fail_node: 'go_blank',
				speaker: 'Persuasion',
				text: ['It\'s obvious. You are a master of human psychology. You can sell anything to anyone. Tell her that.'],
				choices: [
					{
						id: 'its_just_sales',
						name: '"Last year we helped a diaper brand triple their Sales. And I didn\'t have to be a baby or even a parent to achieve that. Human beings are simply human beings and I know how to sell to them"',
					},
				]
			},
			{
				id: 'go_blank',
				speaker: 'Persuasion',
				text: [
					'You try to think of a reason why you are right. But your mind goes blank',
					'There\'s an uncomfortable silence in the room.',
					'This is the day your career probably ended.',
				],
				continue_to: 'roger_tries_to_save',
			},
			{
				id: 'roger_tries_to_save',
				speaker: 'Roger',
				text: [
					'Roger breaks the silence',
					'"Milena, I agree with Nathan\'s hypothesis. We can run focus groups and find out the right market segment to target. We have some of the best analysts who can do that for you."'
				],
				continue_to: 'milena_hmms'
			},
			{
				id: 'milena_hmms',
				text: [
					'"Hmmm... This has been a good meeting. It has given a me a lot to think about."',
					'"So I am going to get back to you once we figure out our ideal customer. Thanks to you and Nathan and the team"',
					'"My assistant Rachel will be in touch", she says as she leaves.'
				],
				speaker: 'Milena',
				set_game_data: {
					milena_meeting_completed: true,
					milena_meeting_went_well: false
				},
				advance_time: 80,
				xp_gain: 30,
				continue_to: 'milena_leaves'
			},
			{
				id: 'milena_leaves',
				speaker: 'Roger',

				text: [
					'Roger gives you a displeased look as he escorts them outside.',
				],
				continue_to: 'set_scene:Sickness'
			},
			{
				id: 'its_just_sales',
				speaker: 'Milena',
				text: ['"That does makes sense. But..."'],
				choices: [
					{
						id: 'nathans_right',
						name: '"Ms. Hayes, it\'s always easier to cut prices than to increase them. If I am wrong, we can always lower the price. But the reverse will be much more difficult (Interrupt her)"',
					},
					{
						id: 'allow_to_finish',
						name: 'Allow her to finish',
					},
				],

			},
			{
				id: 'allow_to_finish',
				speaker: 'Milena',
				text: ['"I am just concerned that we need a good start for the brand"'],
				choices: [
					{
						id: 'nathans_right',
						name: '"It\'s always easier to cut prices than to increase them. If I am wrong, we can always lower the price. But the reverse will be much more difficult."',
					},
				],
			},
			{
				id: 'nathans_right',
				speaker: 'Milena',
				text: ['"You\'re right. I think we should adopt this premium price strategy."'],
				choices: [
					{
						id: 'very_well_then',
						name: '"Very well then. Let us know what price you decide. We will figure out a plan based on that and begin the work."',
					},
				],
			},
			{
				id: 'very_well_then',
				speaker: 'Milena',
				text: ['"Sure. That sounds like a plan. I look forward to working with you on this Mr. Spade", she says as she gets up from her chair.'],
				choices: [
					{
						id: 'me_too',
						name: '"Me too, Ma\'am"',
					},
				],
			},
			{
				id: 'me_too',
				advance_time: 80,
				set_game_data: {
					milena_meeting_completed: true,
					milena_meeting_went_well: true
				},
				xp_gain: 30,
				speaker: 'Milena',
				text: ['"My assistant Rachel will take this forward from here onward.", she says before Roger escorts them out of the room'],
				continue_to: 'set_scene:Sickness'
			}

		]
	}

	meetingStartChoices () {
		if (this.gameData.clientProfileReviewed) {
			return [{
				id: 'ask_about_pricing',
				name: '"Sure. Let\'s get into it right away. We noticed that you didn\'t specify the prices of the products. What\'s your pricing strategy?"',
			}]
		} else {
			return [{
				id: 'present_the_case',
				name: '"Sure. Let\'s get into it right away. Can you briefly explain your business and what you want from us?"',
			}]
		}

	}

	timeToStart () {
		let meetingTime = this.gameData.currentDateTime.hour(11).minute(0)
		return (meetingTime.diff(this.gameData.currentDateTime, 'minutes'))
	}

	event (id) {
		return this.events().filter((ev) => {
			return ev['id'] === id
		})[0]
	}

}
