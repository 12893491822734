<template>
	<transition :duration="500" name="fade">
		<div id="scene-switcher" v-if="sceneSwitching"></div>
	</transition>
	<div id="vbox" v-if="currentSceneName !== null">
		<div id="bg-layer" class="fixed inset-0 bg-black bg-opacity-80 z-20">
			<transition name="fade">
				<div id="lightbox" v-if="hasImage"
						 class="text-center fixed left-0 bottom-0 top-0 bg-contain bg-no-repeat bg-center flex"
						 style="right: calc(40rem + 30px);">
					<img :src="require(`@/assets/images/scenes/${latestSceneEvent.image_path}`)" alt="">
				</div>
			</transition>
		</div>
		<div class="bg-white w-full lg:w-5/12 fixed top-0 right-0 lg:right-12 p-4 lg:p-10 lg:pb-40 h-full z-30">
			<div class="absolute top-3 left-0 right-0 bg-white lg:hidden mb-2 border-b border-gray-200 pb-3"
					 v-if="hasProfileImage(latestSceneEvent.speaker)">
				<div class="w-20 h-20 rounded-full bg-cover mx-auto"
						 :style="`background-image: url('${require(`@/assets/images/people/${profileImageMap(latestSceneEvent.speaker)}`)}')`"
						 alt="">
				</div>
			</div>
			<div class="w-36 rounded overflow-hidden bg-white p-1 shadow-lg hidden lg:block absolute top-12 -left-32"
					 v-if="hasProfileImage(latestSceneEvent.speaker)">
				<img :src="require(`@/assets/images/people/${profileImageMap(latestSceneEvent.speaker)}`)" alt="">
			</div>
			<div id="vbox-inner" class="h-full overflow-x-scroll">
				<div id="game_events " class="pb-10 pt-28 lg:pb-0 lg:pt-0">
					<template v-for="(event, index) in sceneEvents">
						<p v-for="(txt, idx) in event.text" :class="[idx === 0 ? 'game-event-first' : 'game-event', 'mb-3']">
                <span v-if="idx === 0 ">
                  <b class="uppercase font-serif" :style="`color: ${speakerTextColorMap(event.speaker)};`">{{
											event.speaker
										}}</b> <template v-if="event.hasOwnProperty('roll_result')"> <span class="font-serif">[Success] [Required: {{
										event['score_required']
									}} vs. Result: {{ event['roll_result'] }}]</span></template>  - </span>
							<span class="font-serif text-gray-700">{{ txt }}</span>
						</p>
						<p class="has-text-success my-4" v-if="event.set_game_goal !== undefined">Task added:
							{{ goalData(event.set_game_goal).title }}</p>
						<p class="has-text-success my-4" v-if="event.complete_game_goal !== undefined">Task completed:
							{{ goalData(event.complete_game_goal).title }}</p>
						<p class="has-text-success my-4" v-if="event.xp_gain !== undefined">XP Gained:
							{{ event.xp_gain }}</p>
						<p class="has-text-success my-4"
							 v-if="event.money_on_hand_change !== undefined && event.money_on_hand_change > 0">Money gained:
							{{ Math.abs(event.money_on_hand_change) }}</p>
						<p class="has-text-danger my-4"
							 v-if="event.money_on_hand_change !== undefined && event.money_on_hand_change < 0">Money lost:
							{{ Math.abs(event.money_on_hand_change) }}</p>

						<div id="game_responses" v-if="(index + 1) === sceneEvents.length">
							<img v-if="hasImage" :src="require(`@/assets/images/scenes/${latestSceneEvent.image_path}`)" alt=""
									 class="lg:hidden">
							<ol v-if="latestSceneEvent.choices !== undefined" class="font-serif list-decimal mt-4">
								<li v-for="choice in latestSceneEvent.choices" class="mt-2 ml-4">
									<div
										:class="{'choice-button': true, 'is-onetime': isOneTimeCheck(choice), 'is-retryable': isRetryableCheck(choice)}"
										v-on:click="makeChoice(choice.id, choice.name)">{{
											choice.name
										}}
										<template v-if="skillNeeded(choice) !== undefined">
											<div :class="['success-indicator', skillNeeded(choice)[0]]">
												<div class="skill-title">{{ skillNeeded(choice)[1] }}</div>
												<div>Success Probability</div>
												<div class="success-rate">{{ successProbability(choice) }}%</div>
												<template v-if="skillModifiers(choice) !== undefined ">
													<div v-for="(val, name) in skillModifiers(choice)">{{
															name
														}} {{ val > 0 ? `+${val}` : val }}
													</div>
												</template>
												<hr style="margin: 10px 0;">
												<div class="choice-note" v-if="isOneTimeCheck(choice)">You can only try this once</div>
												<div class="choice-note" v-if="isRetryableCheck(choice)">You can only retry this later</div>
											</div>
										</template>
									</div>
								</li>
							</ol>
							<div class="buttons" style="margin-top: 1rem;" v-else>
								<button class="button is-primary" v-on:click="makeChoice(event.continue_to)">
									{{ showEndButton ? 'End ■' : 'Continue ▶' }}
								</button>
							</div>
							<a target="_blank" v-if="currentSceneName === 'EndOfContent'"
								 href="https://www.patreon.com/bePatron?u=46726406"
								 class="patreon-button button is-rounded"><span class="icon"><i class="fab fa-patreon"></i></span>
								<span>Become a Patron!</span></a>
						</div>

					</template>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import goalData from '@/scripts/goals'

const {
	Howl,
	Howler
} = require('howler')

export default {
	name: 'DialogueBox',
	props: [],
	computed: {
		...mapGetters(
			[
				'currentSceneName',
				'currentScene',
				'sceneEvents',
				'latestSceneEvent',
				'skills',
				'pendingGoalNames'
			]
		),
		showEndButton () {
			if (this.latestSceneEvent.continue_to === undefined) {
				return false
			}
			return this.latestSceneEvent.continue_to === 'the_end' || this.latestSceneEvent.continue_to.split(':')[0] === 'set_scene'
		},
		hasImage () {
			return this.latestSceneEvent.image_path !== undefined
		}
	},
	created () {
		// this.playVoiceOver(this.latestSceneEvent.sound_file, this.currentSceneName, this.latestSceneEvent.id)
	},
	updated () {
		let vboxInner = document.getElementById('vbox-inner')
		if (vboxInner !== null) {
			setTimeout(function () {
				vboxInner.scrollTop = vboxInner.scrollHeight
			}, 10)
		}
		// this.playVoiceOver(this.latestSceneEvent.sound_file, this.currentSceneName, this.latestSceneEvent.id)
	},
	data () {
		return {
			sceneSwitching: false
		}
	},
	methods: {
		playVoiceOver (soundFile, scene, node) {
			return false
			try {
				const context = new AudioContext()
				let path
				if (soundFile === null || soundFile === undefined) {
					path = require(`@/assets/audio/scripts/${scene}/${node}.mp3`)
				} else {
					path = require(`@/assets/audio/scripts/${scene}/${soundFile}.mp3`)
				}
				context.resume().then(function () {
					var sound = new Howl({
						src: [path]
					})
					Howler.stop()
					sound.play()
				})
			} catch (ex) {
				console.log(ex)
			}
		},

		goalData (id) {
			return goalData(id)
		},

		makeChoice (choiceId, dialogue = null) {

			if (dialogue !== null) {
				this.$store.commit('recordEvent', {
					id: `${choiceId}-response`,
					text: [dialogue],
					speaker: 'You',
				})
				this.$store.commit('noteScript', `${this.currentSceneName}.${choiceId}-response`)
			}
			if (choiceId === 'the_end') {
				this.$store.commit('clearScene')
			} else {
				let event = this.currentScene.event(choiceId)
				if (choiceId.split(':')[0] === 'set_scene') {
					this.sceneSwitching = true
					let vm = this
					setTimeout(() => {
						vm.$store.dispatch('initiateScene', choiceId.split(':')[1])
						vm.sceneSwitching = false
					}, 1000)
					return
				}
				if (event.hasOwnProperty('score_required')) {
					event = this.skillCheckedEvent(event)
				}
				this.execCallbacks(event)
				this.$store.commit('recordEvent', event)
				this.$store.commit('noteScript', `${this.currentSceneName}.${event.id}`)
			}
		},

		rollDie () {
			return Math.round(Math.random() * (6 - 1) + 1)
		},

		skillCheckedEvent (event) {
			let [attr, skill] = event['skill_required'].split('.')
			let minScoreNeeded = event['score_required']
			let modifierValue = 0
			if (event.hasOwnProperty('skill_modifiers')) {
				modifierValue = Object.values(event['skill_modifiers']).reduce((a, b) => a + b)
			}
			let skillValue = this.skills[attr].startingBase + this.skills[attr].learned[skill] + modifierValue
			let rollResult = event['check_type'] === 'passive' ? 6 : this.rollDie() + this.rollDie()
			event['roll_result'] = rollResult + skillValue
			if (rollResult === 2) {
				return this.currentScene.event(`${event['fail_node']}`)
			}
			if (rollResult === 12 || ((rollResult + skillValue) >= minScoreNeeded)) {
				return event
			} else {
				return this.currentScene.event(`${event['fail_node']}`)
			}
		},

		execCallbacks (ev) {
			if (ev.hasOwnProperty('set_game_data')) {
				this.$store.commit('setGameData', ev['set_game_data'])
			}
			if (ev.hasOwnProperty('set_game_goal')) {
				this.$store.commit('setGameGoal', ev['set_game_goal'])
			}
			if (ev.hasOwnProperty('set_pc_image')) {
				this.$store.commit('updateImage', ev['set_pc_image'])
			}
			if (ev.hasOwnProperty('set_pc_location')) {
				this.$store.commit('navigateTo', ev['set_pc_location'])
			}
			if (ev.hasOwnProperty('complete_game_goal')) {
				this.$store.commit('completeGameGoal', ev['complete_game_goal'])
			}
			if (ev.hasOwnProperty('money_on_hand_change')) {
				this.$store.commit('moneyOnHandChange', ev['money_on_hand_change'])
			}
			if (ev.hasOwnProperty('xp_gain')) {
				this.$store.commit('xpGain', ev['xp_gain'])
			}
			if (ev.hasOwnProperty('advance_time')) {
				this.$store.dispatch('advanceTime', ev['advance_time'])
			}
		},

		isOneTimeCheck (choice) {
			let event = this.currentScene.event(choice.id)
			return event['check_type'] === 'onetime'
		},

		isRetryableCheck (choice) {
			let event = this.currentScene.event(choice.id)
			return event['check_type'] === 'retryable'
		},

		skillNeeded (choice) {
			let event = this.currentScene.event(choice.id)
			if (event.hasOwnProperty('skill_required')) {
				return event['skill_required'].split('.')
			} else {
				return undefined
			}
		},

		scoreNeeded (choice) {
			let event = this.currentScene.event(choice.id)
			let [attr, skill] = event['skill_required'].split('.')
			return event['score_required']
		},

		playerSkill (choice) {
			let event = this.currentScene.event(choice.id)
			let [attr, skill] = event['skill_required'].split('.')
			return this.skills[attr].startingBase + this.skills[attr].learned[skill]
		},

		skillModifiers (choice) {
			let event = this.currentScene.event(choice.id)
			return event['skill_modifiers']
		},

		successProbability (choice) {
			let event = this.currentScene.event(choice.id)
			let [attr, skill] = event.skill_required.split('.')
			let minScoreNeeded = this.scoreNeeded(choice)
			let modifierValue = 0
			if (event.hasOwnProperty('skill_modifiers')) {
				modifierValue = Object.values(event.skill_modifiers).reduce((a, b) => a + b)
			}
			let skillValue = this.skills[attr].startingBase + this.skills[attr].learned[skill] + modifierValue
			let probabilityTable = {
				2: 3,
				3: 8,
				4: 17,
				5: 28,
				6: 42,
				7: 58,
				8: 72,
				9: 83,
				10: 92,
				11: 97,
				12: 100,
			}
			if (minScoreNeeded < skillValue) {
				return 97
			} else {
				return 100 - probabilityTable[Math.max(minScoreNeeded - skillValue, 2)]
			}
		},

		hasProfileImage (speaker) {
			return this.profileImageMap(speaker) !== undefined
		},

		profileImageMap (speaker) {
			return {
				'Colby': 'colby.jpeg',
				'Memory': 'memory.jpeg',
				'Willpower': 'willpower.jpeg',
				'Phone': 'phone.jpeg',
				'Laptop': 'laptop.jpeg',
				'Analysis': 'intellect.jpeg',
				'Deviance': 'deviance.jpeg',
				'Allure': 'allure.jpeg',
				'Perception': 'perception.jpeg',
				'Esmeralda': 'esmeralda.jpeg',
				'Amanda': 'amanda.jpeg',
				'Roger': 'roger.jpeg',
				'Brenda': 'brenda.jpeg',
				'Pete McLaren': 'pete.jpeg',
				'Jonah': 'jonah.jpeg',
				'Milena': 'milena.jpeg',
				'Rachel': 'rachel.jpeg',
				'Niels': 'niels.png',
				'Katja': 'katja.png',
				'Old Man Joe': 'oldmanjoe.png',
			}[speaker]
		},

		speakerTextColorMap (speaker) {
			return {
				'Endurance': '#FB3640',
				'Shivers': '#FB3640',
				'Perception': '#FB3640',
				'Poise': '#FB3640',
				'Willpower': '#417B5A',
				'Memory': '#417B5A',
				'Analysis': '#417B5A',
				'Deviance': '#417B5A',
				'Persuasion': '#2972e0',
				'Empathy': '#2972e0',
				'Assertiveness': '#2972e0',
				'Allure': '#2972e0',
			}[speaker] || 'initial'
		},
	}

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.vertical-box {
	width: 40rem;
	position: fixed;
	top: 0;
	right: 30px;
	padding: 40px 40px 100px 40px;
	border-radius: 0;
	height: 100%;
	background-color: rgba(255, 255, 255, 1);

	#vbox-inner {
		height: 100%;
		overflow-x: scroll;
	}

	#speaker_portrait {
		width: 150px;
		position: absolute;
		top: 70px;
		left: -130px;

		img {
			box-shadow: 0 1px 3px rgba(0, 0, 0, .2);
			border: 2px solid #fff;
			border-radius: 10px;
		}
	}
}

#bg-layer {
	//position: fixed;
	//background: rgba(0, 0, 0, 0.8);
	//left: 0;
	//right: 0;
	//bottom: 0;
	//top: 0;
	//z-index: 2;

	#lightbox {
		text-align: center;
		position: fixed;
		left: 0;
		right: calc(40rem + 30px);
		bottom: 0;
		top: 0;
		background-size: contain;
		background-repeat: no-repeat;
		background-position-x: center;
		background-position-y: center;
		display: flex;

		img {
			margin: auto;
			max-height: calc(100% - 4.5rem);
		}
	}
}

.choice-button {
	@apply relative w-full text-left text-red-900 cursor-pointer my-2;

	&.is-onetime {
		@apply bg-red-200;
	}

	&.is-retryable {
		background-color: #ccc;
	}

	.success-indicator {
		display: none;
		color: #000;
		border: 1px solid #eee;
		box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
		position: absolute;
		right: 50px;
		bottom: 5px;
		background-color: #fff;
		padding: 10px;
		border-radius: 4px;
		text-align: center;

		.skill-title {
			color: #fff;
			text-transform: uppercase;
			font-weight: bold;
			padding: 5px 10px;
		}

		&.physical {
			.skill-title {
				background-color: #FB3640;
			}
		}

		&.mental {
			.skill-title {
				background-color: #417B5A;
			}
		}

		&.social {
			.skill-title {
				background-color: #2972e0;
			}
		}

		.success-rate {
			font-size: 2.5rem;
			font-weight: bold;
		}

		.choice-note {

		}
	}

	&:hover {
		background-color: lightgoldenrodyellow;

		.success-indicator {
			display: block;
		}
	}

}

.has-text-success {
	@apply bg-green-200 text-green-800 py-1 px-4 rounded-md;
}

.has-text-danger {
	@apply bg-red-200 text-red-800 py-1 px-4 rounded-md;
}


#scene-switcher {
	z-index: 1000;
	background-color: #000;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}

.fade-enter-active, .fade-leave-active {
	transition: opacity .5s ease;
}

.fade-enter-from, .fade-leave-to {
	opacity: 0;
}
</style>
