export default class EndOfContent {
    constructor(gameData) {
        this.gameData = gameData
    }

    events() {
        return [
            {
                id: "start",
                speaker: 'Developer',
                image_path: 'EIrywoGXkAAPUfJ.jpg',
                text: [
                    "You have now reached the current end of content",
                    "How will the workplace treat Nathan?",
                    "Can he locate the gypsy and get his body back?",
                    "What will the doctor say?",
                    "What kind of woman will he become?",
                    "Find out your answers when Episode 3 is out",
                    "Support me on Patreon, so I can realize my vision for this game and flesh out this story in glorious detail."
                ],
                choices: []
            },
        ]
    }


    event(id) {
        return this.events().filter((ev) => {
            return ev['id'] === id
        })[0]
    }

}
