export default class RogerJobUpdate {
	constructor (gameData) {
		this.gameData = gameData
	}

	events () {
		return [
			{
				id: 'start',
				speaker: 'Roger',
				text: ['I spoke to HR. We can get you onboard as a consultant. I figured we could tell you team that Nathan\'s gone to attend an emergency and you would be on retainer until he gets back\n'],
				choices: [
					{
						id: 'plan',
						name: 'Sounds like a plan'
					}
				]
			},
			{
				id: 'plan',
				speaker: 'Roger',
				text: [
					'There are a few more things you need to take care of. You will need a name, bank account, an identity, the whole package. So you will need to arrange those things for yourself.'
				],
				continue_to: 'analysis',
			},
			{
				id: 'analysis',
				speaker: 'Analysis',
				text: [
					'Makes sense. You are going to need all the documentation to take part in modern society.'
				],
				continue_to: 'memory',
			},
			{
				id: 'memory',
				speaker: 'Memory',
				text: [
					'Colby hires immigrant labor for his business. He must have some contacts'
				],
				choices: [{
					id: 'got-it',
					name: 'Got it'
				}],
			},
			{
				id: 'got-it',
				speaker: 'Roger',
				text: [
					'Anything else?'
				],
				choices: [
					{
						id: 'identity-question',
						name: 'I don\'t know where I can get an identity'
					}
				],
			},
			{
				id: 'identity-question',
				speaker: 'Roger',
				text: [
					'Hmmm... As I remember your friend, the guy with the beard, seems like a guy who would have connections. Why don\'t you ask him'
				],
				set_game_goal: ['ask_colby_about_identity'],
				choices: [
					{
						id: 'he-must-know',
						name: 'Yeah, he must know'
					}
				],
			}
			,
			{
				id: 'he-must-know',
				speaker: 'Roger',
				text: [
					'Alright, I\'ll see you Monday'
				],
				continue_to: 'the_end'
			}

		]
	}

	event (id) {
		return this.events().filter((ev) => {
			return ev['id'] === id
		})[0]
	}

}
