export default class ColbysApartment {
	constructor (gameData) {
		this.gameData = gameData
		this.name = 'Colby\'s Apartment'
		this.bgImage = 'places/apartment.png'
		this.description = 'You are at Colby\'s apartment'
	}

	actions () {
		let actions = [
			{
				name: 'Talk to Colby',
				scene: 'Colby',
				icon: 'icons8-communicate-96.png',
				condition: true,
			},
		]
		return actions.filter((element) => {
			return element['condition']
		})
	}

	isSleepTime (hour) {
		return (hour >= 21 || hour < 6)
	}
}
