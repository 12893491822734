<template>
	<template v-if="mode === 'mainmenu'">
		<div :style="bgStyle" >
			<div class="grid grid-cols-12 is-gapless" style="">
				<div class="col-span-7 is-offset-1 has-text-right pr-3 mt-20">
					<div class="font-display uppercase text-8xl text-gray-100 text-right">wish</div>
					<div class="font-display uppercase text-8xl text-gray-100 text-right">gone</div>
					<div class="font-display uppercase text-8xl text-gray-100 text-right">wild</div>
				</div>
				<div class="col-span-5 border-l border-gray-400">
					<ul class="mt-24 w-32" v-if="mainMenuView === 'menu'">
						<li class="main-menu-btn" v-on:mouseover="playButtonSound()" v-on:click="setView('game')">Continue</li>
						<li class="main-menu-btn" v-on:mouseover="playButtonSound()" v-on:click="newGame()">New Game</li>
						<li class="main-menu-btn" v-on:mouseover="playButtonSound()" v-on:click="saveGame()">Save Game</li>
						<li class="main-menu-btn" v-on:mouseover="playButtonSound()" v-on:click="viewLoadMenu()">Load Game</li>
						<!--            <li class="main-menu-btn" v-on:mouseover="playButtonSound()" v-on:click="saveToDisk()">Save to Disk</li>-->
						<!--            <li class="main-menu-btn" v-on:mouseover="playButtonSound()" v-on:click="loadFromDisk()">Load from Disk</li>-->
						<li class="main-menu-btn" v-on:mouseover="playButtonSound()" v-on:click="viewOptions()">Options</li>

						<a target="_blank" href="https://www.patreon.com/bePatron?u=46726406"
							 class="patreon-button fixed top-4 right-4 "><span class="mr-2"><i class="fab fa-patreon"></i></span>
							<span>Become a Patron!</span>
						</a>
					</ul>

					<template v-if="mainMenuView === 'loadList'">
						<ul class="mt-1">
							<p style="color: #fff;cursor: pointer;margin-bottom: 1rem;padding-left: 10px;"
								 v-on:click="backToMainMenu()">&larr; back to
								menu</p>
							<li class="main-menu-btn" v-for="(saveObj, saveName) in loadList()" v-on:click="loadSave(saveObj)">
								{{ saveName }}
							</li>
						</ul>
					</template>
					<template v-if="mainMenuView === 'options'">
						<ul class="main-menu">
							<p style="color: #fff;cursor: pointer;margin-bottom: 1rem;padding-left: 10px;"
								 v-on:click="backToMainMenu()">&larr; back to
								menu</p>
							<li class="main-menu-btn" v-on:click="toggleNSFW()">
								NSFW: {{ nsfw ? 'YES' : 'NO' }}
							</li>
						</ul>
					</template>
				</div>
			</div>
		</div>
	</template>
	<template v-if="mode === 'archetype'">
		<div class="page-title">CHOOSE AN ARCHETYPE</div>
		<div class="">
			<div class="section">
				<ArchetypeSelection v-on:archetype-select="archetype = $event"/>
				<div :class="{'text-center py-2 fixed bottom-0 right-0 left-0 bg-white border-t border-gray-200':true, 'hidden': archetype === null }">
					<button class="button" v-on:click="setView('mainmenu')">Back</button>
					<button class="button is-primary ml-4" :disabled="archetype === null" v-on:click="continueAfterArchetype()">
						Continue
					</button>
				</div>
			</div>
		</div>
	</template>
	<template v-if="mode === 'attrSelection'">
		<div class="page-title">CUSTOMIZE YOUR ATTRIBUTES</div>
		<div class="">
			<div class="section">
				<AttrSelection/>
				<div class="text-center py-2 fixed bottom-0 right-0 left-0 bg-white border-t border-gray-200">
					<button class="button" v-on:click="setView('archetype')">Back</button>
					<button class="button is-primary ml-4" v-on:click="continueToGame()">Continue</button>
				</div>
			</div>
		</div>
	</template>
	<template v-if="mode === 'skills'">
		<div class="">
			<div class="section">
				<Skills/>
				<div class="buttons is-centered">
					<button v-if="!gameStarted()" class="button" v-on:click="goBeforeSkills()">Back</button>
					<button class="button is-primary" v-on:click="continueToGame()">Continue</button>
				</div>
			</div>
		</div>
	</template>
	<template v-if="mode === 'game'">
		<Place/>
		<DialogueBox/>
		<Footer v-on:switch-view="mode = $event"/>
		<div id="mainmenu-bars" class="fixed cursor-pointer top-4 left-4 z-40 flex flex-row items-center bg-white p-1 rounded lg:px-4 lg:rounded-full lg:shadow-lg lg:opacity-80 lg:text-xs" v-on:click="setView('mainmenu')">
			<i class="fas fa-bars"></i> <span class="ml-2 hidden lg:block">Main Menu</span>
		</div>
	</template>
</template>

<script>
import Place from '@/components/Place.vue'
import Footer from '@/components/Footer.vue'
import DialogueBox from '@/components/DialogueBox'
import ArchetypeSelection from '@/components/ArchetypeSelection'
import AttrSelection from '@/components/AttrSelection'
import Skills from '@/components/Skills'
import { originalState } from '@/store/index'

const {
	Howl,
	Howler
} = require('howler')
const dayjs = require('dayjs')
dayjs.extend(require('dayjs/plugin/duration'))
dayjs.extend(require('dayjs/plugin/isBetween'))

export default {
	name: 'App',
	created () {
		if (localStorage.getItem('nsfw') === null) {
			localStorage.setItem('nsfw', process.env.NODE_ENV !== 'development')
		} else {
			this.nsfw = localStorage.getItem('nsfw') === 'true'
		}
		document.title = 'Wish Gone Wild'
		if (this.hasSave()) {
			this.loadSave(JSON.parse(localStorage.getItem('currentGame')))
			this.setView('mainmenu')
		} else {
			this.$store.dispatch('initiateScene', 'ColbyFirstCall')
		}
		this.$store.subscribe((mutation, state) => {
			localStorage.setItem('currentGame', JSON.stringify(this.$store.state))
		})
	},
	updated () {
	},
	data () {
		return {
			mode: 'mainmenu',
			mainMenuView: 'menu',
			nsfw: true,
			archetype: null,
		}
	},
	computed: {
		bgStyle () {
			return {
				height: '100vh',
				'background-color': `#343434`,
				'background-size': 'cover',
				'background-position': 'center',
				'background-image': `url('${this.nsfw ? require('@/assets/images/other/main-menu-bg.jpeg') : ''}')`
			}
		}
	},
	methods: {
		clearSave (name = 'currentGame') {
			localStorage.removeItem(name)
			this.$store.commit('loadSave', this.$store.state)
		},
		createSave (name) {
			localStorage.setItem(name, JSON.stringify(this.$data))
		},
		loadSave (obj) {
			this.$store.commit('loadSave', obj)
			this.setView('game')
		},
		hasSave () {
			return localStorage.getItem('currentGame') !== undefined && localStorage.getItem('currentGame') !== null
		},
		continueAfterArchetype () {
			if (this.archetype === 'custom') {
				this.setView('attrSelection')
			} else {
				// this.setView('skills')
				this.continueToGame()
			}
		},
		goBeforeSkills () {
			if (this.archetype === 'custom') {
				this.setView('attrSelection')
			} else {
				this.setView('archetype')
			}
		},
		setView (name) {
			this.mode = name
		},
		gameStarted () {
			return this.$store.getters.sceneEvents.length !== 0
		},
		continueToGame () {
			if (!this.gameStarted()) {
				this.$store.dispatch('initiateScene', 'ColbyFirstCall')
			}
			this.setView('game')
		},
		newGame () {
			this.clearSave()
			this.loadSave(originalState())
			this.setView('archetype')
		},
		saveGame () {
			let name = prompt('Please enter a name', 'Save Game 1')
			let saves = JSON.parse(localStorage.getItem('saves')) || {}
			saves[name] = this.$store.state
			localStorage.setItem('saves', JSON.stringify(saves))
		},
		saveToDisk () {
			let data = new Blob([JSON.stringify(this.$store.state)], { type: 'text/plain' })
			let textFile = window.URL.createObjectURL(data)
			let link = document.createElement('a')
			link.href = textFile
			link.download = textFile.substr(textFile.lastIndexOf('/') + 1)
			link.click()
		},
		loadFromDisk () {
			alert('tbi')
		},
		viewLoadMenu () {
			this.mainMenuView = 'loadList'
		},
		viewOptions () {
			this.mainMenuView = 'options'
		},
		backToMainMenu () {
			this.mainMenuView = 'menu'
		},
		loadList () {
			let saves = localStorage.getItem('saves')
			if (saves === undefined) {
				return []
			}
			saves = JSON.parse(saves)
			return saves
		},
		toggleNSFW () {
			let newResult = !this.nsfw
			localStorage.setItem('nsfw', newResult)
			this.nsfw = newResult
		},
		playButtonSound () {
			const context = new AudioContext()
			context.resume().then(function () {
				var sound = new Howl({
					src: [require('@/assets/audio/fx/button.mp3')]
				})
				Howler.stop()
				sound.play()
			})
		}

	},
	components: {
		Place,
		DialogueBox,
		Footer,
		ArchetypeSelection,
		AttrSelection,
		Skills
	}
}
</script>

<style lang="scss">

.page-title {
	@apply font-display uppercase mb-1 fixed top-0 bg-red-800 text-white left-2 lg:left-12 px-8 text-2xl lg:text-4xl;
	clip-path: polygon(4% 0, 100% 0%, 96% 100%, 0% 100%);
}

.main-menu-btn {
	@apply font-ui font-light uppercase text-gray-200 text-2xl p-1 pl-3 hover:bg-red-800 hover:text-white cursor-pointer
}

.button {
	@apply font-sans uppercase font-light px-4 py-2 bg-white border border-gray-300 rounded-md;
	&.is-small {
		@apply text-sm px-2 py-1;
	}
	&.is-primary {
		@apply bg-red-800 text-white;
		&:hover {
			@apply bg-red-700 ;
		}
	}
}
</style>
