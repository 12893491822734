export default class JonahAndBrenda {
    constructor(gameData) {
        this.gameData = gameData
    }

    events() {
        return [
            {
                id: "start",
                speaker: 'Your Team',
                text: ["You walk up to Jonah, and Brenda"],
                continue_to: this.gameData.jonahBrendaNotedChanges ? 'hey_boss' : 'comment_on_changes'
            },
            {
                id: 'comment_on_changes',
                set_game_data: {'jonah_brenda_noted_changes': true},
                speaker: 'Jonah',
                text: [
                    "\"Good morning, boss! What's with the new hairdo?\""
                ],
                choices: [
                    {
                        id: 'grew_overnight',
                        name: "\"Thank you I grew them myself - overnight\"",
                    }
                ],
            },
            {
                id: 'grew_overnight',
                speaker: 'Brenda',
                text: ['"Wow. That\'s unbelievable"'],
                continue_to: 'charm',
            },
            {
                id: 'charm',
                speaker: "Empathy",
                text: ['"You are their boss. They are doing a very bad job of trying to flatter you, get on your good side."'],
                advance_time: 5,
                continue_to: 'hey_boss',
            },
            {
                id: 'profile_walk_through',
                speaker: 'Jonah',
                advance_time: 10,
                set_game_data: {'client_profile_reviewed': true},
                text: [
                    "He opens the profile on his computer and begins to talk.",
                    "\"Okay! The gist of it is that the company is called Sensual Explorations founded by Milena and Milton Hayes.\"",
                    "\"The company has designed vibrators for men and women and they want a marketing strategy from us.\", he concludes quickly flipping through the slides",
                ],
                choices: [
                    {
                        id: 'go_through_ideas',
                        name: '"Okay. What are your thoughts on this?"',
                    },
                ],
            },
            {
                id: 'go_through_ideas',
                speaker: 'Jonah',
                text: ["\"Firstly, I think they didn't mention the prices they are planning to charge. That's crucial for deciding which segment of the customer to target.\""],
                advance_time: 5,
                choices: [
                    {
                        id: 'what_else',
                        name: "\"Very nice. What else?\"",
                    }
                ]
            },
            {
                id: 'what_else',
                speaker: 'Brenda',
                text: [
                    "\"Uhh... I disagree. I think we can already tell what kind of customer they are targeting. Ms. Milena, the founder, is obviously a person of taste\"",
                    "\"They have designed vibrators in an aesthetically pleasing way. My guess is they were unsatisfied with what's available on the market and designed it for people like themselves.\"",
                ],
                choices: [
                    {
                        id: 'what_kind_of_people',
                        name: "\"And what kind of people are they?\"",
                    },
                ],
            },
            {
                id: 'what_kind_of_people',
                speaker: 'Brenda',
                advance_time: 5,

                text: ["\"The rich kind. The kind that does not worry about price.\""],
                continue_to: 'thats_not_the_full_story',
            },
            {
                id: 'thats_not_the_full_story',
                speaker: 'Analysis',
                text: ["\"That's not the fully story. Sure they don't worry about price. But they definitely worry about looking unique, thoughtful, exclusive, and intelligent among their friends.\""],
                choices: [
                    {
                        id: 'its_not_a_vibrator',
                        name: "\"It's not a vibrator then. It's a luxury sex toy\"",
                    },
                ],
            },
            {
                id: 'its_not_a_vibrator',
                speaker: 'Analysis',
                text: ["\"Bingo\""],
                choices: [
                    {
                        id: 'good_work',
                        name: "\"Good work guys.\"",
                    },
                ],
            },
            {
                id: 'good_work',
                speaker: 'Brenda',
                set_game_data: {'gone_through_ideas': true},
                xp_gain: 5,
                text: ["\"Thanks Mr. Spade\""],
                continue_to: 'the_end',
            },
            {
                id: 'hey_boss',
                speaker: 'Jonah',
                text: [
                    "What's up boss!"
                ],
                choices: this.startChoices(),
            },
            {
                id: 'nothing',
                speaker: 'Brenda',
                text: ['"Sure. We are here when you need us."'],
                continue_to: 'the_end',
            }
        ]
    }

    startChoices() {
        let choices = [
            {
                id: 'nothing',
                name: "\"Nothing. Just carry on whatever you were doing.\""
            }
        ]
        if (this.gameData.clientProfileReviewed) {
            choices.unshift({
                id: 'go_through_ideas',
                name: this.gameData.goneThroughIdeas ? "\"Let's go over your ideas again\"" : "\"I assume you've both gone through the profile. Give me your best ideas now\"",
            })
        } else {
            choices.unshift({
                id: 'profile_walk_through',
                name: "\"I haven't reviewed the client's profile. Can you guys walk me through it?\"",
            })
        }
        return choices
    }

    event(id) {
        return this.events().filter((ev) => {
            return ev['id'] === id
        })[0]
    }

}
