export default class AmandaDream {
    constructor(gameData) {
        this.gameData = gameData
    }

    events() {
        return [
            {
                id: "start",
                speaker: 'Home',
                text: ["You reach home, change into your pajamas, and lie on your bed and try to sleep"],
                continue_to: 'dream'
            },
            {
                id: 'dream',
                speaker: 'Amanda',
                set_pc_location: 'Home',
                text: [
                    "\"Hey Nathan.\"",
                ],
                choices: [
                    {
                        id: 'is_it_amanda',
                        name: '"Amanda, is that you?"'
                    }
                ]
            },
            {
                id: 'is_it_amanda',
                speaker: 'Amanda',
                text: ["\"Yes. It's me. I hope you get well soon.\""],
                choices: [
                    {
                        id: 'why_are_you_here',
                        name: '"Why are you here?"',
                    },
                ],
            },
            {
                id: 'why_are_you_here',
                speaker: 'Amanda',
                text: ["\"I am here because you wouldn't let me go.\""],
                choices: [
                    {
                        id: 'nonsense',
                        name: '"That doesn\'t make any sense"',
                    },
                ],
            },
            {
                id: 'nonsense',
                speaker: 'Amanda',
                text: ["\"You are a smart guy Nathan. You will figure it out\""],
                choices: [
                    {
                        id: 'why_leave_me',
                        name: '"Why did you leave me Amanda? I loved you."',
                    },
                ],
            },
            {
                id: 'why_leave_me',
                speaker: 'Amanda',
                text: ["\"You were very nice and I loved you. But you weren't the one for me. I couldn't be with you..\""],
                choices: [
                    {
                        id: 'why_not',
                        name: '"Why not? And don\'t give me that "I didn\'t feel a connection with you" crap"',
                    },
                ],
            },
            {
                id: 'why_not',
                speaker: 'Amanda',
                text: ["\"It's hard to explain. I have to go now\""],
                choices: [
                    {
                        id: 'wait',
                        name: '"Wait"',
                    },
                ],
            },
            {
                id: 'wait',
                speaker: 'Amanda',
                advance_time: 360,
                text: ['"Farewell... dear friend"'],
                continue_to: 'set_scene:AmandaDreamAwakening',
            }
        ]
    }


    event(id) {
        return this.events().filter((ev) => {
            return ev['id'] === id
        })[0]
    }

}
