export default class KatjaAtNiels {
	constructor (gameData) {
		this.gameData = gameData
	}

	events () {
		return [
			{
				id: 'start',
				speaker: 'Katja',
				text: [`"Hey ${this.gameData.nielsName}", she says dragging on her cigarette.`],
				choices: [
					{
						id: 'hey',
						name: 'Hey Katja'
					}
				]
			},
			{
				id: 'hey',
				speaker: 'Perception',
				text: [
					'She nods without opening the conversation. She is expecting you to talk.',
				],
				choices: this.questionNodes(),
			},
			{
				id: 'muse?',
				speaker: 'Katja',
				text: [
					'"No. I am not. I am just his friend"',
					`"Sometimes... I use him to...", she pauses as if looking for the right word, "...relieve tension"`,
				],
				choices: [
					{
						id: 'tension?',
						name: 'Relieve tension?'
					}
				]
			},
			{
				id: 'tension?',
				speaker: 'Katja',
				text: [
					'"We fuck now and then"',
				],
				choices: this.questionNodes(),
			},
			{
				id: 'living',
				speaker: 'Katja',
				text: [
					'"I am a journalist. An investigative journalist."',
					'"I try to find dirt on companies, politicians, powerful people and expose them. I go where normal people don\'t go and do what normal people don\'t do."',
					'"You? What do you do?"'
				],
				choices: [
					{
						id: 'no-job',
						name: 'I don\'t have a job. Why else would I be here?'
					},
					{
						id: 'marketing',
						name: 'Marketing'
					},
				]
			},
			{
				id: 'marketing',
				speaker: 'Katja',
				text: [
					'"Good for you. Stable jobs are great. I should get one of them these days."'
				],
				choices: this.questionNodes()
			},
			{
				id: 'no-job',
				speaker: 'Katja',
				text: [
					'"Of course. Good luck with the job search"'
				],
				choices: this.questionNodes()
			},
			{
				id: 'whats-niels-like',
				speaker: 'Katja',
				text: [
					'"Niels is a crazy guy. He\'s Danish royalty, was in their Marines, and he\'s über rich."',
					'"Then he woke up one day wanting to be a painter. After years of training, he learned to paint."',
					'"Now he just wants to paint his masterpiece. But he really has no idea what it can be."',
				],
				choices: this.questionNodes()
			},

			{
				id: 'wait-for-niels',
				speaker: 'Niels',
				advance_time: 60,
				text: [
					`"We can get started now. ${this.gameData.nielsName}, you get changed in there (points to a room) and come to the kitchen. That's where we are shooting."`
				],
				continue_to: 'set_scene:PhotoShoot'
			},

		]
	}

	questionNodes () {
		return [
			{
				id: 'muse?',
				name: 'Are you his muse?'
			},
			{
				id: 'living',
				name: 'What do you do for a living?'
			},
			{
				id: 'whats-niels-like',
				name: 'What is Niels like?'
			},
			{
				id: 'wait-for-niels',
				name: 'Wait for Niels (conclude)'
			}
		]
	}

	event (id) {
		return this.events().filter((ev) => {
			return ev['id'] === id
		})[0]
	}

}
