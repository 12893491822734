export default class Reorganizing {
	constructor (gameData) {
		this.gameData = gameData
	}

	events () {
		return [
			{
				id: 'start',
				speaker: 'Willpower',
				text: ['How could something like this happen?'],
				choices: [
					{
						id: 'find_out',
						name: 'It\'s time to think about this.'
					},
					{
						id: 'dont_think_about_it_now',
						name: 'I don\'t want to think about it. Not now.'
					},
				],
			},
			{
				id: 'dont_think_about_it_now',
				text: ['You are going to have to deal with it sooner rather than later.'],
				speaker: 'Willpower',
				continue_to: 'the_end'
			},
			{
				id: 'find_out',
				text: ['OK. Let\'s see. There are so many things to unpack here.'],
				speaker: 'Willpower',
				...this.endNodes()
			},
			{
				id: 'the-how',
				speaker: 'Analysis',
				text: ['It\'s certainly not a wild hormone imbalance. I can\'t imagine of any reason for such a thing to happen. It\'s not possible. The technology doesn\'t exist'],
				continue_to: 'scientifically'
			},
			{
				id: 'scientifically',
				speaker: 'Willpower',
				text: [
					'Technology? You\'re not a scientist. You don\'t know much about that subject.',
					'But then I sense that there\'s a crazy theory somewhere in the back of your mind.'
				],
				continue_to: 'back-of-the-mind'
			},
			{
				id: 'back-of-the-mind',
				speaker: 'Shivers',
				text: [
					'Yes. It\'s the gypsy. She did this.',
					this.gameData.esmeraldaAngryMeeting ? 'She must have cursed you. Angry gypsy women do it all the time to teach deviant men lessons.' : 'She set the spirit of a woman on you. You are probably possessed by that Dominique lady\'s spirit'
				],
				continue_to: 'doesnt-make-sense'
			},
			{
				id: 'doesnt-make-sense',
				speaker: 'Analysis',
				text: ['That\'s just bullshit.'],
				continue_to: 'doesnt-make-sense-2'
			},
			{
				id: 'doesnt-make-sense-2',
				speaker: 'Willpower',
				text: ['Nothing that happened today makes sense. You need to keep an open mind. If the gypsy is responsible, you should find her.'],
				xp_gain: 5,
				advance_time: 15,
				choices: [
					{
						id: 'gypsy-responsible',
						name: 'I need at least rule her out. I need to find her. (Accept task)'
					},
					{
						id: 'gypsy-not-responsible',
						name: 'Magic isn\'t real. She isn\'t responsible for this. There\'s something else'
					},
				]
			},

			{
				id: 'gypsy-responsible',
				speaker: 'Willpower',
				set_game_goal: 'find_esmeralda',
				text: [
					'Probably for the best.',
					'Onto the next big things.'
				],
				...this.endNodes()
			},
			{
				id: 'gypsy-not-responsible',
				speaker: 'Analysis',
				set_game_goal: 'find_out_the_reason_for_change',
				text: [
					'As mysterious as this is, it isn\'t caused by magic. It would be a waste of time to find her. Instead, you should investigate this.',
					'Onto the next big things.'
				],
				...this.endNodes()
			},

			{
				id: 'why-me',
				speaker: 'Shivers',
				advance_time: 15,
				text: [
					this.gameData.milenaMeetingWentWell ? 'It\'s really bad. First, Amanda left you, then it was the incident at Life Labs, and now this.' : 'It\'s really bad. First, Amanda left you, then it was the incident at Life Labs, you probably are getting fired after that last meeting, and now this.',
					'It there is a higher power, it probably has a hard-on for you'
				],
				continue_to: 'wp-on-why',
			},
			{
				id: 'wp-on-why',
				speaker: 'Willpower',
				text: [
					'Yes. It\'s bad. But it could be worse.'
				],
				choices: [
					{
						id: 'bad-thing',
						name: 'It can\'t be any worse. I am a woman with no friends or family.'
					},
					{
						id: 'not-bad-thing',
						name: 'It could be worse. I could be dead. I just need to find a way get my old self back.'
					},
					{
						id: 'good-thing',
						name: 'It\'s an opportunity to start a new life with a clean slate.'
					},
				],
			},
			{
				id: 'bad-thing',
				speaker: 'Shivers',
				text: [
					'You\'re right. Life is hard. Especially now that you are a woman. Not to mention, one without an identity.',
				],
				continue_to: 'cry-this-one'
			},
			{
				id: 'cry-this-one',
				text: ['Let it all out. Cry this one through. You need it. No one\'s here. This is your house. So go ahead and do it.'],
				speaker: 'Willpower',
				choices: [
					{
						id: 'cry',
						name: 'Breakdown and cry'
					}
				]
			},
			{
				id: 'cry',
				speaker: 'Your Apartment',
				text: ['You sob for a while reliving your past failures trying to trace the reason for your current predicament until finally there\'s no more crying left to do'],
				continue_to: 'post-crying'
			},
			{
				id: 'post-crying',
				speaker: 'Willpower',
				xp_gain: 5,
				text: ['Now that wasn\'t so bad.', 'Let\'s see what\'s next next on the list.'],
				...this.endNodes()
			},
			{
				id: 'not-bad-thing',
				speaker: 'Willpower',
				xp_gain: 5,
				text: [
					'Yeah. Plain and simple. You should do that. Solve the mystery of the sudden feminization and get your body back.',
				],
				...this.endNodes(),
			},
			{
				id: 'good-thing',
				speaker: 'Willpower',
				xp_gain: 5,
				text: [
					'Wait, are you really seeing a future as a woman?',
				],
				choices: [
					{
						id: 'future-as-woman',
						name: 'Yes. I am going to keep an open mind and try it out. Why not keep it if it\'s good?'
					},
					{
						id: 'not-really',
						name: 'Not really. I just want to live my life without the pressure of being responsible for Nathan\'s mistakes.'
					},
				],
			},
			{
				id: 'future-as-woman',
				speaker: 'Analysis',
				text: [
					this.gameData.autogynephelic ? 'It\'s important to not get carried away by your sexual fantasies.' : 'It sounds alright. I am all for open minds and trying things out.',
					'There are far more important things to deal with before that though.'
				],
				...this.endNodes()
			},
			{
				id: 'not-really',
				speaker: 'Willpower',
				text: [
					'That would be such a big relief. But you are still Nathan, right?'
				],
				...this.endNodes()
			},
			{
				id: 'whats-next',
				speaker: 'Willpower',
				text: [
					'Life goes on. You most certainly need friends to help you. And then, you need a job - To pay the bills and definitely to distract yourself from all this.',
				],
				choices: [
					{
						id: 'tell-roger',
						name: 'I should see if I can get my old job back. I will have to speak to Roger'
					},
				]
			},
			{
				id: 'tell-roger',
				speaker: 'Analysis',
				xp_gain: 5,
				advance_time: 15,
				set_game_goal: 'setup_a_meeting_with_roger',
				text: ['Telling Roger seems like the right idea. He can help you. And maybe no one else in the office needs to know.'],
				continue_to: this.gameData.colbyKnowsOfTF ? 'colby-knows' : 'tell-colby'
			},

			{
				id: 'colby-knows',
				speaker: 'Analysis',
				text: ['Besides, Colby already knows. He\'s really the only real friend you have right now. So at least you don\'t have to go through the awkward process of telling your friend why you have suddenly become a girl.'],
				...this.endNodes()
			},
			{
				id: 'tell-colby',
				speaker: 'Analysis',
				set_game_goal: 'tell_colby_about_change',
				text: [
					'You know who else should know? Colby. He\'s really the only real friend you have. Besides, I don\'t think there\'s any real chance of you getting away without telling him.',
					'Onto the next thing now.'
				],
				...this.endNodes()
			},
		]
	}

	endNodes () {
		if (this.gameData.processedTheHow && this.gameData.processedTheWhy && this.gameData.processedWhatsNext) {
			return { continue_to: 'the_end' }
		} else {
			return { choices: this.choiceNodes() }
		}
	}

	choiceNodes () {
		let choices = []
		if (!this.gameData.processedTheHow) {
			choices.push({
				id: 'the-how',
				name: 'How could a healthy man suddenly change into a real biological woman?'
			})
		}
		if (!this.gameData.processedTheWhy) {
			choices.push({
				id: 'why-me',
				name: 'Why me?'
			})
		}
		if (!this.gameData.processedWhatsNext) {
			choices.push({
				id: 'whats-next',
				name: 'What\'s next for me?'
			})
		}
		return choices
	}

	event (id) {
		return this.events().filter((ev) => {
			return ev['id'] === id
		})[0]
	}

}
