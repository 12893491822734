export default class FirstNightAsWomanSleep {
	constructor (gameData) {
		this.gameData = gameData
	}

	events () {
		return [
			this.startNode(),
			{
				id: 'get_out',
				speaker: 'Your bed',
				text: ['You get out of your bed'],
				continue_to: 'the_end',
			},
			{
				id: 'not_processed',
				speaker: 'Willpower',
				text: ['You lie down but your mind can\'t stop racing. You need process what happened to you and what you\'re going to do next if you want to sleep'],
				continue_to: 'the_end',
			},
			{
				id: 'sleep',
				speaker: 'Your Bed',
				text: ['You sleep peacefully for now'],
				advance_time: this.timeToAdvance(),
				continue_to: 'set_scene:FirstMorningAsWoman',
			},
		]
	}

	startNode () {
		let obj = {
			id: 'start',
			speaker: 'Your Bed',
			text: ['You climb into your bed and close your eyes. May be this is all a dream.'],
		}

		if (this.gameData.processedTheHow && this.gameData.processedTheWhy && this.gameData.processedWhatsNext) {
			obj['choices'] = [{
				name: 'Go to sleep',
				id: 'sleep'
			}]
		} else {
			obj['continue_to'] = 'not_processed'
		}
		return obj
	}

	timeToAdvance () {
		let wakingTime = this.gameData.currentDateTime.add(1, 'day').hour(8).minute(0)
		return (wakingTime.diff(this.gameData.currentDateTime, 'minutes'))
	}

	event (id) {
		return this.events().filter((ev) => {
			return ev['id'] === id
		})[0]
	}

}
