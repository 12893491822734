export default class Wardrobe {
	constructor (gameData) {
		this.gameData = gameData
	}

	canTryMaleClothes () {
		if (this.gameData.gameAttrs['gender'] !== 'female') {
			return false
		}
		if (this.gameData.experiencedScripts.includes('Wardrobe.the-shoes') || this.gameData.experiencedScripts.includes('Wardrobe.perception-on-looks')) {
			return false
		}
	}

	events () {
		return [
			{
				id: 'start',
				speaker: 'Wardrobe',
				text: [
					...(this.gameData.gameAttrs['gender'] === 'female' ? [
						'Your wardrobe has all your male clothes. But you are not confident that any of it will fit you',
						this.gameData.amandasBoxSeen ? 'There\'s also the box left by Amanda' : 'There is also a sealed cardboard box at the bottom.'
					] : [
						'Your wardrobe has a grey suit, a navy blazer, dress shirts, dress pants, jeans, dress shoes, sneakers and an overcoat',
						this.gameData.amandasBoxSeen ? 'There\'s also the box left by Amanda' : 'There is also a sealed cardboard box at the bottom.'
					]),
				],
				choices: [
					...(this.canTryMaleClothes() ? [
						{
							id: 'try-the-boxers',
							name: 'Try your old boxers'
						}] : []),
					...(this.gameData.amandasBoxSeen ? [{
						id: 'open-box',
						name: 'Open Amanda\'s box'
					}] : [{
						id: 'inspect-box',
						name: 'Inspect the box'
					}]),
					{
						id: 'shut-wardrobe',
						name: 'Close the wardrobe'
					}
				]
			},
			{
				id: 'try-the-boxers',
				speaker: 'The Boxers',
				text: [
					'It feels loose and the elastic can barely hold on to your waist',
					'It\'s feels like it\'s going to slide down if you make the slightest movement.',
					'You move around and check and it slides down in just 2 steps',
				],
				choices: [{
					name: 'Try the jeans',
					id: 'try-the-jeans'
				}],
			},
			{
				id: 'try-the-jeans',
				speaker: 'The Blue Jeans',
				text: [
					'The jeans are too big for you. They feel heavier than usual',
					'Your belt doesn\'t even have the holes even if you wanted to force the jeans to stay in your hips even if somehow you ended up doing it.',
				],
				choices: [{
					name: 'Try the shirt',
					id: 'the-shirt'
				}],
			},
			{
				id: 'the-shirt',
				speaker: 'The Shirt',
				text: [
					'The shirt is roomy enough for 2 of you to fit. You will have to roll your sleeves a lot and still it would like more that oversized on you.',
					'You can be sure to draw all the attention wherever you go just because of how odd and stupid it would look on you'
				],
				continue_to: 'perception-on-looks',
			},
			{
				id: 'perception-on-looks',
				speaker: 'Perception',
				text: [
					'With this look, you will be the ugliest dressed person in the world who is one wardrobe malfunction away',
					'Sorry it\'s a no-go',
				],
				continue_to: this.gameData.amandasBoxSeen ? 'memory-on-clothes' : 'start',
			},
			{
				id: 'memory-on-clothes',
				speaker: 'Memory',
				text: [
					'It\'s time for the nuclear option - Amanda\'s work out clothes. It\'s your best chance',
				],
				choices: [{
					id: 'open-box',
					name: 'Open Amanda\'s box'
				}],
			},
			{
				id: 'inspect-box',
				speaker: 'Memory',
				set_game_data: { amandas_box_seen: true },
				text: [
					'It\'s a sealed card board box belonging to Amanda. She forgot to load it in her car. It has her "gym wear and some other stuff". She told you to mail it to her. But you didn\'t.',
					'The box feels heavy and filled up. Something in the back of your mind tells you to not open it.'
				],
				choices: [
					{
						id: 'open-box',
						name: 'Open Amanda\'s box'
					}
					,
					{
						id: 'shut-wardrobe',
						name: 'Close the wardrobe'
					}
				]
			},
			{
				id: 'open-box',
				speaker: 'The Sealed Box',
				set_game_data: { amandas_box_ripped_open: true },
				text: [
					this.gameData.amandasBoxRippedOpen ? 'You open the box again.' : 'You rip apart the duct tape and open the box',
					this.gameData.experiencedScripts.includes('Wardrobe.the-shoes') ? 'The box no longer has any of her clothes. Just her membership card to the Fitness Zone Gym' : 'It has a sports bra, tight running shorts, a tank top, a pair of joggers, and a membership card to the Fitness Zone gym and several fitness books and magazines.',
				],
				...((this.gameData.amandasBoxRippedOpen && this.gameData.gameAttrs['gender'] === 'male') || this.gameData.experiencedScripts.includes('Wardrobe.the-shoes') ? {
					choices: [{
						id: 'start',
						name: 'Back to the wardrobe'
					}]
				} : { continue_to: 'memory' }),
			},

			{
				id: 'memory',
				speaker: 'Memory',
				text: [
					'It is her standard gym outfit for her weekly visit to Fitness Zone. That\'s all the gym wear she had.',
					'She never missed a single week. Very consistent.',
					...(this.gameData.gameAttrs['gender'] === 'female' ? ['May be there is something here that fits your new body'] : [])
				],
				...(this.gameData.noteInAmandasBoxSeen && this.gameData.gameAttrs['gender'] === 'female' ? {
					choices: [
						{
							id: 'try-running-shorts',
							name: 'Try Amanda\'s running shorts'
						}
					]
				} : { continue_to: 'thebox' }),
			},
			{
				id: 'thebox',
				speaker: 'The Sealed Box',
				set_game_data: { note_in_amandas_box_seen: true },
				text: [
					'There is white sheet of paper peeking out of one of the magazines'
				],
				choices: [
					{
						id: 'examine-note',
						name: 'Pull it out'
					}
				],
			},
			{
				id: 'examine-note',
				speaker: 'The Sealed Box',
				text: [
					'You pull the note out and read it',
					'Babe',
					'',
					'Here is a parting gift so you don\'t miss me. It\'s an exact replica of your\'s truly\'s holy staff. (and a drawing of a penis)',
					'Luv, Z'
				],
				continue_to: 'analysis',
			},

			{
				id: 'analysis',
				speaker: 'Analysis',
				text: [
					'It looks a lot like she was cheating on you? And her lover gifted her a dildo in the shape of his own dick. It\'s not even subtle',
				],
				continue_to: 'reaction',
			},
			{
				id: 'reaction',
				speaker: 'Shivers',
				text: [
					'You feel a sudden sinking feeling in your heart. She left you a few months ago and you have almost moved on. But it looks like she was also cheating on you.',
					'It\'s just a bit hard to take'
				],
				continue_to: 'questioning',
			},
			{
				id: 'questioning',
				speaker: 'Memory',
				text: [
					'Who\'s this Z guy? It doesn\'t ring any bells. Only one way to find out',
				],
				choices: [
					...(this.gameData.pendingGoalNames.includes('find_out_who_z_is') || this.gameData.completedGoalNames.includes('find_out_who_z_is') ? [] : [{
						id: 'go-to-gym',
						name: 'Go to the gym and find out (Accept task)',
					}]),
					{
						id: 'start',
						name: 'Nevermind. Back to the wardrobe.',
					}
				],
			},

			{
				id: 'try-running-shorts',
				speaker: 'The Running Shorts',
				text: [
					'The running short looks too small at a glance but feels it like the material will stretch',
					'As expected it expands and stretchs over your skin smoothly as you slip your legs into them',
					'It doesn\'t even feel like you\'re wearing anything. It\'s light and tight.'
				],
				choices: [
					{
						id: 'sports-bra',
						name: 'Try the sports bra',
					}
				]
			},

			{
				id: 'sports-bra',
				speaker: 'The Sports Bra',
				text: [
					'The material feels smooth in your hand',
					...(this.gameData.gameAttrs['autogynephelic'] ? ['You feel excited to wear your first bra'] : ['You feel a tinge of shame as you wear your first bra']),
					'The bra needs to zipped up from the front. You let your hands inside the shoulder straps and pull the bra forward and attach the zip.',
					'As you zip yourself up you feel the bra firmly cupping your breasts. Now your tits don\'t look so big',
					'You experimentally move around and notice that your puppies are no longer jiggling'
				],
				choices: [
					{
						id: 'tank-top',
						name: 'Try the tank top',
					}
				]
			},

			{
				id: 'tank-top',
				speaker: 'The Pink Tank Top',
				text: [
					'You wear the pink sleeveless top and cover your midriff. Still it doesn\'t reach down enough to hide your shapely ass. They are still on display for all the world to see and enjoy.'
				],
				choices: [
					{
						id: 'the-joggers',
						name: 'Try the joggers',
					}
				]
			},

			{
				id: 'the-joggers',
				speaker: 'The Black Joggers',
				text: [
					'You wear the joggers. They are roomy, comfortable and soft against your skin',
					this.gameData.gameAttrs['autogynephelic'] ? 'You wonder if it would have been hotter if these were tight yoga pants instead' : 'Thankfully she didn\'t have form-hugging yoga pants that would advertised your curves to every person that laid their eyes on you.'
				],
				choices: [
					{
						id: 'the-shoes',
						name: 'Try the shoes',
					}
				]
			},
			{
				id: 'the-shoes',
				speaker: 'The Sneakers',
				set_game_data: { clothing: 'amandas_gym_wear' },
				text: [
					'You only have your old sneakers from a few years ago when you were much leaner.',
					'They are 1 size too big and it\'s going to be slightly annoying to walk in them. But since Amanda didn\'t leave any footwear for you to wear for when you\'ve inexplicably turned into chick, you\'re going to settle for wearing them now.'
				],
				choices: [
					{
						id: 'start',
						name: 'Back to the wardrobe',
					},
				],
			},
			{
				id: 'go-to-gym',
				speaker: 'Willpower',
				text: [
					'Great! It\'s time to get some closure.',
				],
				set_game_goal: 'find_out_who_z_is',
				choices: [
					...(this.gameData.gameAttrs['gender'] === 'female' ? [{
						id: 'try-running-shorts',
						name: 'Try the running shorts',
					}] : []),
					{
						id: 'start',
						name: 'Back to the wardrobe',
					},
				],
			},
			{
				id: 'shut-wardrobe',
				speaker: 'Wardrobe',
				text: [
					'The wardrobe closes'
				],
				continue_to: 'the_end',
			},
		]
	}

	event (id) {
		return this.events().filter((ev) => {
			return ev['id'] === id
		})[0]
	}

}
