export default class GapStore {
	constructor (gameData) {
		this.gameData = gameData
		this.name = 'The Gap Store'
		this.bgImage = 'places/clothing_store.png'
		this.description = 'You are at the local Gap Store where you generally buy your clothes'
	}

	actions () {
		let actions = [
			{
				name: 'Browse the men\'s collection',
				scene: 'MenCollection',
				icon: 'icons8-male-80.png',
				condition: true,
			},
			{
				name: 'Browse the women\'s collection',
				scene: 'WomenCollection',
				icon: 'icons8-female-80.png',
				condition: true,
			},
		]

		return actions.filter((element) => {
			return element['condition']
		})

	}

	isSleepTime (hour) {
		return (hour >= 21 || hour < 6)
	}
}
