export default class CoachPaulson {
    constructor(gameData) {
        this.gameData = gameData
    }

    events() {
        return [
            {
                id: "start",
                speaker: 'The Receptionist',
                text: ["You go to the receptionist"],
                continue_to: 'end-of-content'
            },
            {
                id: 'end-of-content',
                speaker: 'Developer',
                text: [
                  'This part of the story will be added in the next major release'
                ],
                continue_to: 'the_end',
            },
        ]
    }

    event(id) {
        return this.events().filter((ev) => {
            return ev['id'] === id
        })[0]
    }

}
