export default class StripClub {
	constructor (gameData) {
		this.gameData = gameData
		this.name = 'Itsi Bitsi Bikinis'
		this.bgImage = 'places/club_interior.png'
		this.description = 'You are at Itsi Bitsi Bikinis, one of Palmgrove\'s hottest strip clubs'
	}

	actions () {
		let actions = [
			{
				name: 'Get past the bouncer',
				scene: 'StripClubBouncer',
				icon: 'icons8-communicate-96.png',
				condition: true,
			},
		]

		return actions.filter((element) => {
			return element['condition']
		})

	}

	isSleepTime (hour) {
		return (hour >= 21 || hour < 6)
	}
}
