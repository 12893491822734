<template>
	<div id="place" style="">
		<header class="h-36 lg:h-48 bg-cover relative bg-center	"
						:style="`background-image: url('${require(`@/assets/images/${currentLocation.bgImage}`)}');`">
			<div class="absolute -bottom-3 text-center left-0 right-0">
				<span class="text-center bg-white font-bold border border-blue-800 text-xl lg:text-2xl px-3 py-0 rounded-full">{{
						currentLocation.name
					}}</span>
			</div>
		</header>
		<section class="bg-white p-4 lg:w-9/12 lg:mx-auto lg:pt-8 lg:px-12 h-screen lg:shadow-xl">
			<div class="grid grid-cols-1 lg:grid-cols-12 lg:gap-10">
				<div class="col-span-4">
					<div>{{ currentLocation.description }}</div>
					<hr class="my-4 hidden lg:block">
					<template v-if="currentLocation.name !== 'City'">
						<div class="text-left">
							<button v-if="gameAttrs['clothing'] !== 'none'" class="button is-small is-primary mt-2"
											v-on:mouseover="playButtonHoverSound()"
											v-on:click="navigateTo('City')">
								<span>Leave {{ currentLocation.name }}</span>
								<span class="ml-3"><i class="fas fa-sign-out-alt"></i></span>
							</button>
							<span v-else class="text-red-600 mt-2">You can't leave the house naked. Find something to wear</span>
						</div>
					</template>
					<hr class="my-4 block lg:hidden">
				</div>
				<div class="col-span-8">

					<div class="grid lg:grid-cols-2">
						<div id="place_actions">
							<h5 class="font-bold mt-2 lg:mt-0 lg:mb-4 text-lg">Actions Available</h5>
							<div class="flex flex-col	">
								<div v-for="action in allActions"
										 class="flex flex-row cursor-pointer rounded-lg items-center my-1 p-1 hover:bg-yellow-100 w-11/12"
										 v-on:mouseover="playButtonHoverSound()" v-on:click="initiateScene(action.scene)">
									<img :src="require(`@/assets/images/icons/${action.icon}`)" alt="" class="h-8 mr-2">
									<span>{{ action.name }}</span>
								</div>
							</div>
						</div>
						<template v-if="currentLocation.name === 'City'">
							<div>
								<h5 class="font-bold mt-2 lg:mt-0 lg:mb-4 text-lg">Places you can visit</h5>
								<div class="flex flex-col	">
									<div class="flex flex-row cursor-pointer rounded-lg items-center my-1 p-1 hover:bg-yellow-100 w-11/12"
											 v-for="place in currentLocation.places()"
											 v-on:mouseover="playButtonHoverSound()" v-on:click="navigateTo(place['location'])">
										<img :src="require(`@/assets/images/icons/${place.icon}`)" alt="" class="h-8 mr-2">
										<span>{{ place['name'] }}</span>
									</div>
								</div>
							</div>
						</template>
					</div>
				</div>
			</div>

		</section>
	</div>

</template>

<script>
import { mapGetters } from 'vuex'

const {
	Howl,
	Howler
} = require('howler')

export default {
	name: 'Place',
	props: [],

	computed: {
		...mapGetters(
			[
				'gender',
				'currentLocation',
				'gameAttrs',
				'processedTheHow',
				'processedTheWhy',
				'processedWhatsNext',
			]
		),
		phoneHasAlerts () {
			return (this.gameAttrs['received_client_profile'] && this.gameAttrs['client_profile_is_unread'])
		}
		,
		allActions () {
			let globalActions = [
				{
					name: 'Phone',
					scene: 'Phone',
					icon: this.phoneHasAlerts ? 'icons8-phonelink-ring.gif' : 'icons8-iphone-100.png',
					spinner: this.phoneHasAlerts
				}
			]

			let reorganized = this.processedTheHow &&
				this.processedTheWhy &&
				this.processedWhatsNext

			if (this.gameAttrs['gender'] === 'female' && !reorganized) {
				globalActions.push({
					name: 'Process what happened to you',
					scene: 'Reorganizing',
					icon: 'icons8-thought-balloon-96.png',
				})
			}
			return [...globalActions, ...this.currentLocation.actions()]
		}
	},

	methods: {
		navigateTo (locationName) {
			this.$store.commit('navigateTo', locationName)
			this.scrollToTop()
		},
		initiateScene (SceneName) {
			this.$store.dispatch('initiateScene', SceneName)
			this.scrollToTop()
		},
		playButtonHoverSound () {
			const context = new AudioContext()
			context.resume().then(function () {
				var sound = new Howl({
					src: [require('@/assets/audio/fx/button-hover.mp3')]
				})
				Howler.stop()
				sound.play()
			})
		},
		scrollToTop(){
			let app = document.getElementById('app')
			if (app !== null) {
				app.scrollIntoView();
			}
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.action-icon {
	cursor: pointer;
	border-radius: 4px;
	width: 100%;
	display: flex;
	padding: 6px 3px;

	img {
		width: 30px;
	}

	span {
		line-height: 30px;
		padding-left: 6px;
	}

	&:hover {
		img {
			transform: scale(1.1);
		}

		box-shadow: 0 1px 3px rgba(0, 0, 0, .1)
	}
}

.action-list {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
</style>
