export default class Niels {
	constructor (gameData) {
		this.gameData = gameData
	}

	events () {
		return [
			{
				id: 'start',
				speaker: 'Niels',
				text: ['You ring the door bell. A bearded young man opens the door and says nothing.'],
				choices: [{
					id: 'niels',
					name: 'I am looking for Niels'
				}]
			},
			{
				id: 'niels',
				speaker: 'Niels',
				text: [
					'I am Niels. You must be?'
				],
				choices: [
					{
						id: 'omj',
						name: 'Old Man Joe sent me.'
					}
				]
			},
			{
				id: 'omj',
				speaker: 'Niels',
				text: [
					'Oh. Very nice. Please come in. Do you have a name?'
				],
				continue_to: 'name'
			},

			{
				id: 'name',
				speaker: 'Analysis',
				text: [
					'Quick, think on your feet! You need to come up with a name.',
					'Just go with your current name\'s female version: Natalie',
				],
				continue_to: 'natasha?'
			},
			{
				id: 'natasha?',
				speaker: 'Analysis',
				text: [
					'Or is it Natasha?',
				],
				choices: [
					{
						id: 'natasha',
						name: 'My name is Natasha (enter the apartment)'
					},
					{
						id: 'natalie',
						name: 'My name is Natalie (enter the apartment)'
					},
				]
			},
			{
				id: 'natalie',
				speaker: 'Niels',
				text: [
					'"Natalie. Hmmm...", he pauses to think. "It\'s not a fitting name. But that\'s a discussion for another day".'
				],
				set_game_data: { nielsName: 'Natalie' },
				choices: [
					{
						id: 'model?',
						name: 'So you\'re looking for a model?'
					}
				]
			},
			{
				id: 'natasha',
				speaker: 'Niels',
				text: [
					'You definitely look like a Natasha.'
				],
				set_game_data: { nielsName: 'Natasha' },
				choices: [
					{
						id: 'model?',
						name: 'So you\'re looking for a model?'
					}
				]
			},
			{
				id: 'model?',
				speaker: 'Niels',
				text: [
					'Not exactly. It\'s not a model that I want.',
					'What I want is a muse?'
				],
				continue_to: 'katja',
			},

			{
				id: 'katja',
				speaker: 'Katja',
				text: [
					'There\'s a young, attractive woman in the sofa, smoking a cigarette. She\'s dressed stylishly in a white shirt and a skirt like an office worker and observes your conversation with interest',
				],
				choices: [
					{
						id: 'muse?',
						name: 'A muse?'
					}
				]
			},
			{
				id: 'muse?',
				speaker: 'Niels',
				text: [
					'Not exactly. It\'s not a model that I want.',
					'What I want is a muse?'
				],
				choices: [
					{
						id: 'katja-intro',
						name: 'A muse?'
					}
				]
			},

			{
				id: 'katja-intro',
				speaker: 'Niels',
				text: [
					'"That\'s Katja, by the way." he says ignoring your question',
					`Katja, meet ${this.gameData.nielsName}. he says to her`
				],
				continue_to: 'katja-hello'
			},
			{
				id: 'katja-hello',
				speaker: 'Katja',
				text: [
					`"Hey ${this.gameData.nielsName}", she says with a smile`
				],
				continue_to: 'muse-answer'
			},
			{
				id: 'muse-answer',
				speaker: 'Niels',
				text: [
					`"You see... I am an artist in search of inspiration. I need someone who can be around me, spend time with me, and inspire that next big idea inside me."`
				],
				continue_to: 'around-all-time',
			},
			{
				id: 'around-all-time',
				speaker: 'Analysis',
				text: [
					`"What? That sounds like a big commitment? Wasn't this supposed to be a modeling job?"`
				],
				choices: [{
					id: 'around-all-time?',
					name: `I thought this was a modeling job.`
				}]
			},
			{
				id: 'around-all-time?',
				speaker: 'Niels',
				text: [
					`"Yes it is. We are going to do a photoshoot. I'll let you know if you can be my muse based on how that goes."`,
				],
				continue_to: 'any-questions?',
			},
			{
				id: 'any-questions?',
				speaker: 'Niels',
				text: [
					'"Do you have any questions?"',
				],
				choices: this.questionChoices(),
			},
			{
				id: 'no-experience',
				speaker: "Niels",
				text: [
					'"I know. I specifically asked for someone like you. A fresh unbiased soul. So don\'t worry about it. You\'ll do alright"'
				],
				choices: this.questionChoices(),
			},
			{
				id: 'whats-the-job',
				speaker: "Niels",
				text: [
					'"You\'re going to model a bodysuit for a lingerie brand called Innocence."',
				],
				continue_to: 'analysis-on-bodysuit',
			},
			{
				id: 'analysis-on-bodysuit',
				speaker: 'Analysis',
				text: [
					'"Oh boy! Tight gym clothes is one thing. But lingerie? That\'s another level."'
				],
				choices: this.questionChoices()
			},
			{
				id: 'whats-the-pay',
				speaker: "Niels",
				text: [
					'"You\'ll get $2,000 for this. But only if I like it."',
				],
				choices: this.questionChoices()
			},
			{
				id: 'no-more-questions',
				speaker: 'Niels',
				advance_time: 60,
				text: [
					'"Very good. We can get started. I\'ll need an hour to set things up for the shoot. You and Katja keep each other company until then."'
				],
				continue_to: 'set_scene:KatjaAtNiels'
			},


		]
	}

	questionChoices(){
		return [
			{
				id: 'no-experience',
				name: "I have no experience modeling..."
			},
			{
				id: 'whats-the-pay',
				name: "What's the pay?"
			},
			{
				id: 'whats-the-job',
				name: "What's the job?"
			},
			{
				id: 'no-more-questions',
				name: "I have no more questions (conclude)"
			},
		]
	}

	event (id) {
		return this.events().filter((ev) => {
			return ev['id'] === id
		})[0]
	}

}
