export default class FirstBodyChange {
	constructor (gameData) {
		this.gameData = gameData
	}

	events () {
		return [
			{
				id: 'start',
				speaker: 'Perception',
				text: [
					'As you get out of the bed, you realize something feels off',
					'It\'s your ears. It feels like your hair is getting inside your ears',
				],
				choices: [
					{
						id: 'touch_hair',
						name: 'Touch your hair'
					}
				]
			},
			{
				id: 'touch_hair',
				set_pc_image: 'people/nathan-s1.jpg',
				set_game_data: {
					'first_night_slept': true,
					'change_score': 1
				},
				speaker: 'Your Hair',
				text: ['Suddenly, there\'s a lot of hair on your head. You were not in any urgent need of a haircut. But now, you look like you are long overdue.'],
				choices: [
					{
						id: 'find_mirror',
						name: 'Find a mirror',
					},
				],
			},
			{
				id: 'find_mirror',
				speaker: 'The Mirror',
				text: [
					'Looking at your face in the mirror, your head seems to have shaggy, medium-length hair',
					'And your face... Your face seems to have gotten younger. There\'s no trace of any facial hair. You won\'t be needing to shave before your big meeting today.'
				],
				choices: [{
					id: 'whats_happening',
					name: 'What\'s happening?'
				},]
			},
			{
				id: 'whats_happening',
				speaker: 'Analysis',
				text: ['There\'s no way to tell. May be you lost some weight due to all the jerking off you have been doing?'],
				continue_to: 'Perception_note2'
			},
			{
				id: 'Perception_note2',
				speaker: 'Perception',
				text: ['Speaking of the body... doesn\'t that shirt feel different in some way? Looser, roomier than before.'],
				choices: [
					{
						id: 'remove_shirt',
						name: 'Remove shirt',
					},
				],
			},
			{
				id: 'remove_shirt',
				speaker: 'Perception',
				text: ['There it is. Whatever little muscles you had now seem to be gone'],
				continue_to: 'intellect_note'
			},
			{
				id: 'intellect_note',
				speaker: 'Analysis',
				text: ['It doesn\'t seem to be a drastic change but it is still a big enough change to be noticeable. Maybe it\'s best to wait and watch. You can may be cut your hair and gain that weight back.'],
				choices: [
					{
						id: 'wait_and_watch',
						name: 'That\'s right. It could just be... I don\'t know what it is but I think I will deal with it later',
					},
				],
			},
			{
				id: 'wait_and_watch',
				speaker: 'Analysis',
				text: ['Seems like the only choice you have at the moment'],
				continue_to: 'the_end',
			}
		]
	}

	event (id) {
		return this.events().filter((ev) => {
			return ev['id'] === id
		})[0]
	}

}
