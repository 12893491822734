export default class PhotoShoot {
	constructor (gameData) {
		this.gameData = gameData
	}

	events () {
		return [
			{
				id: 'start',
				speaker: 'The Changing Room',
				text: ['You go into the changing room and immediately the black, lace, one-piece bodysuit catches you eyes.'],
				continue_to: 'willpower'
			},
			{
				id: 'willpower',
				speaker: 'Willpower',
				text: ['You need the money and this seems to be easiest way to get it. Let\'s do it'],
				choices: [
					{
						id: 'undress',
						name: 'Undress'
					}
				]
			},
			{
				id: 'undress',
				speaker: 'The Changing Room',
				text: ['You strip all your clothes and get naked again.'],
				choices: [
					{
						id: 'examine-the-bodysuit',
						name: 'Examine the bodysuit'
					}
				]
			},
			{
				id: 'examine-the-bodysuit',
				speaker: 'Perception',
				text: [
					'The bodysuit has snaps at the crotch which means you should wear it from top to bottom and close the snaps at the crotch'
				],
				choices: [
					{
						id: 'wear-the-bodysuit',
						name: 'Wear the bodysuit'
					}
				]
			},
			{
				id: 'wear-the-bodysuit',
				speaker: 'The Changing Room',
				text: [
					'You slide it over your body from top to bottom and finally close the snaps at the crotch.',
				],
				continue_to: 'perception'
			},
			{
				id: 'perception',
				speaker: 'Perception',
				text: [
					'The material hugs you firmly and yet so softly that you still feel naked.',
					'But it\'s not just nakedness you feel. You feel... sexy',

				],
				continue_to: 'memory'
			},
			{
				id: 'memory',
				speaker: 'Memory',
				text: [
					'Dressing well definitely has made you feel confident before. It has never made you feel sexy',
				],
				continue_to: 'shivers'
			},
			{
				id: 'shivers',
				speaker: 'Shivers',
				text: [
					'The deep neckline displaying the curves of your breasts can explain that',
				],
				continue_to: 'niels_calls'
			},
			{
				id: 'niels_calls',
				speaker: 'Niels',
				text: [
					`"${this.gameData.nielsName}, if you are ready, we can get started", Niels calls out you.`,
				],
				choices: [
					{
						id: 'get-out',
						name: 'Go out into the kitchen'
					}
				]
			},
			{
				id: 'get-out',
				speaker: 'Katja',
				text: [
					`"Wow! You're a bombshell" she says truly impressed`
				],
				continue_to: 'niels-responds'
			},
			{
				id: 'niels-responds',
				speaker: 'Niels',
				text: [
					`"Didn't I tell you she'd kill it in that bodysuit."`
				],
				continue_to: 'perception-resp'
			},
			{
				id: 'perception-resp',
				speaker: 'Perception',
				text: [
					`"You've never been particularly complimented on your looks as a man. This feels strange and good."`
				],
				continue_to: 'niels-ask'
			},
			{
				id: 'niels-ask',
				speaker: 'Niels',
				text: [
					`"Let's get started! ${this.gameData.nielsName}, why don't you wear these and take a seat at the dinner table and pour yourself a coffee", he says handing you a pair of stilettos`
				],
				continue_to: 'stilettos',
			},
			{
				id: 'stilettos',
				speaker: 'Perception',
				text: [
					'Those stilettos look challenging'
				],
				choices: [
					{
						id: 'try_stilettos',
						name: 'Wear the stilettos'
					}
				]
			},
			{
				id: 'try_stilettos',
				speaker: 'The Stilettos',
				text: [
					'You sit down on the chair and wear the shoes. They are not difficult to wear but you get a feeling it will take time to get used to standing or walking in it',
				],
				choices: [
					{
						id: 'coffee?',
						name: 'I need to pour the coffee?'
					}
				]
			},
			{
				id: 'coffee?',
				speaker: 'Niels',
				text: [
					`"Don't worry about it. Just follow my instructions for the next 30 minutes and we will be done."`
				],
				choices: [{
					id: 'comply',
					name: '(Do as he says)'
				}]
			},
			{
				id: 'comply',
				speaker: 'Niels',
				image_path: '1 - yH0IjyQ.png',
				text: [
					`*camera shutter sound*`,
					'"There it is. Nice one", he says looking at the camera screen.',
					'Now turn and look at me'
				],
				choices: [{
					id: 'comply2',
					name: '(Turn and look)'
				}]
			},
			{
				id: 'comply2',
				speaker: 'Niels',
				image_path: '2 - 0FZi9wY.png',
				text: [
					`*camera shutter sound*`,
					'"Great!", he says looking at the camera screen.',
					'Now stand up, lean back at the table and pose with the cup in your hand'
				],
				choices: [{
					id: 'comply3',
					name: '(Do as he says)'
				}]
			},
			{
				id: 'comply3',
				speaker: 'Niels',
				image_path: '3 - AoXvlkE.png',
				text: [
					`*camera shutter sound*`,
					'"Beautiful!", he says looking at the camera screen.',
					'That was better than what I expected',
				],
				continue_to: 'warmup'
			},
			{
				id: 'warmup',
				speaker: 'Niels',
				text: [
					'Now that the warm up is over, we need to do the real shooting.',
				],
				continue_to: 'willpower-what'
			},
			{
				id: 'willpower-what',
				speaker: 'Willpower',
				text: [
					'What? Here I was thinking that this was going very well'
				],
				continue_to: 'niels-continue'
			},
			{
				id: 'niels-continue',
				speaker: 'Niels',
				text: [
					'I need one good shot. Only one. But I want a lot of emotion in it.',
				],
				choices: [
					{
						id: 'niels-continue1',
						name: 'I don\'t know how to do that'
					},
					{
						id: 'attempt-a-pose',
						name: '(Attempt a pose)'
					}
				]
			},
			{
				id: 'attempt-a-pose',
				speaker: 'Niels',
				image_path: '6 - jUBBDW7.png',
				text: [
					'That\'s cute. But it doesn\'t inspire, it doesn\'t connect.'
				],
				continue_to: 'niels-continue1'
			},
			{
				id: 'niels-continue1',
				speaker: 'Niels',
				text: [
					'The reason I thought you would be a good fit for this brand, Innocence, is because I sensed a little bit of innocence in you.',
					'You seem like someone who doesn\'t even know her true self. Like someone who is curious to know more.',
					'So I want you to go inside your head, find out what you\'re curious about... and come up with something.'
				],
				choices: [
					{
						id: 'think',
						name: 'Reflect on your innocence'
					}
				]
			},
			{
				id: 'think',
				speaker: 'Shivers',
				text: [
					'What\'s so innocent about you?',
					'What are you curious to know about yourself?',
				],
				continue_to: 'shivers-think'
			},
			{
				id: 'shivers-think',
				speaker: 'Shivers',
				text: [
					'It\'s definitely your body',
					'You have only had it for a little time and in that little time it has definitely affected *you*',
					'But what are you most curious about?',
					'What comes to mind first?'
				],
				choices: [
					{
						id: 'breasts',
						name: 'Your breasts'
					}, {
						id: 'pussy',
						name: 'Your vagina'
					}
				]
			},
			{
				id: 'breasts',
				speaker: 'The Kitchen',
				image_path: '4 - o5JDUVt.png',
				text: [
					'As you lose yourself in thought, you look down ony your breasts as your hands grab the cups of your bodysuit',
				],
				continue_to: 'camera-click'
			},
			{
				id: 'camera-click',
				speaker: 'Niels',
				text: [
					'*camera clicks*',
					'"Beautiful", Niels says. That\'s exactly what I wanted',
				],
				choices: [
					{
						id: 'smart',
						name: 'That was a neat trick.'
					}
				]
			},
			{
				id: 'pussy',
				speaker: 'Niels',
				image_path: '5 - RiqFLTN.png',
				text: [
					'As you lose yourself in thought, you reach down to your crotch instinctively with one hand',
				],
				continue_to: 'camera-click'
			},
			{
				id: 'smart',
				speaker: 'Niels',
				text: [
					'"Thank you Ma\'am.", he says.'
				],
				choices: [
					{
						id: 'need-money',
						name: 'If we are done, can I get paid. I need money. Urgently.'
					}
				]
			},
			{
				id: 'need-money',
				speaker: 'Niels',
				text: [
					'"Of course", he says handing you the cash.',
					'"There\'s also another thing I want to tell you. I think you will be a good muse. If you decide to be my muse, you should drop by some time"'
				],
				money_on_hand_change: 2000,
				choices: [
					{
						id: 'thanks',
						name: 'Thanks, I\'ll let you know'
					}
				]
			},
			{
				id: 'thanks',
				speaker: 'Niels',
				advance_time: 60,
				text: [
					'"I\'ll see you around. Buh-bye"',
					'Katja waves goodbye'
				],
				continue_to: 'set_scene:EndOfContent'
			},

		]
	}

	event (id) {
		return this.events().filter((ev) => {
			return ev['id'] === id
		})[0]
	}

}
