export default class WomenCollection {
    constructor(gameData) {
        this.gameData = gameData
    }

    events() {
        return [
            {
                id: "start",
                speaker: 'Mirror',
                text: ["You go to the mirror and take a look at yourself"],
                continue_to: 'description'
            },
            {
                id: 'description',
                speaker: 'Mirror',
                sound_file: 'male_description',
                text: [
                    this.gameData.gameAttrs.nameDescription,
                    this.gameData.gameAttrs.workDescription,
                    this.gameData.gameAttrs.headDescription,
                    this.gameData.gameAttrs.bodyDescription
                ],
                continue_to: 'the_end',
            },
        ]
    }

    event(id) {
        return this.events().filter((ev) => {
            return ev['id'] === id
        })[0]
    }

}
