export default class JoesLaundry {
	constructor (gameData) {
		this.gameData = gameData
		this.name = 'Joe\'s Laundry'
		this.bgImage = 'places/clothing_store.png'
		this.description = 'You are at Joe\'s Laundry. An old man is at checkout counter'
	}

	actions () {
		let actions = [
			{
				name: 'Ask for Mike Volkanovski',
				scene: 'OldManJoe',
				icon: 'icons8-communicate-96.png',
				condition: true,
			},
		]

		return actions.filter((element) => {
			return element['condition']
		})

	}

	isSleepTime (hour) {
		return (hour >= 21 || hour < 6)
	}
}
