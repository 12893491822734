export default class Phone {
	constructor (gameData) {
		this.gameData = gameData
	}

	events () {
		return [
			{
				id: 'start',
				text: ['You look at the phone in your hand'],
				speaker: 'Phone',
				choices: this.phoneChoices(),
			},
			{
				id: 'client_profile_notification',
				text: ['The email is from Roger. It has an attachment that can only be opened in a Computer'],
				speaker: 'Phone',
				set_game_data: { 'client_profile_is_unread': false },
				continue_to: 'the_end'
			},
			{
				id: 'text_roger',
				text: ['You anxiously type out, "Roger, can I meet you at your house? It\'s very important. And we can only do this in person."'],
				speaker: 'Phone',
				continue_to: this.gameData.milenaMeetingWentWell ? 'roger_normal_response' : 'roger_angry_response'
			},
			{
				id: 'roger_normal_response',
				text: ['Sure we can. Is everything alright? Brenda told me you weren\'t feeling so well.'],
				speaker: 'Roger',
				choices: [{
					id: 'related',
					name: 'It\'s related to that. It\'s something that can only be seen not told'
				}]
			},
			{
				id: 'related',
				text: ['Ok then. I\'ll be here. Come over anytime.'],
				speaker: 'Roger',
				complete_game_goal: 'setup_a_meeting_with_roger',
				set_game_goal: 'meet_roger_at_his_home',
				continue_to: 'the_end'
			},
			{
				id: 'roger_angry_response',
				text: ['I hope it\'s something important and not excuses or apologies about the client meeting and your subsequent disappearance on Friday.'],
				speaker: 'Roger',
				choices: [{
					id: 'serious',
					name: 'No it\'s not. This is serious.'
				}]
			},
			{
				id: 'serious',
				text: ['What\'s so serious? Brenda told me you weren\'t feeling so well.'],
				speaker: 'Roger',
				continue_to: 'perception'
			},
			{
				id: 'perception',
				text: ['The displeasure in his voice morphs into concern as soon as he remembers you were sick.'],
				speaker: 'Roger',
				choices: [{
					id: 'related',
					name: 'It\'s related to that. Please tell me you will come and see me.'
				}]
			},
			{
				id: 'make_a_call',
				speaker: 'Phone',
				text: ['Who do you want to call?'],
				choices: [
					{
						id: 'colby',
						name: 'Colby Simmons (your next door neighbour and friend)',
					},
					{
						id: 'amanda',
						name: 'Amanda Fox (your ex-girlfriend)',
					},
					{
						id: 'roger',
						name: 'Roger Slattery (your boss)',
					},
					{
						id: 'close_phone',
						name: 'Put away the phone',
					},
				]
			},
			this.ringer('colby'),
			this.colbyResult(),
			this.nothing('Colby'),
			this.ringer('amanda'),
			this.amandaResult(),
			this.nothing('Amanda'),
			this.ringer('roger'),
			this.rogerResult(),
			this.nothing('Roger'),
			{
				id: 'got_it_roger',
				speaker: 'Roger',
				text: ['"So what did you call me for?"'],
				set_game_goal: 'review_client_profile',
				set_game_data: { 'spoken_to_roger_about_meeting': true },

				choices: [
					{
						id: 'nothing-roger',
						name: 'Nothing really. I probably misdialed'
					},
				]

			},
			{
				id: 'seen_it_already',
				speaker: 'Roger',
				text: ['"That\'s good to hear. Good luck with tomorrow\'s meeting"',
					'"So what did you call me for?"'],
				choices: [{
					id: 'nothing-roger',
					name: 'Nothing really. I probably misdialed'
				},],
			},
			{
				id: 'close_phone',
				speaker: 'Phone',
				text: ['You put away the phone'],
				continue_to: 'the_end',
				advance_time: 0
			},
		]
	}

	phoneChoices () {
		let choices = [
			{
				id: 'make_a_call',
				name: 'Make a call',
			},
			{
				id: 'close_phone',
				name: 'Put away the phone',
			},
		]
		if (this.gameData.clientProfileIsUnread) {
			choices.unshift({
				id: 'client_profile_notification',
				name: 'Check out the new email'
			})
		}
		if (this.gameData.pendingGoalNames.includes('setup_a_meeting_with_roger')) {
			choices.push({
				id: 'text_roger',
				name: 'Text Roger asking to meet'
			})
		}
		return choices
	}

	ringer (speaker) {
		return {
			id: speaker,
			speaker: 'Phone',
			text: ['*the phone rings*'],
			continue_to: `${speaker}_result`
		}
	}

	colbyResult () {
		return {
			id: 'colby_result',
			speaker: 'Colby',
			text: ['"Hey man, what\'s up?"'],
			choices: [
				{
					id: 'nothing-colby',
					name: 'Nothing really. I probably misdialed'
				}
			]
		}
	}

	nothing (speaker) {
		return {
			id: `nothing-${speaker.toLowerCase()}`,
			speaker: speaker,
			text: [{
				'Colby': '"Oh! That\'s OK", he says before hanging up',
				'Amanda': '"Hmmm... That\'s alright", she says before hanging up',
				'Roger': '"Get a grip, my friend.", he says before hanging up',
			}[speaker]],
			continue_to: 'make_a_call'
		}
	}

	amandaResult () {
		return {
			id: 'amanda_result',
			speaker: 'Amanda',
			text: ['"Hey Nathan, what\'s up?"'],
			choices: [
				{
					id: 'nothing-amanda',
					name: 'Nothing really. I probably misdialed'
				},
			]
		}
	}

	rogerResult () {
		if (!(this.gameData.spokenToRogerAboutMeeting || !this.gameData.clientProfileIsUnread) && this.gameData.currentDateTime.isSame('2030-04-18', 'day')) {

			let choices = []

			if (!this.gameData.clientProfileIsUnread) {
				choices.push({
					id: 'seen_it_already',
					name: 'I went through it. I am confident we can land this Client.'
				})
			} else {
				choices.push({
					id: 'got_it_roger',
					name: 'Got it Roger. I\'ll look into it'
				})
			}

			if (this.gameData.receivedClientProfile) {
				return {
					id: 'roger_result',
					speaker: 'Roger',
					sound_file: 'roger_result_mailed',
					text: [
						'"Hey Nathan. It\'s a good thing you called. Listen. We are getting a profile of the new client who\'s visiting our office tomorrow. It has been emailed to you.',
						'"Go through their profile. It should help you prepare for tomorrow\'s meeting."'
					],
					choices: choices
				}
			} else {
				return {
					id: 'roger_result',
					speaker: 'Roger',
					sound_file: 'roger_result_to_mail',
					text: [
						'"Hey Nathan. It\'s a good thing you called. Listen. We are getting a profile of the new client who\'s visiting our office tomorrow. I will have it emailed to you.',
						'"Go through their profile. It should help you prepare for tomorrow\'s meeting."'
					],
					choices: choices
				}
			}
		}
		return {
			id: 'roger_result',
			speaker: 'Roger',
			text: ['"Nathan"'],
			choices: [
				{
					id: 'nothing-roger',
					name: 'Nothing really. I probably misdialed'
				},
			]
		}
	}

	event (id) {
		return this.events().filter((ev) => {
			return ev['id'] === id
		})[0]
	}

}
