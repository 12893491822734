export default class ColbyFirstCall {
    constructor (gameData) {
        this.gameData = gameData

    }

    events () {
        return [
            {
                'id': 'start',
                'text': ['Your phone rings with a familiar tune waking you from sleep. It flashes the name "Colby" on it.'],
                'speaker': 'Phone',
                'continue_to': 'colby_info',
            },
            {
                'id': 'colby_info',
                'text': ['Colby is your neighbour. He lives next door in your Apartment. He is a warm, friendly guy who acts like you are his younger brother.'],
                'speaker': 'Memory',
                'choices': [
                    {
                        'id': 'answer',
                        'name': '"Hey Colby!" (answer the call)'
                    },
                    {
                        'id': 'why_did_i_move',
                        'name': '"Why did I move here again?"'
                    }
                ]
            },
            {
                id: 'why_did_i_move',
                text: ['"You moved here to work for Morse+Slattery, a reputed Digital Marketing consultancy firm. It was your dream job."',
                    '"You came here with your girlfriend Amanda. Amanda Fox. Palmgrove is a place with a beautiful beach, great weather, and nice people. You even imagined you would settle down here with her and start a family"'],
                speaker: 'Memory',
                choices: [

                    {
                        id: 'what_happened_there',
                        name: '"Oh! What happened then?"'
                    },
                ]
            },
            {
                id: 'what_happened_there',
                text: [
                    '"Well, one day she abruptly decided to end your relationship. She didn\'t give you much details. But only that she didn\'t feel a "connection" with you anymore. She then moved out hurriedly and left Palmgrove."',
                ],
                speaker: 'Memory',
                choices: [

                    {
                        id: 'sounds_terrible',
                        name: '"Sounds terrible!"'
                    },
                ]
            },
            {
                id: 'sounds_terrible',
                text: [
                    '"Yes. It does. But there\'s an incoming call."',
                ],
                speaker: 'Phone',
                choices: [
                    {
                        id: 'answer',
                        name: '"Hey Colby!" (answer the call)'
                    },
                ]
            },
            {
                id: 'answer',
                text: [
                    '"Hey Nathan, are you coming to the see the Gypsy woman or what? This woman man! I am telling you she\'s the best. Her thing works always. You know how I consulted her before I started my used car business last year?."',
                    '"And guess what? I have sold more cars last year than the previous 3 years combined. She\'s the real deal man"',
                ],
                speaker: 'Colby',
                continue_to: 'reasoning'
            },
            {
                id: 'reasoning',
                skill_required: 'mental.analysis',
                check_type: 'passive',
                score_required: 4,
                fail_node: 'be_there',
                text: ['It\'s true. He seems to have visibly gotten rich. A new Corvette. A gold watch.',
                    'But it could have been that he got better at selling and this is just a placebo effect'],
                speaker: 'Analysis',
                choices: [
                    {
                        id: 'placebo_effect',
                        name: '"That\'s because you became better selling cars. It\'s called experience."'
                    },
                    {
                        id: 'agree_to_go',
                        name: '"Ok man, I\'ll be there."',
                    }
                ]

            },
            {
                id: 'be_there',
                speaker: 'Persuasion',
                text: ['May be it could work for you.'],
                choices: [
                    {
                        id: 'agree_to_go',
                        name: '"Ok man, I\'ll be there."',
                    }
                ]
            },
            {
                id: 'placebo_effect',
                text: [
                    '"May be. But let me ask you this. What have you got to lose? A hundred bucks? What if I am right and her powers are real?"'
                ],
                speaker: 'Colby',
                continue_to: 'evaluation'

            },
            {
                id: 'evaluation',
                text: [
                    'He has a point. You have nothing to lose.',
                    'At the very least, it could be an entertaining evening',
                ],
                speaker: 'Analysis',
                choices: [
                    {
                        id: 'agree_to_go',
                        name: '"Ok man, I\'ll be there."',
                    }
                ]

            },
            {
                id: 'agree_to_go',
                speaker: 'Colby',
                text: ['"That\'s great man. I\'ll set you an appointment today evening then"'],
                set_game_goal: 'visit_esmeralda',
                continue_to: 'the_end',
                advance_time: 20
            }

        ]
    }

    event (id) {
        return this.events().filter((ev) => {
            return ev['id'] === id
        })[0]
    }

}
