<template>

	<footer class="fixed grid grid-cols-3 bottom-0 right-0 left-0 h-16 bg-white border-t border-gray-200">
		<div class="flex flex-row items-center h-16">
			<div id="profile_pic" class=" lg:w-36 lg:ml-2 border-2 border-white rounded-lg overflow-hidden self-end">
				<img :src="require(`@/assets/images/${pcImageFile}`)" class=" lg:w-36"
						 alt="">
			</div>
			<div class="lg:text-2xl mx-2 lg:mx-4"> {{ pcName }}</div>

		</div>

		<div class="col-span-2 flex flex-row-reverse items-center h-16">


			<div class="mx-2 lg:mx-4">
				<div class="text-center">
					<span class="text-xs font-bold"> {{ currentWeekday }}<br></span>
					<span class="text-sm lg:text-lg"> {{ currentTime }}  <br></span>
				</div>
			</div>

			<div class="mx-2 lg:mx-4">
				<div class="text-center ">
					<span class="text-xs font-bold">Money <br></span>
					<span class="text-sm lg:text-lg">$  {{ moneyOnHand }}</span>
				</div>
				<div id="money_change_notif" class="tooltip">
					Money changed
				</div>
			</div>

			<div class="cursor-pointer mx-4 relative " v-on:click="openGoalsModal()">
				<span class="text-sm lg:text-base mr-2 lg:mr-3"><i class="fas fa-book"></i></span><span
				class="text-sm lg:text-base">Tasks</span>
				<transition name="fade">
					<div id="task_change_notif" :class="['hidden', hasUnreadGoals ? 'block' : '']">
						<i class="fas fa-circle "></i>
					</div>
				</transition>
			</div>

		</div>
	</footer>

	<div :class="{'modal is-large': true, 'is-active': goalsModalVisible }">
		<div class="modal-background overscroll-none"></div>
		<div class="modal-content overscroll-none">
			<div class="bg-white h-full lg:h-auto mx-auto mt-12 lg:my-4 w-full lg:w-6/12 p-4 lg:p-8 rounded-lg">
				<div class="content">
					<span v-on:click="closeGoalsModal()" class="float-right text-red-800 cursor-pointer text-sm">
					<i class="fas fa-times"></i> Close
					</span>
					<h4 class="text-2xl font-bold mb-2">Tasks</h4>
					<div v-if="haveNoTasks">You have no tasks at the moment</div>
					<table v-else class="todo-table">
						<colgroup>
							<col style="width: 30px;">
							<col>
						</colgroup>
						<tbody>
						<tr v-for="goal in pendingGameGoals">
							<td class="align-top">
								<i class="far fa-square"></i>
							</td>
							<td class="text-sm lg:text-base"><p><b> {{ goal.title }} </b> <br>
								{{ goal.description }} </p>
							</td>
						</tr>
						<tr v-for="goal in completedGameGoals">
							<td class="align-top 	text-gray-400">
								<i class="fas fa-check-square"></i>
							</td>
							<td class="text-sm lg:text-base line-through	text-gray-400"><p><b> {{ goal.title }} </b> <br>
								{{ goal.description }} </p>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<button class="modal-close is-large" aria-label="close" v-on:click="closeGoalsModal()"></button>
	</div>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'Footer',
	props: [],
	data () {
		return {
			goalsModalVisible: false,
			inventoryModalVisible: false,
		}
	},
	created () {
	},
	updated () {
	},
	computed: {
		...mapGetters(
			['pcName',
				'pcImageFile',
				'moneyOnHand',
				'pendingGameGoals',
				'completedGameGoals',
				'currentWeekday',
				'currentTime',
				'latestSceneEvent',
				'hasUnreadGoals',
				'clothingOwned',
				'clothingEquipped',
				'equippedClothingIds',
				'ownedClothingIds',
				'gameAttrs'
			]
		),
		haveNoTasks () {
			return this.pendingGameGoals.length === 0 && this.completedGameGoals.length === 0
		},
	},
	methods: {
		openGoalsModal () {
			this.goalsModalVisible = true
		},
		openInventoryModal () {
			this.inventoryModalVisible = true
		},
		closeGoalsModal () {
			this.goalsModalVisible = false
			this.$store.commit('readGameGoals')
		},
		closeInventoryModal () {
			this.inventoryModalVisible = false
		},
		switchView (view) {
			this.$emit('switch-view', view)
		},
	}
}
</script>

<style scoped lang="scss">
@keyframes bounce {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-10px);
	}

}

.modal {
	@apply hidden;

	&.is-active {
		@apply block ;
		.modal-background {
			@apply bg-black opacity-80 fixed inset-0;
		}
	}

	.modal-content {
		@apply fixed inset-0;
	}
}


.tooltip {
	@apply hidden;
	&.is-displayed {
		@apply block;
	}
}

#task_change_notif {
	animation: bounce 0.8s infinite alternate;
}


.todo-table {
	td {
		padding: 5px;
	}
}

</style>
