export default class Sickness {
    constructor(gameData) {
        this.gameData = gameData
    }

    events() {
        return [
            {
                id: "start",
                speaker: 'Shivers',
                text: [
                    "Something's wrong all of a sudden. You're nauseous.",
                    "You want to vomit.",
                ],
                continue_to: 'brenda_observes'
            },
            {
                id: 'brenda_observes',
                speaker: 'Brenda',
                text: [
                    "\"Nathan, you don't look alright.\"",
                ],
                choices: [
                    {
                        id: 'restroom',
                        name: "Go to the restroom"
                    }
                ]
            },
            {
                id: 'restroom',
                speaker: 'The Restroom',
                text: [
                    "You find an unoccupied stall and start throwing up.",
                    "Once you are done throwing up, momentarily, you feel a sense of relief.",
                ],
                continue_to: 'headache',
            },
            {
                id: 'headache',
                speaker: 'Shivers',
                text: [
                    "But it doesn't last long. A throbbing headache starts.",
                    "Your body feels tired. Nothing would feel better than just to lie down and sleep",
                ],
                choices: [
                    {
                        id: 'go_home_and_rest',
                        name: 'Call it day. Go home and rest.',
                    },
                ],
            },
            {
                id: 'go_home_and_rest',
                speaker: 'The Restroom',
                text: [
                    "You call a Lyft to take you home.",
                    "While on the way, you message your team that you had to leave home early because you suddenly felt sick."
                ],
                advance_time: 20,
                continue_to: 'set_scene:AmandaDream',
            }
        ]
    }


    event(id) {
        return this.events().filter((ev) => {
            return ev['id'] === id
        })[0]
    }

}
