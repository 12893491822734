import { createStore } from 'vuex'
import goalData from '@/scripts/goals'
import itemData from '@/scripts/items'
import loadScript from '@/scripts/load_script'

const dayjs = require('dayjs')
dayjs.extend(require('dayjs/plugin/duration'))

export const originalState = () => {
	return {
		pcName: 'Nathan Spade',
		pcImageFile: 'people/nathan.jpg',
		moneyOnHand: 500,
		currentDateTime: '2030-04-18 18:00',
		xp: 0,
		currentLocationName: 'Home',
		currentSceneName: null,
		experiencedScripts: [],
		gameAttrs: {
			gender: 'male',
			nameDescription: 'You are Nathan Spade.',
			workDescription: 'You work as a Senior Designer at Morse+Slattery, a Digital Marketing consultancy',
			headDescription: 'You have a somewhat attractive face and short hair.',
			bodyDescription: 'On your lean body, you have a flat chest, narrow hips, and small unremarkable rear. Your body is lightly muscled, thanks to your time at the gym following your breakup.',
			clothing: 'jeans_and_tshirt',
			change_score: 0,
		},
		pendingGoalNames: [],
		completedGoalNames: [],
		skills: {
			physical: {
				startingBase: 0,
				learningCap: 0,
				learned: {
					endurance: 0,
					shivers: 0,
					perception: 0,
					poise: 0
				},
			},
			mental: {
				startingBase: 0,
				learningCap: 0,
				learned: {
					memory: 0,
					willpower: 0,
					analysis: 0,
					deviance: 0
				},
			},
			social: {
				startingBase: 0,
				learningCap: 0,
				learned: {
					persuasion: 0,
					empathy: 0,
					assertiveness: 0,
					allure: 0
				},
			},
		},
		hasUnreadGoals: false,
		sceneEvents: [],
		ownedClothingIds: [
			'mens_blue_dress_shirt',
			'mens_dark_blue_pants',
			'mens_dress_shoes',
			'short_sleeved_jacket',
			'white_tee',
		],
		equippedClothingIds: [
			'mens_blue_dress_shirt',
			'mens_dark_blue_pants',
			'mens_dress_shoes',
		],
	}
}

export default createStore({
	state: originalState(),
	getters: {
		pcName: state => state.pcName,
		pcImageFile: state => state.pcImageFile,
		moneyOnHand: state => state.moneyOnHand,
		sceneEvents: state => state.sceneEvents,
		currentSceneName: state => state.currentSceneName,
		gameAttrs: state => state.gameAttrs,
		hasUnreadGoals: state => state.hasUnreadGoals,
		experiencedScripts: state => state.experiencedScripts,
		skills: state => state.skills,
		equippedClothingIds: state => state.equippedClothingIds,
		ownedClothingIds: state => state.ownedClothingIds,
		pendingGoalNames: state => state.pendingGoalNames,
		completedGoalNames: state => state.completedGoalNames,
		nielsName: state => state.gameAttrs.nielsName,

		// related to Esmeralda
		metEsmeralda: state => {
			return state.experiencedScripts.includes('Esmeralda.shes_hot')
		},
		esmeraldaAngryMeeting: state => {
			return state.experiencedScripts.includes('Esmeralda.dramatic')
		},
		jerkedOffToEsmeralda: state => {
			return state.experiencedScripts.includes('FirstNightSleep.esmeralda-post-orgasm-1')
		},

		// TF related
		colbyKnowsOfTF: state => {
			return state.experiencedScripts.includes('ColbyHomeAwakening.start') || state.experiencedScripts.includes('Colby.tell-about-change')
		},
		toldRogerAboutTF: state => {
			return state.experiencedScripts.includes('RogerHomeMeeting.wave-at-him')
		},
		seenDoctor: state => {
			return false
		},
		postTFsaviour: state => {
			if (state.experiencedScripts.includes('ColbyHomeAwakening.start')) {
				return 'Colby'
			} else if (state.experiencedScripts.includes('EMTHomeAwakening.start')) {
				return 'the EMT'
			}
		},
		firstNightSlept: state => {
			return state.experiencedScripts.includes('FirstBodyChange.start')
		},
		autogynephelic: state => {
			return state.experiencedScripts.includes('FirstNightSleep.esmeralda-alt-scene-prologue')
		},
		jonahBrendaNotedChanges: state => {
			return state.experiencedScripts.includes('JonahAndBrenda.comment_on_changes')
		},
		peteNotedChanges: state => {
			return state.experiencedScripts.includes('PeteMcLaren.comment_on_changes')
		},

		//post tf
		textedRogerAboutMeeting: state => {
			return state.experiencedScripts.includes('FirstNightAsWomanSleep.type_it_out') || state.experiencedScripts.includes('Phone.text_roger')
		},
		processedTheHow: state => {
			return state.experiencedScripts.includes('Reorganizing.doesnt-make-sense-2')
		},
		processedTheWhy: state => {
			return state.experiencedScripts.includes('Reorganizing.why-me')
		},
		processedWhatsNext: state => {
			return state.experiencedScripts.includes('Reorganizing.tell-roger')
		},


		// Amanda Related
		amandasBoxSeen: state => {
			return state.experiencedScripts.includes('Wardrobe.inspect-box')
		},
		amandasBoxRippedOpen: state => {
			return state.experiencedScripts.includes('Wardrobe.open-box')
		},
		noteInAmandasBoxSeen: state => {
			return state.experiencedScripts.includes('Wardrobe.thebox')
		},
		// Milena Meeting
		spokenToRogerAboutMeeting: state => {
			return state.experiencedScripts.includes('Phone.got_it_roger')
		},
		receivedClientProfile: state => {
			return state.experiencedScripts.includes('Esmeralda.dramatic') || state.experiencedScripts.includes('Esmeralda.pay_her')
		},
		clientProfileIsUnread: state => {
			return !state.experiencedScripts.includes('Phone.client_profile_notification')
		},
		clientProfileReviewed: state => {
			return (state.experiencedScripts.includes('Computer.read_intro') &&
				state.experiencedScripts.includes('Computer.read_about_products') &&
				state.experiencedScripts.includes('Computer.read_about_founder') &&
				state.experiencedScripts.includes('Computer.read_about_requirements')) || state.experiencedScripts.includes('JonahAndBrenda.profile_walk_through')
		},
		goneThroughIdeas: state => {
			return state.experiencedScripts.includes('JonahAndBrenda.good_work')
		},
		rogerBackstoryConversationCompleted: state => {
			return state.experiencedScripts.includes('MilenaMeeting.yes') ||
				state.experiencedScripts.includes('MilenaMeeting.no')
		},
		milenaMeetingCompleted: state => {
			return state.experiencedScripts.includes('MilenaMeeting.me_too') ||
				state.experiencedScripts.includes('MilenaMeeting.milena_hmms')
		},
		milenaMeetingWentWell: state => {
			return state.experiencedScripts.includes('MilenaMeeting.me_too')
		},
		embarrassedToSellSexToys: state => {
			return state.experiencedScripts.includes('PeteMcLaren.its_embarrassing')
		},

		clothingEquipped: state => {
			return state.equippedClothingIds.map((itemId) => {
				return itemData(itemId)
			})
		},
		clothingOwned: state => {
			return state.ownedClothingIds.map((itemId) => {
				return itemData(itemId)
			})
		},
		currentDateTime (state, getters) {
			return dayjs(state.currentDateTime)
		},
		pendingGameGoals (state) {
			return state.pendingGoalNames.map((goalId) => {
				return goalData(goalId)
			})
		},
		completedGameGoals (state) {
			return state.completedGoalNames.map((goalId) => {
				return goalData(goalId)
			})
		},
		currentWeekday (state, getters) {
			return getters.currentDateTime.format('ddd')
		},
		currentTime (state, getters) {
			return getters.currentDateTime.format('hh:mm A')
		},
		currentLocation (state, getters) {
			return loadScript(state.currentLocationName, getters)
		},
		currentScene (state, getters) {
			if (state.currentSceneName) {
				return loadScript(state.currentSceneName, getters)
			}
		},
		latestSceneEvent (state) {
			return state.sceneEvents.filter((ev) => ev['speaker'] !== 'You').slice(-1)[0]
		}
	},
	mutations: {
		loadSave (state, gameData) {
			for (const property in gameData) {
				state[property] = gameData[property]
			}
		},
		navigateTo (state, locationName) {
			state.currentLocationName = locationName
		},
		clearScene (state) {
			state.currentSceneName = null
			state.sceneEvents = []
		},
		setScene (state, sceneName) {
			state.currentSceneName = sceneName
			state.sceneEvents = []
		},
		recordEvent (state, event) {
			state.sceneEvents.push(event)
		},
		setGameData (state, gameData) {
			for (const property in gameData) {
				state.gameAttrs[property] = gameData[property]
			}
		},
		setGameGoal (state, goalName) {
			if (!state.completedGoalNames.includes(goalName)) {
				state.pendingGoalNames.unshift(goalName)
				state.hasUnreadGoals = true
			}
		},
		completeGameGoal (state, goalName) {
			state.completedGoalNames.push(goalName)
			let index = state.pendingGoalNames.indexOf(goalName)
			if (index > -1) {
				state.pendingGoalNames.splice(index, 1)
			}
		},
		moneyOnHandChange (state, changeValue) {
			state.moneyOnHand = state.moneyOnHand + changeValue
		},
		xpGain (state, amount) {
			state.xp = state.xp + amount
		},
		updateImage (state, newImage) {
			state.pcImageFile = newImage
		},
		readGameGoals (state) {
			state.hasUnreadGoals = false
		},
		setSkillStartingBase (state, skillSet) {
			for (const skillType in skillSet) {
				state.skills[skillType]['startingBase'] = skillSet[skillType]
				state.skills[skillType]['learningCap'] = skillSet[skillType]
			}
		},
		setSignatureSkill (state, skillType, skillName) {
			state.skills[skillType][skillName]['learned']++
		},
		acquireClothing (state, itemId) {
			if (!state.ownedClothingIds.includes(itemId)) {
				state.ownedClothingIds.push(itemId)
			}
		},
		equipClothing (state, itemId) {
			state.equippedClothingIds.push(itemId)
		},
		unequipClothing (state, removedItemId) {
			state.equippedClothingIds = state.equippedClothingIds.filter(itemId => itemId !== removedItemId)
		},
		resetClothing (state, clothes) {
			state.equippedClothingIds = clothes.map(clothing => clothing['name'])
		},
		noteScript (state, scriptNode) {
			if (!state.experiencedScripts.includes(scriptNode)) {
				state.experiencedScripts.push(scriptNode)
			}
		}
	},
	actions: {
		initiateScene (ctx, sceneName) {
			ctx.commit('setScene', sceneName)
			ctx.commit('noteScript', `${sceneName}.start`)
			ctx.commit('recordEvent', ctx.getters.currentScene.event('start'))
		},
		advanceTime (ctx, minutes) {
			ctx.state.currentDateTime = ctx.getters.currentDateTime.add(dayjs.duration({ 'minutes': minutes })).toString()
		}
	},
	modules: {},

})
