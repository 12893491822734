export default class City {
	constructor (gameData) {
		this.gameData = gameData
		this.name = 'City'
		this.bgImage = 'places/town.png'
		this.description = 'You are outside your apartment. Select a place where you want to go'
	}

	actions () {
		return []
	}

	places () {
		let list = [
			{
				'name': 'Home',
				'location': 'Home',
				'icon': 'icons8-house-100.png',
				'condition': true
			},
			{
				'name': 'Colby\'s Apartment',
				'location': 'ColbysApartment',
				'icon': 'icons8-home-96.png',
				'condition': true
			},
			{
				'name': 'Roger\'s House',
				'location': 'RogersHouse',
				'icon': 'icons8-home-96.png',
				'condition': this.gameData.pendingGoalNames.includes('meet_roger_at_his_home')
			},
			{
				'name': 'Niels\' Apartment',
				'location': 'NielsApartment',
				'icon': 'icons8-home-96.png',
				'condition': this.gameData.experiencedScripts.includes('OldManJoe.goodluck')
			},
			{
				'name': 'Joe\'s Laundry',
				'location': 'JoesLaundry',
				'icon': 'icons8-washing-machine-96.png',
				'condition': this.gameData.pendingGoalNames.includes('get_identity_from_joes_laundry')
			},
			{
				'name': `Office`,
				'location': 'Office',
				'icon': 'icons8-company-100.png',
				'condition': this.withinTimeRange(7, 17) && (this.gameData.currentDateTime.day() > 0 && this.gameData.currentDateTime.day() < 6),
			},
			{
				'name': 'ATM',
				'location': 'ATM',
				'icon': 'icons8-atm-96.png',
				'condition': this.gameData.pendingGoalNames.includes('get_cash_from_atm')
			},
			{
				'name': 'The Gypsy\'s Tent',
				'location': 'GypsyTent',
				'icon': 'icons8-tent-100.png',
				'condition': !this.gameData.metEsmeralda
			},
			{
				'name': 'The Gap Store',
				'location': 'GapStore',
				'icon': 'gap-logo.png',
				'condition': false, // this.withinTimeRange(10, 20)
			},
			{
				'name': '24hr Convenience Store',
				'location': 'ConvenienceStore',
				'icon': '711.png',
				'condition': false
			},
			{
				'name': 'Fitness Zone',
				'location': 'FitnessZone',
				'icon': 'icons8-bench-press-96.png',
				'condition': this.withinTimeRange(5, 20)
			},
			{
				'name': 'Itsi Bitsi Bikinis',
				'location': 'StripClub',
				'icon': 'icons8-high-heels-116.png',
				'condition': false, // this.withinTimeRange(12, 23) || this.withinTimeRange(0, 3)
			},
		]
		list = list.filter((element) => {
			return element['condition']
		})
		return list
	}

	withinTimeRange (min, max) {
		let num = this.gameData.currentDateTime.hour()
		return (num >= min && num <= max)
	}

}
