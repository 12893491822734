export default function itemData (itemId) {
	const data = [
		{
			id: 'mens_blue_dress_shirt',
			type: 'Clothing',
			name: 'Blue Dress Shirt',
			da_name: 'MenDressShirt',
			da_attrs: {
				liningWidth: 2,
				sleeveLength: 1,
				topParted: 2,
				fill: 'hsla(198.9, 42.2%, 82.4%, 1.00)',
			},
			description: 'A simple, unfancy dress shirt. The working professional\'s default choice',
		},
		{
			id: 'mens_dark_blue_pants',
			type: 'Clothing',
			da_name: 'SuperPants',
			name: 'Dress Pants',
			da_attrs: {
				innerLoose: 0.15,
				legCoverage: 1,
				outerLoose: 0.5,
			},
			description: 'Plain, dark blue, dress pants.',
		},
		{
			id: 'mens_dress_shoes',
			type: 'Clothing',
			name: 'Dress Shoes',
			da_name: 'FlatShoes',
			da_attrs: {},
			description: 'Black dress shoes',
		},
		{
			id: 'short_sleeved_jacket',
			type: 'Clothing',
			name: 'Short Sleeved Jacket',
			da_name: 'LooseJacket',
			da_attrs: {
				clothingLayer: 3,
				chestCoverage: 0.75,
				cling: 0.35,
				liningWidth: 5,
				sleeveLength: 1,
				stomachCoverage: 1,
				thickness: 0,
			},
			description: 'A short-sleeved jacket.',
		},
		{
			id: 'white_tee',
			type: 'Clothing',
			name: 'Plain White Tee',
			da_name: 'Tee',
			da_attrs: {
				armCoverage: 0.35,
				cleavageCoverage: 0.095,
				crosses: -10,
				curveCleavageX: 12,
				curveCleavageY: -6,
				fill: 'hsla(0.0,0.0%,90.2%,1.00)',
				stroke: 'hsla(0.0,0.0%,74.5%,1.00)',
			}
		}
	]

	return data.filter((item) => {
		return item['id'] === itemId
	})[0]
}
