<template>

	<div class=" mt-20 ml-8">
		<div class="text-sm text-gray-800 mb-2">Skill points available</div>
		<div class="skill-dots h-3">
			<div class="skill-dot skilled" v-for="item in unallocatedPoints"></div>
		</div>
	</div>
	<table class="attr-selection text-lg lg:text-xl ml-8 mt-4">
		<tr>
			<td><span :class="{'attr-control': true, 'is-disabled': skillSet.physical === 1}"
								v-on:click="decrementAttr('physical')"><i class="fas fa-chevron-left"></i></span>
			</td>
			<td class="attr-value">
				{{ skillSet.physical }}
			</td>
			<td><span :class="{'attr-control': true, 'is-disabled': skillSet.physical === 6 || areSkillsMaxed}"
								v-on:click="incrementAttr('physical')"><i class="fas fa-chevron-right"></i></span>
			</td>
			<td class="">
				<div class="font-display uppercase text-lg">Physical</div>
				<div class="font-serif">Ability to work, perceive, and feel</div>
			</td>
		</tr>
		<tr>
			<td><span :class="{'attr-control': true, 'is-disabled': skillSet.mental === 1}"
								v-on:click="decrementAttr('mental')"><i class="fas fa-chevron-left"></i></span>
			</td>
			<td class="attr-value">
				{{ skillSet.mental }}
			</td>
			<td><span :class="{'attr-control': true, 'is-disabled': skillSet.mental === 6 || areSkillsMaxed}"
								v-on:click="incrementAttr('mental')"><i class="fas fa-chevron-right"></i></span>
			</td>
			<td class="">
				<div class="font-display uppercase text-lg">Physical</div>
				<div class="font-serif">Analyze, remember, remain composed</div>
			</td>
		</tr>
		<tr>
			<td><span :class="{'attr-control': true, 'is-disabled': skillSet.social === 1}"
								v-on:click="decrementAttr('social')"><i class="fas fa-chevron-left"></i></span>
			</td>
			<td class="attr-value">
				{{ skillSet.social }}
			</td>
			<td><span :class="{'attr-control': true, 'is-disabled': skillSet.social === 6 || areSkillsMaxed}"
								v-on:click="incrementAttr('social')"><i class="fas fa-chevron-right"></i></span>
			</td>
			<td class="">
				<div class="font-display uppercase text-lg">Social</div>
				<div class="font-serif">Persuade, understand, and influence others</div>
			</td>
		</tr>
	</table>
</template>

<script>

export default {
	name: 'Footer',
	props: [],
	data () {
		return {
			skillSet: {
				physical: 1,
				mental: 1,
				social: 1
			}
		}
	},
	computed: {
		totalSkillPoints () {
			return this.skillSet.physical + this.skillSet.mental + this.skillSet.social
		},
		unallocatedPoints () {
			return 9 - this.totalSkillPoints
		},
		areSkillsMaxed () {
			return this.unallocatedPoints === 0
		}
	},
	methods: {
		incrementAttr (attr) {
			if (this.totalSkillPoints < 9 && this.skillSet[attr] < 6) {
				this.skillSet[attr]++
			}
			this.confirmSelection()
		},
		decrementAttr (attr) {
			if (this.skillSet[attr] > 1) {
				this.skillSet[attr]--
			}
			this.confirmSelection()
		},
		confirmSelection () {
			this.$store.commit('setSkillStartingBase', this.skillSet)
		},
	}
}
</script>

<style scoped lang="scss">

.attr-selection {
	@apply font-display;

	.desc {
		@apply font-serif;
	}

	.attr-title {
		@apply uppercase;
	}

	.attr-value {
		text-align: center;
	}

	td {
		vertical-align: middle;
		padding: 10px;
	}


}

.attr-control {
	cursor: pointer;

	&.is-disabled {
		color: #bbb;
	}
}

.skill-dots {
	display: flex;
}

.skill-dot {
	height: 12px;
	width: 12px;
	border: 2px solid #666;
	border-radius: 3px;
	margin-right: 4px;

	&.skilled {
		background-color: #666;
	}
}

</style>
