export default class ATM {
	constructor (gameData) {
		this.gameData = gameData
		this.name = 'ATM'
		this.bgImage = 'places/atm.jpg'
		this.description = 'You are at your bank\'s ATM.'
	}

	actions () {
		let actions = [
			{
				name: 'Withdraw Money to Pay for identity',
				scene: 'CashWithdrawal',
				icon: 'icons8-atm-96.png',
				condition: true,
			},
		]

		return actions.filter((element) => {
			return element['condition']
		})

	}

}
