export default class OldManJoe {
	constructor (gameData) {
		this.gameData = gameData
	}

	events () {
		return [
			{
				id: 'start',
				speaker: 'Old Man Joe',
				text: [
					'You go to the old man in the cash counter',
					this.gameData.experiencedScripts.includes('OldManJoe.mike-volk') ? '"Hello again. Do you have the cash?", he says.' : '"Hello Miss. How can I help you?", he greets you.'
				],
				choices: [

					...(this.gameData.experiencedScripts.includes('OldManJoe.mike-volk') ? [] : [{
						id: 'mike-volk',
						name: 'I am looking for Mike Volkanovski',
					}]),

					...(this.gameData.experiencedScripts.includes('Colby.colby-loan') && this.gameData.experiencedScripts.includes('CashWithdrawal.enter_amount_3k') ? [{
						id: '8-grand',
						name: 'I have 9 thousand here. Can I at least pay the rest later?',
					}] : []),

					...(this.gameData.experiencedScripts.includes('Colby.colby-loan') && this.gameData.experiencedScripts.includes('CashWithdrawal.enter_amount_3k') && this.gameData.experiencedScripts.includes('RogerHomeMeeting.borrow-money') ? [{
						id: 'heres-the-money',
						name: 'Here\'s the money',
					}] : []),

					{
						id: 'leave',
						name: 'Leave',
					}

				]
			},
			{
				id: 'leave',
				speaker: 'Old Man Joe',
				text: ['You leave'],
				continue_to: 'the_end'
			},
			{
				id: 'mike-volk',
				speaker: 'Old Man Joe',
				text: ['"Sure. Let me take you to him", he points to an office room beckoning you to follow'],
				choices: [{
					id: 'office',
					name: 'Go into the office',
				}]
			},
			{
				id: 'office',
				speaker: 'The Office',
				text: ['"Have you brought the money?", he asks shifting his friendly demeanor into a neutral, business-like one.'],
				choices: [{
					id: 'no-money',
					name: 'Well, no. I am little short on money.',
				}]
			},
			{
				id: 'no-money',
				speaker: 'The Office',
				text: ['"No money, no identity", he says sternly.'],
				continue_to: 'assertiveness',
			},
			{
				id: 'assertiveness',
				speaker: 'Assertiveness',
				text: ['Bargain with him. Tell him you will pay back twice the amount at the end of the month.'],
				choices: [{
					id: 'pay-twice',
					name: 'I promise you I can pay double by the end of the month.',
				}]
			},
			{
				id: 'pay-twice',
				speaker: 'Old Man Joe',
				text: ['"I am not in the business of giving credit. Please come back when you have the cash.", he says pointing to the door.'],
				continue_to: 'the_end',
			},

			{
				id: '8-grand',
				speaker: 'Old Man Joe',
				text: ['"No credit.", I already told you.'],
				choices: [
					{
						id: 'anything-else',
						name: 'Is there anything I can do? I need this.',
					},
				]
			},

			{
				id: 'anything-else',
				speaker: 'Old Man Joe',
				text: ['"Actually... there is. I can refer you to a friend of mine who is looking for a model. A good looking female. Like you.", he says.'],
				choices: [
					{
						id: 'a-model',
						name: 'A model?',
					},
				]
			},

			{
				id: 'a-model',
				speaker: 'Old Man Joe',
				text: ['"Yes. The kind that poses for pictures", he hands you a card.'],
				continue_to: 'analysis'
			},

			{
				id: 'analysis',
				speaker: 'Analysis',
				text: ['He won\'t budge. You have to look at other options to raise some money. Might as well understand what the job is.'],
				choices: [
					{
						id: 'accept-card',
						name: 'Accept the card'
					}
				]
			},

			{
				id: 'accept-card',
				speaker: 'The Card',
				text: ['The card has the name "Niels" and an address to an apartment in one of the rich neighborhoods on Palmgrove'],
				set_game_goal: 'visit_the_photographer',
				continue_to: 'goodluck'
			},
			{
				id: 'goodluck',
				speaker: 'Old Man Joe',
				text: ['"Good luck with the gig", he says'],
				continue_to: 'the_end'
			},

			{
				id: 'heres-the-money',
				speaker: 'Old Man Joe',
				text: ['Very nice. Get into that room there. They will take the necessary photographs and give you a passport, social security card, and a driver\'s licence'],
				choices: [
					{
						id: 'go-inside-photo',
						name: 'Go inside'
					}
				]
			},
			{
				id: 'go-inside-photo',
				speaker: 'Perception',
				text: ['You pose for a few photographs and wait outside'],
				continue_to: 'docs'
			},
			{
				id: 'docs',
				speaker: 'Old Man Joe',
				text: ['"Here\'s is your new passport and social security card, Ms. Sylvie Allen", says Joe with a smile on his face'],
				continue_to: 'sylvie',
			},
			{
				id: 'sylvie',
				speaker: 'Perception',
				text: ['Wow! Your name is Sylvie now. That last bit that was Nathan is now dead.'],
				continue_to: 'deviance',
			},
			{
				id: 'deviance',
				speaker: 'Deviance',
				text: ['Long live Sylvie Allen'],
				continue_to: 'willpower',
			},

			{
				id: 'willpower',
				speaker: 'Willpower',
				text: ['What? No. We need to get back to being Nathan. This is just a stop-gap arrangement'],
				continue_to: 'the_end',
			},
		]
	}

	event (id) {
		return this.events().filter((ev) => {
			return ev['id'] === id
		})[0]
	}

}
