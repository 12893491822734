export default class Computer {
    constructor (gameData) {
        this.gameData = gameData
    }

    events () {
        return [
            this.startNode(),
            this.startNode('restart'),
            {
                id: 'check_instagram',
                speaker: 'Laptop',
                text: [
                    'Your Instagram feed is filled with interesting lives of your friends and the celebrities you follow',
                    'Exotic locations, beautiful food, adorable pets. They seem to have it all',
                ],
                choices: [
                    {
                        id: 'restart',
                        name: 'Close Instagram',
                    },
                    {
                        id: 'close_laptop',
                        name: 'Close the laptop',
                    },
                ],

            },
            {
                id: 'review_client_profile',
                speaker: 'Laptop',
                text: ['You open the PDF document titled, "Sensual Explorations", attached in Roger\'s email'],
                choices: this.reviewChoices(),
            },

            {
                id: 'read_intro',
                speaker: 'Laptop',
                text: ['Sensual Explorations LLC is an innovative up and coming intimate lifestyle company',
                    'What we do is look for unexplored spaces and chances to improve on the current landscape of sensuality'],
                continue_to:  'sounds-interesting',
            },

            {
                id: 'sounds-interesting',
                speaker: 'Deviance',
                text: ['Sounds interesting!'],
                choices: this.reviewChoices(),
            },

            {
                id: 'read_about_products',
                speaker: 'Laptop',
                text: [
                    'We have two major products - Aphrodite and Hermes.',
                    'Aphrodite is a remote controlled vibrator-cum-massager. One arm vibrates powerfully inside you and the other massages your clitoris. It can be used solo or along with a partner.',
                    'Hermes is a remote controlled prostate massager. It is designed to give the most explosive climaxes - hands-free.',
                    'Both of our products have tested very well in terms of user satisfaction in early customer reviews'],
                image_path: 'qHQ7310.png',
                continue_to: 'aesthetics_remarks_on_products',
            },
            {
                id: 'aesthetics_remarks_on_products',
                speaker: 'Empathy',
                text: [
                    'They seem to be thoughtfully designed and visually pleasing products',
                    'Whoever designed it has done a really good job'
                ],
                continue_to: 'carnality_remarks_on_products',
            },
            {
                id: 'carnality_remarks_on_products',
                speaker: 'Deviance',
                text: ['Did they say, "touchless explosive climaxes"?. I wonder what that feels like.'],
                choices: [
                    {
                        id: 'no_thanks',
                        name: 'No thanks. I would like my anus to remain uninvaded',
                    },
                    {
                        id: 'its_not_gay',
                        name: 'It\'s not gay if it\'s just a toy, right?',
                    },
                ],
            },
            {
                id: 'no_thanks',
                speaker: 'Deviance',
                text: ['Well, you will run out of ideas sooner or later'],
                continue_to: 'review_client_profile',
            },
            {
                id: 'its_not_gay',
                speaker: 'Deviance',
                text: ['Of course, it isn\'t. You should try it if you get a chance. It\'s important to keep an open-mind'],
                continue_to: 'review_client_profile',
            },
            {
                id: 'read_about_founder',
                speaker: 'Laptop',
                text: [
                    'The company was founded 2 years ago by Milena and Milton Hayes',
                    'Milena and Milton were leading figures in the adult entertainment industry before founding Sensual Explorations',
                    '7 years ago, they founded "Sensual Studios", an Internet pornography production company.'
                ],
                continue_to: 'aesthetics_remarks_on_founders',

            },
            {
                id: 'aesthetics_remarks_on_founders',
                speaker: 'Deviance',
                text: ['Sensual Studios. You\'ve heard about them. They make HD quality porn with beautiful people in exotic locations. Their variety ranges from Hardcore to Passionate. All of them, top-notch. You love them.'],
                continue_to: 'review_client_profile',
            },
            {
                id: 'read_about_requirements',
                speaker: 'Laptop',
                complete_game_goal: 'review_client_profile',
                xp_gain: 10,
                set_game_data: { client_profile_reviewed: true },
                text: [
                    'Sensual Explorations needs a go-to-market strategy for both their products',
                    'We need your agency to come up with a compelling marketing plan to hit the market including what kind of customers to target, what kind of ads to place, what kind of social media influencers to target etc.',
                ],

                choices: [
                    {
                        id: 'review_client_profile',
                        name: 'Seems straightforward (Conclude)',
                    },
                ],
            },
            {
                id: 'begin_jerk_off',
                speaker: 'Laptop',

                text: [
                    'You fire up your computer to find some material that will help you satiate your arousal',
                    'What kind of porn do you want?'
                ],
                choices: [
                    {
                        id: 'hardcore',
                        name: 'Watch a hardcore scene'
                    },

                    {
                        id: 'passionate',
                        name: 'Watch a passionate sex scene'
                    },

                    {
                        id: 'interracial',
                        name: 'Watch an interracial scene'
                    },
                    {
                        id: 'close_laptop',
                        name: 'Close the laptop'
                    }
                ]
            },
            {
                id: 'climax',
                speaker: 'Laptop',
                text: ['You jerk off to porn'],
                continue_to: 'the_end',
                advance_time: 20
            },
            {
                id: 'close_laptop',
                speaker: 'Laptop',
                text: ['The laptop lid closes'],
                continue_to: 'the_end',
                advance_time: 0
            },

        ]
    }

    startNode (restart = false) {
        let choices = [
            {
                id: 'check_instagram',
                name: 'Check your Instagram',
            },
            {
                id: 'close_laptop',
                name: 'Close the laptop',
            },
        ]
        if (this.gameData.receivedClientProfile) {
            choices.unshift({
                id: 'review_client_profile',
                name: 'Review the Client Profile emailed to you'
            })
        }

        return {
            id: restart ? restart : 'start',
            text: [restart ? 'What would you like to do now?' : 'You fire up your Laptop'],
            speaker: 'Laptop',
            choices: choices,
        }
    }

    reviewChoices () {
        return [
            {
                id: 'read_intro',
                name: 'Read the introduction',
            },
            {
                id: 'read_about_products',
                name: 'Read about their products',
            },
            {
                id: 'read_about_founder',
                name: 'Read about the founders',
            },
            {
                id: 'read_about_requirements',
                name: 'Read about their requirements',
            },
            {
                id: 'restart',
                name: 'Close the document',
            },
        ]
    }

    event (id) {
        return this.events().filter((ev) => {
            return ev['id'] === id
        })[0]
    }

}
