export default class ColbyIdentityTalk {
	constructor (gameData) {
		this.gameData = gameData
	}

	events () {
		return [
			{
				id: 'start',
				speaker: 'Colby',
				text: ['Hey Nathan. How did go with the boss?'],
				choices: [
					{
						id: 'job',
						name: ' He said he would give me a job. But...'
					}
				]
			},
			{
				id: 'job',
				speaker: 'Colby',
				text: ['That\'s great. What\'s the problem?'],
				choices: [
					{
						id: 'job',
						name: 'I need a name, Social Security number, and the whole package to work there and get paid'
					}
				]
			},
			{
				id: 'job',
				speaker: 'Colby',
				text: [
					'"That won\'t be a problem. I helped Maria and Jose get their American identities."',
					'"I know just the guy.", he whispers'
				],
				continue_to: 'joe'
			},
			{
				id: 'joe',
				speaker: 'Colby',
				text: [
					'You need to go to Joe\'s Laundry next on the 39th street and ask for Mike Volkanovski',
				],
				choices: [
					{
						id: 'and',
						name: 'And?'
					}
				]
			},
			{
				id: 'and',
				speaker: 'Colby',
				text: [
					'You will be given an identity in the evening or so. It\'s really that quick',
				],
				choices: [
					{
						id: 'simple',
						name: 'Sounds simple.'
					}
				]
			},
			{
				id: 'and',
				speaker: 'Colby',
				set_game_goal: 'visit_joes_aundry',
				text: [
					'You will be given an identity in the evening or so. It\'s really that quick',
				],
				choices: [
					{
						id: 'simple',
						name: 'Sounds simple. What\'s it gonna cost?'
					}
				]
			},
			{
				id: 'simple',
				speaker: 'Colby',
				text: [
					'Not more than 5 grand. But they only accept cash.',
				],
				choices: [
					{
						id: 'inexpensive',
						name: 'It\'s not very expensive. I should get it from my account.'
					}
				]
			},
			{
				id: 'inexpensive',
				speaker: 'Colby',
				text: [
					'That\'s what I thought too. I hear it\'s even cheaper on the dark web which is where they get it from',
				],
				choices: [
					{
						id: 'see-them',
						name: ' I\'ll go see them then.'
					}
				]
			},
			{
				id: 'see-tham',
				speaker: 'Colby',
				text: [
					'You do that Nathan',
				],
				continue_to: 'the_end'
			},

		]
	}

	event (id) {
		return this.events().filter((ev) => {
			return ev['id'] === id
		})[0]
	}

}
