export default class GypsyTent {
  constructor(gameData) {
    this.gameData = gameData
    this.name = 'Gypsy\'s Tent'
    this.bgImage = 'places/gypsy_tent.png'
    this.description = "This is the traveling Gypsy's tent. There's always a big queue of customers looking for shortcuts to their life's problems"
  }

  actions() {
    return [
      {
        name: 'Talk to Esmeralda',
        scene: 'Esmeralda',
        icon: 'icons8-communicate-96.png'
      }
    ]
  }
}
