export default class EMTHomeAwakening {
    constructor(gameData) {
        this.gameData = gameData
    }

    events() {
        return [
            {
                id: 'start',
                speaker: 'EMT',
                text: ["Ma'am. How are you feeling?"],
                choices: [
                    {
                        id: 'what_happened',
                        name: 'What happened?',
                    },
                ],
            },
            {
                id: 'what_happened',
                speaker: 'Perception',
                text: ["That's not who you remember your voice. Your voice has changed. You sound like a girl now."],
                choices: [
                    {
                        id: 'sound_like_girl',
                        name: 'And why do I sound like a girl?',
                    },
                ],
            },
            {
                id: 'sound_like_girl',
                speaker: 'EMT',
                text: ["You called 911 thinking you were dying. Given that you are young woman, I would say, you sounding like you do seems just about right."],
                choices: [
                    {
                        id: 'where_am_i',
                        name: 'Where am I?',
                    },
                ],
            },
            {
                id: 'where_am_i',
                speaker: 'EMT',
                text: ["You are at your apartment, in your bed. You seem to be fine. There's nothing wrong with you. I would recommend you see a doctor though. You probably had a panic attack."],
                continue_to: 'diplomacy',
            },
            {
                id: 'diplomacy',
                speaker: 'Empathy',
                text: ["Thank the gentleman and get rid of him. It's not the best idea to tell him you were a man. He might assume you are high."],
                choices: [
                    {
                        id: 'thanks',
                        name: "Thank you sir.",
                    },
                ],
            },
            {
                id: 'thanks',
                speaker: 'EMT',
                advance_time: 30,
                text: ["The EMT packs up his kit and leaves."],
                continue_to: 'set_scene:AllWomanNow'
            }
        ]
    }


    event(id) {
        return this.events().filter((ev) => {
            return ev['id'] === id
        })[0]
    }

}
