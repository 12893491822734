<template>
	<div class="page-title">YOUR SKILLS</div>
	<div class="columns is-gapless is-multiline">
		<template v-for="attr in attributes">
			<div class="column is-1" style="margin-top: 1rem;">
				<div class="attr-title">{{ attr.name }}</div>
				<div class="attr-score">{{ skills[attr.key].startingBase }}</div>
			</div>
			<div class="column is-8" style="margin-top: 1rem;">
				<div class="columns">
					<div class="column" v-for="skill in attr.skills">
						<div class="card skill-card">
							<div class="card-content">
								<div class="content">
									<h4 style="margin-bottom: 0;">{{ skill.name }}</h4>
									<div style="margin: 7px 0;">{{ skill.desc }}</div>
									<div class="skill-dots">
										<div class="skill-dot skilled"
												 v-for="index in (skills[attr.key].startingBase + skills[attr.key].learned[skill.key])"></div>
										<div class="skill-dot" v-for="index in (skills[attr.key].learningCap)"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="column is-3"></div>
		</template>
	</div>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'Footer',
	props: [],
	data () {
		return {
			attributes: [
				{
					name: 'Physical',
					key: 'physical',
					skills: [
						{
							name: 'Endurance',
							key: 'endurance',
							desc: 'Work hard'
						},
						{
							name: 'Shivers',
							key: 'shivers',
							desc: 'Nervousness and fear'
						},
						{
							name: 'Perception',
							key: 'perception',
							desc: 'Perceive the world'
						},
						{
							name: 'Poise',
							key: 'poise',
							desc: 'Carry yourself'
						}
					]
				},
				{
					name: 'Mental',
					key: 'mental',
					skills: [
						{
							name: 'Memory',
							key: 'memory',
							desc: 'Factual memory'
						},
						{
							name: 'Willpower',
							key: 'willpower',
							desc: 'Resist temptations'
						},
						{
							name: 'Analysis',
							key: 'analysis',
							desc: 'Analyse and conclude'
						},
						{
							name: 'Deviance',
							key: 'deviance',
							desc: 'Indulge without guilt'
						}
					]
				},
				{
					name: 'Social',
					key: 'social',
					skills: [
						{
							name: 'Persuasion',
							key: 'persuasion',
							desc: 'Persuade and influence'
						},
						{
							name: 'Empathy',
							key: 'empathy',
							desc: 'Understand motivations'
						},
						{
							name: 'Assertiveness',
							key: 'assertiveness',
							desc: 'Hold your ground'
						},
						{
							name: 'Allure',
							key: 'allure',
							desc: 'Attract people'
						}
					]
				}
			]
		}
	},
	computed: {
		...mapGetters(
			['skills']
		),
	},
	methods: {}
}
</script>

<style scoped lang="scss">
.attr-title {
	font-family: 'Squada One', sans-serif;
	text-transform: uppercase;
	font-size: 1.6rem;
}

.attr-score {
	font-family: 'Squada One', sans-serif;
	font-size: 2.2rem;
}

.skill-card {
	width: 210px;
}
</style>
