export default class RogerHomeMeeting {
	constructor (gameData) {
		this.gameData = gameData
	}

	events () {
		return [
			{
				id: 'start',
				speaker: 'Roger\'s Home',
				text: ['You ring Roger\'s doorbell'],
				continue_to: this.gameData.toldRogerAboutTF ? 'roger-lets-you-in' : 'wave-at-him',
			},
			{
				id: 'roger-lets-you-in',
				speaker: 'Roger',
				text: [
					'"Come on in Nathan. What\'s up?"',
				],
				choices: [
					{
						id: 'nothing',
						name: 'Nothing. I was just leaving.'
					},
				]
			},

			{
				id: 'nothing',
				speaker: 'Roger',
				text: [
					'"Okay! I\'ll see you around"',
				],
				continue_to: 'the_end'
			},

			{
				id: 'wave-at-him',
				speaker: 'Roger',
				text: [
					'A few moment\'s later, Roger opens the door and asks, "Do I know you?"'
				],
				choices: [
					{
						id: 'yes',
						name: 'Actually yes. I am Nathan.'
					}
				]
			},
			{
				id: 'yes',
				speaker: 'Roger',
				text: [
					'What?'
				],
				choices: [
					{
						id: 'long-story',
						name: 'It\'s a long story and most importantly I want you to not freak out.'
					}
				]
			},
			{
				id: 'long-story',
				speaker: 'Roger',
				text: [
					'"What story? Where\'s Nathan? Who are you?"',
					'He pulls out his phone while still maintaining eye contact. He is trying to call you.'
				],
				choices: [
					{
						id: 'go-ahead',
						name: 'I am Nathan. I fell sick and changed into a woman'
					},
					{
						id: 'go-ahead',
						name: 'Go ahead and call him.'
					}
				]
			},
			{
				id: 'go-ahead',
				speaker: 'Roger',
				text: [
					'Your phone rings',
					'"So you have his phone. Is this some kind of prank. I can tell you I am not enjoying it.", he says',
				],
				choices: [
					{
						id: 'nathan-no-prank',
						name: 'Will the Nathan you know pull pranks like this?'
					},
					{
						id: 'not-prank',
						name: 'It\'s not a prank. It\'s real. Let me explain.'
					}
				]
			},
			{
				id: 'nathan-no-prank',
				speaker: 'Roger',
				text: [
					'May be he will. I can\'t tell what he will or will not do anymore.',
				],
				choices: [
					{
						id: 'not-prank',
						name: 'It\'s not a prank. It\'s real. Let me explain.'
					}
				]
			},
			{
				id: 'not-prank',
				speaker: 'Roger',
				text: [
					'"Ok, let\'s hear the story"',
				],
				choices: [{
					id: 'face-change',
					name: 'So it started on the day of the meeting. I had lost some weight overnight and my hair grew a bit...'
				}]
			},
			{
				id: 'face-change',
				speaker: 'perception',
				text: [
					'Roger\'s face immediately drops the defensive skeptical look. He recalls your how you looked at the meeting. He didn\'t mention it then. But he remembers it now.'
				],
				choices: [{
					id: 'nausea',
					name: 'And then I felt nauseous and got sick, so I went home to rest\n'
				}]
			},
			{
				id: 'nausea',
				speaker: 'Roger',
				text: [
					'Go on'
				],
				choices: [{
					id: 'unbelievable',
					name: `I passed out before I could call for help and by the time ${this.gameData.postTFsaviour} could come I had already changed`,
				}]
			},
			{
				id: 'unbelievable',
				speaker: 'Roger',
				text: [
					'It\'s unbeleivable'
				],
				choices: [
					{
						id: 'its-real',
						name: 'But it\'s real'
					},
				]
			},
			{
				id: 'its-real',
				speaker: 'Roger',
				text: [
					'I don\'t know what to say',
				],
				choices: [
					{
						id: 'need-proof',
						name: 'How can I prove that I am really Nathan?'
					},
					{
						id: 'say-nothing',
						name: '(Say nothing)'
					}
				]
			},
			{
				id: 'say-nothing',
				speaker: 'Roger',
				text: [
					'"I\'ll need more convincing. Let me think of something that only you and I know..."',
				],
				continue_to: 'memory-on-identity'
			},
			{
				id: 'need-proof',
				speaker: 'Roger',
				text: [
					'"Good that you asked. Let me think of something that only you and I know..."',
				],
				continue_to: 'memory-on-identity'
			},
			{
				id: 'memory-on-identity',
				speaker: 'Memory',
				text: [
					'Good. It\'s time prove your identity'
				],
				continue_to: 'first-project'
			},
			{
				id: 'first-project',
				speaker: 'Roger',
				text: [
					'What was the first project that we worked together?'
				],
				continue_to: 'recall'
			},
			{
				id: 'recall',
				speaker: 'Memory',
				text: [
					'That\'s easy. It was a project for the North American Paints Corporation. Tell him that.'
				],
				choices: [{
					id: 'napc',
					name: 'North American Paints Corporation'
				}]
			},
			{
				id: 'napc',
				speaker: 'Roger',
				text: [
					'Hmmm...'
				],
				continue_to: 'analysis-details'
			},

			{
				id: 'analysis-details',
				speaker: 'Analysis',
				text: [
					'This man is expecting details. Anyone who had your personnel file would know all the projects you worked for. Give him some details only he knows.'
				],
				continue_to: 'memory-details'
			},
			{
				id: 'memory-details',
				speaker: 'Memory',
				text: [
					'Of course. It was your first project ever in the company and it was with him. He drove you to the client\'s office and told the VP of Marketing that you were a very experienced and astute marketer and left you alone with him.'
				],
				continue_to: 'memory-details-2'
			},
			{
				id: 'memory-details-2',
				speaker: 'Memory',
				text: [
					'But you had no real experience. He basically put you in a tank full of sharks. But you survived. Tell him the story. Remind him how he abandoned little Nathan in a cruel pool of corporate sharks who constantly asked stupid questions in meetings to make their presence felt.'
				],
				choices: [{
					id: 'answer',
					name: 'You left me in their office and their entire marketing team bombarded me with thousands of questions. It\'s a miracle I survived.'
				}]
			},
			{
				id: 'answer',
				speaker: 'Roger',
				text: [
					'"Oh god. It\'s you. How is this even possible?"',
					'"Come inside the house", he motions you in.'
				],
				choices: [
					{
						id: 'how-sudden-change',
						name: 'I have no idea (Go inside)'
					},
					{
						id: 'how-sudden-change',
						name: 'Don\'t you think I want to know? (Go inside)'
					}
				]
			},
			{
				id: 'how-sudden-change',
				speaker: 'Roger',
				text: [
					'How does a man suddenly become a woman?'
				],
				choices: [
					{
						id: 'trust-me',
						name: 'Trust me I really want to find out.'
					},
				]
			},
			{
				id: 'trust-me',
				speaker: 'Roger',
				text: [
					'So what\'s next? What are you going to do?'
				],
				choices: [
					{
						id: 'continue-work',
						name: 'I was hoping to continue at Morse+Slattery.'
					},
				]
			},
			{
				id: 'continue-work',
				speaker: 'Roger',
				text: [
					'Just like that? You want to get back to your new life? It isn\'t everyday that a man magically becomes a woman. Don\'t you want to see a doctor or something? This sounds serious.'
				],
				choices: [
					{
						id: 'gonna-see-doctor',
						name: 'Yes. It\'s on my list of things to do. I want to get this checked out.'
					},
					...(this.gameData.seenDoctor ? [{
						id: 'gonna-see-doctor',
						name: 'I saw a doctor but she didn\'t believe me. She thought I was wasting her time and refused to see me'
					}] : [])
				]
			},
			{
				id: 'gonna-see-doctor',
				speaker: 'Roger',
				text: [
					'That\'s understandable. So how are you going to get back your body?'
				],
				choices: [
					{
						id: 'end_node',
						name: 'I have some leads. I am working on it.'
					},
				]
			},
			{
				id: 'end_node',
				speaker: 'Roger',
				text: [
					'"Ok. I\'ll see what I can do. Let me make some calls.", he says and leaves the room.',
				],
				continue_to: 'wait'
			},
			{
				id: 'wait',
				speaker: 'Roger\'s House',
				text: [
					'You wait in his living room until he come\'s back',
				],
				continue_to: 'comeback'
			},
			{
				id: 'comeback',
				speaker: 'Roger',
				text: [
					'I spoke to HR. We can get you onboard as a consultant. I figured we could tell you team that Nathan\'s gone to attend an emergency and you would be on retainer until he gets back',
				],
				choices: [{
					id: 'sounds_plan',
					name: 'Sounds like a plan'
				}]
			},
			{
				id: 'sounds_plan',
				speaker: 'Roger',
				text: [
					'"There are some more things. You will need a name, bank account, an identity, the whole package. So you will need to arrange those things for yourself."',
				],
				continue_to: 'analysis'
			},
			{
				id: 'analysis',
				speaker: 'Analysis',
				set_game_goal: 'get_a_new_identity',
				text: [
					'Makes sense. You are going to need all the documentation to take part in society.',
				],
				continue_to: 'memory'
			},
			{
				id: 'memory',
				speaker: 'Memory',
				text: [
					'Colby hires immigrant labor for his business. Possibly illegals. He must have some contacts.',
				],
				set_game_goal: 'talk_to_colby_about_new_identity',
				choices: [{
					id: 'got_it',
					name: 'Got it.'
				}]
			},
			{
				id: 'got_it',
				speaker: 'Roger',
				text: ['What else?'],
				advance_time: 180,
				choices: [{
					id: 'nothing',
					name: 'Nothing. I\'ll see you monday'
				}]
			},
			{
				id: 'nothing',
				speaker: 'Roger',
				text: ['Very well then'],
				continue_to: 'the_end'
			},

		]
	}

	event (id) {
		return this.events().filter((ev) => {
			return ev['id'] === id
		})[0]
	}

}
