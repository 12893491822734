export default class PeteMcLaren {
    constructor(gameData) {
        this.gameData = gameData
    }

    events() {
        return [
            {
                id: "start",
                speaker: 'Pete McLaren',
                text: ["You walk up to Pete, your rival in this company"],
                continue_to: this.gameData.peteNotedChanges ? 'whats_up' : 'comment_on_changes'
            },
            {
                id: 'comment_on_changes',
                speaker: 'Pete McLaren',
                advance_time: 5,
                text: [
                    "\"Look who's got a new hairstyle. Your androgynous look does suit you, given that you are going to selling dildos for the next few weeks.\""
                ],
                choices: this.changeCommentChoices(),
            },
            {
                id: 'what',
                speaker: 'Pete McLaren',
                text: [
                    "\"Your new client makes dildos, Nathan.\"",
                    "\"Oh god you haven't read the client profile. Have you?\"",
                ],
                choices: [
                    {
                        id: 'no_i_didnt',
                        name: '"No. I didn\'t get the time. I should catch up on it."',
                    },
                ],
            },
            {
                id: 'no_i_didnt',
                speaker: 'Pete McLaren',
                text: ["\"Your continuing descent into mediocrity never ceases to amaze me.\""],
                choices: [
                    {
                        id: 'gotta_go',
                        name: '"I gotta go now."',
                    },
                ],
            },
            {
                id: 'gotta_go',
                speaker: 'Pete McLaren',
                advance_time: 10,
                text: ['"Yes you should."'],
                continue_to: 'the_end',
            },
            {
                id: 'its_embarrassing',
                speaker: 'Pete McLaren',
                set_game_data: {'embarrassed_to_sell_sex_toys': true},
                text: ['"It\'s a sad day man. They could have assigned you better client. I wish you better luck with the next client"'],
                continue_to: 'sociability',
            },
            {
                id: 'product_is_a_product',
                speaker: 'Pete McLaren',
                text: ['"That may be. I still wonder how it will look on your profile. That you sold dildos. I wish you better luck with the next client"'],
                continue_to: 'sociability',
            },
            {
                id: 'sociability',
                speaker: "Assertiveness",
                text: ['"There\'s condescension in his tone. He doesn\'t mean it."'],
                continue_to: 'diplomacy',
            },
            {
                id: 'diplomacy',
                speaker: 'Empathy',
                text: ["\"Of course he doesn't mean it. He's after the top job too. You're in the way.\""],
                continue_to: 'whats_up',
            },

            {
                id: 'whats_up',
                speaker: 'Pete McLaren',
                text: [
                    "\"So what's up? What can I do for you?\""
                ],
                choices: [
                    {
                        id: "nothing_really",
                        name: '"Nothing really"'
                    }
                ]
            },
            {
                id: 'nothing_really',
                speaker: 'Pete McLaren',
                text: ['"Oh! Then why are you wasting my time"'],
                continue_to: 'the_end',
            }
        ]
    }

    changeCommentChoices() {
        return [
            {
                id: 'what',
                name: "\"Dildos?\"",
                condition: !this.gameData.clientProfileReviewed,
            },
            {
                id: 'product_is_a_product',
                name: "\"A product is a product. It could be anything. I know I can sell it.\"",
                condition: this.gameData.clientProfileReviewed,

            },
            {
                id: 'its_embarrassing',
                name: "\"You're right. I am embarrassed to be working for this client\"",
                condition: this.gameData.clientProfileReviewed,
            }
        ].filter((element) => {
            return element['condition'];
        })
    }


    event(id) {
        return this.events().filter((ev) => {
            return ev['id'] === id
        })[0]
    }

}
