export default class ColbyHomeAwakening {
    constructor(gameData) {
        this.gameData = gameData
    }

    events() {
        return [
            {
                id: 'start',
                speaker: 'Colby',
                text: ["Hey man. You feel alright? You won't believe what happened"],
                choices: [
                    {
                        id: 'what_happened',
                        name: 'What happened?',
                    },
                ],
            },
            {
                id: 'what_happened',
                speaker: 'Perception',
                text: ["That's not who you remember your voice. Your voice has changed. You sound like a girl now."],
                choices: [
                    {
                        id: 'sound_like_girl',
                        name: 'And why do I sound like a girl?',
                    },
                ],
            },
            {
                id: 'sound_like_girl',
                speaker: 'Colby',
                text: ["\"It's not just the voice. You've become a woman now. You changed right before my eyes.\""],
                choices: [
                    {
                        id: 'what',
                        name: 'What?',
                    },
                ],
            },
            {
                id: 'what',
                speaker: 'Colby',
                text: [
                    "Yes. When I entered the room, you were in the nude and passed out. You looked different but I could tell it was you.",
                    "You were running a fever. I was going to call 911. But then the strangest thing happened",
                    "Your body... started changing. Right before my eyes, your boobs became larger, your hair grew longer, and your dick became a pussy. It's unbelievable",
                    "It doesn't make any sense."
                ],
                continue_to: 'comes_back',
            },
            {
                id: 'comes_back',
                speaker: 'Memory',
                text: ["It all comes back now. You have been changing since yesterday. However, the changes seem to have accelerated today finally making you a woman."],
                continue_to: 'how_possible',
            },
            {
                id: 'how_possible',
                speaker: 'Colby',
                text: ["How is this possible? What happened to you? I bet it's the Government putting something in your water."],
                choices: [
                    {
                        id: 'leave_me_alone',
                        name: 'Thank you for coming to save me. I think I need some time to process what happened.',
                    },
                ],
            },
            {
                id: 'leave_me_alone',
                speaker: 'Colby',
                text: ["Absolutely. I'll be in my apartment. Give me a call if you need me."],
                choices: [
                    {
                        id: 'thanks',
                        name: 'Thanks man',
                    },
                ],
            },
            {
                id: 'thanks',
                speaker: 'Colby',
                text: ["Colby leaves your apartment"],
                advance_time: 30,
                continue_to: 'set_scene:AllWomanNow'
            }

        ]
    }


    event(id) {
        return this.events().filter((ev) => {
            return ev['id'] === id
        })[0]
    }

}
