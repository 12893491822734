export default class Colby {
	constructor (gameData) {
		this.gameData = gameData
	}

	events () {
		return [
			{
				id: 'start',
				speaker: 'Colby',
				text: ['Hey Nathan, what\'s up?'],
				choices: this.startChoices()
			},
			{
				id: 'nothing',
				speaker: 'Colby',
				text: ['See you around then!'],
				continue_to: 'the_end'
			},

			{
				id: 'talk-about-esmeralda',
				speaker: 'Colby',
				text: ['Very good. I am excited to hear it. What did she say?'],
				choices: [
					this.gameData.esmeraldaAngryMeeting ? {
						id: 'cursed',
						name: 'She may have cursed me because I called out her scam'
					} : {
						id: 'bound',
						name: 'She bound my spirit to some successful business woman'
					}
				]
			},

			{
				id: 'cursed',
				speaker: 'Colby',
				text: [
					'You did what?'
				],
				choices: [
					{
						id: 'called-out',
						name: 'I called her out. She\'s a scammer.'
					},
				]
			},
			{
				id: 'called-out',
				speaker: 'Colby',
				text: [
					'No. No. You screwed up Nathan. I have heard stories.'
				],
				choices: [
					{
						id: 'what-stories',
						name: 'What stories?'
					},
				]
			},
			{
				id: 'what-stories',
				speaker: 'Colby',
				text: [
					'Bad things happen to people who get on her bad side.'
				],
				choices: [
					{
						id: 'what-bad-things',
						name: 'What bad things?'
					},
				]
			},
			{
				id: 'what-bad-things',
				speaker: 'Colby',
				text: [
					'Bad things Nathan.'
				],
				continue_to: 'analysis'
			},
			{
				id: 'analysis',
				speaker: 'Analysis',
				text: [
					'Just "bad things", no details? She sure is a good one. I will give you that.'
				],
				choices: [
					{
						id: 'whatever',
						name: 'Whatever! We will find out soon enough. (Conclude)'
					},
				]
			},
			{
				id: 'whatever',
				speaker: 'Colby',
				text: [
					'Be safe man. You messed with the wrong person.'
				],
				continue_to: 'the_end'
			},

			{
				id: 'bound',
				speaker: 'Colby',
				text: [
					'Oh. That\'s what she did to me too. She bound my soul to the best car salesman in the country.'
				],
				choices: [
					{
						id: 'car-sales',
						name: 'You believe that\'s why you have been selling a lot of cars?'
					},
				]
			},

			{
				id: 'car-sales',
				speaker: 'Colby',
				text: [
					'I do.'
				],
				continue_to: 'analysis2'
			},
			{
				id: 'analysis2',
				speaker: 'Analysis',
				text: [
					'No point arguing with this guy. Just let it go.'
				],
				choices: [
					{
						id: 'good',
						name: 'Hopefully, something good will happen to me too.'
					},
				]
			},
			{
				id: 'good',
				speaker: 'Colby',
				text: [
					'You wait and see my friend. You wait and see.'
				],
				continue_to: 'start'
			},
			{
				id: 'ask-about-business',
				speaker: 'Colby',
				text: [
					'Business is fine. I am working with this guy, Clifford. He is a genius mechanic. He customizes old cars with powerful engines, stylish interiors and there\'s a niche market for that kind of stuff.'
				],
				choices: [
					{
						id: 'nice-idea',
						name: 'Seems like a nice idea.'
					},
				]
			},
			{
				id: 'nice-idea',
				speaker: 'Colby',
				text: [
					'It\'s a killer idea'
				],
				continue_to: 'start'
			},
			{
				id: 'talk-about-change-he-knows',
				speaker: 'Colby',
				text: [
					'It feels weird to see you as a woman. How are you feeling?'
				],
				choices: [
					{
						id: 'feeling',
						name: 'I feel like shit. I don\'t like it'
					},
					{
						id: 'feeling',
						name: 'I feel good except that I am a woman'
					},
				]
			},
			{
				id: 'feeling',
				speaker: 'Colby',
				text: [
					'So what\'s it like having tits? Having a pussy, And no dick?'
				],
				choices: [
					{
						id: 'feeling2',
						name: 'It\'s strange',
					},
					{
						id: 'feeling2',
						name: 'It\'s hot'
					},
				]
			},
			{
				id: 'feeling2',
				speaker: 'Colby',
				text: [
					'I am sorry. It\'s too soon. What are you going to do next?'
				],
				choices: [
					{
						id: 'talk-to-boss',
						name: 'I am going to speak to my boss and see if I can continue at my old job',
					},
				]
			},
			{
				id: 'talk-to-boss',
				speaker: 'Colby',
				text: [
					'Okay. But what are you going to do about this girl body?'
				],
				choices: [
					{
						id: 'see-doctor',
						name: 'I think I\'ll see a Doctor.',
					},
				]
			},
			{
				id: 'see-doctor',
				speaker: 'Colby',
				text: [
					'Sounds like you got this all figured out'
				],
				complete_game_goal: 'tell_colby_about_change',
				continue_to: 'the_end',
			},
			{
				id: 'tell-about-change',
				speaker: 'Colby',
				text: [
					'Do I know you Ma\'am?',
					'Wait! Don\'t tell me.',
					'I am going to recollect your name.'
				],
				choices: [
					{
						id: 'nathan',
						name: 'I am Nathan'
					},
				]
			},
			{
				id: 'nathan',
				speaker: 'Colby',
				text: [
					'Holy shit! It\'s you.'
				],
				continue_to: 'analysis3'
			},
			{
				id: 'analysis3',
				speaker: 'Analysis',
				text: [
					'How did he know? Did he just believe you just like that?'
				],
				choices: [
					{
						id: 'proof',
						name: 'Did you just believe me without any proof?'
					},
				]
			},
			{
				id: 'proof',
				speaker: 'Colby',
				text: [
					'Come on Nathan. I have known you for years. Plus there\'s the resemblance too. You look like your girl version'
				],
				continue_to: 'perception'
			},
			{
				id: 'perception',
				speaker: 'Colby',
				text: [
					'He\'s right. You actually look like a little bit like the female version of you. May be a lot cuter.'
				],
				choices: [
					{
						id: 'you-can',
						name: 'You can?'
					}
				]
			},

			{
				id: 'you-can',
				speaker: 'Colby',
				text: [
					'Of course. What the hell happened to you?'
				],
				choices: [
					{
						id: 'tell-story-ignore-curse',
						name: 'I don\'t know. I started changing yesterday…'
					},
					// {
					// 	id: 'tell-story-curse',
					// 	name: ' I don\'t know. I started changing yesterday… (tell the story, mention the curse)'
					// },
				]
			},
			{
				id: 'tell-story-ignore-curse',
				speaker: 'Colby',
				text: [
					'That\'s unbelievable. What are you going to do next?'
				],
				choices: [
					{
						id: 'talk-to-boss',
						name: 'I am going to speak to my boss and see if I can continue at my old job',
					},
				]
			},
			{
				id: 'tell-story-curse',
				speaker: 'Colby',
				text: [
					'What? It\'s definitely her. We need to find her if you want to reverse this.',
					'That\'s unbelievable. What are you going to do next?'
				],
				choices: [
					{
						id: 'talk-to-boss',
						name: 'I am going to speak to my boss and see if I can continue at my old job',
					},
				]
			},

			{
				id: 'ask-about-identity',
				text: [
					'"I figured you would need it. You can\'t work get paid without an identity in this country."',
					'"But that won\'t be a problem. I helped Maria and Jose get their American identities. *whispers*. I know just the guy."',
					'"You need to go to Joe\'s Laundry next to the Liberty Avenue building and ask for Mike Volkanovski"'
				],
				speaker: 'Colby',
				choices: [
					{
						id: 'and',
						name: 'And?'
					}
				]
			},

			{
				id: 'and',
				text: [
					'"You will be given an identity in the evening or so. It\'s a quick process."',
				],
				speaker: 'Colby',
				set_game_goal: 'get_identity_from_joes_laundry',
				choices: [
					{
						id: 'simple',
						name: 'Sounds simple. What\'s it gonna cost?'
					}
				]
			},
			{
				id: 'simple',
				text: [
					'"Not more than 10 grand if I remember right. But they only accept cash."',
				],
				speaker: 'Colby',
				set_game_goal: 'get_cash_from_atm',
				choices: [
					{
						id: 'endnode',
						name: 'It\'s... not very expensive. I should have that money in the bank'
					}
				]
			},
			{
				id: 'endnode',
				text: [
					'"You got it man!"',
				],
				speaker: 'Colby',
				continue_to: 'the_end'
			},

			{
				id: 'ask-colby-for-a-loan',
				text: [
					'"Oh no. That\'s a real bummer. I just spent a lot of money on my car customization project with Clifford. So let me check how much I have on me.", he looks into his wallet',
				],
				speaker: 'Colby',
				continue_to: 'colby-loan',
			},
			{
				id: 'colby-loan',
				text: [
					'"Here\'s $6000. That\'s as much as I\'ve got"',
				],
				speaker: 'Colby',
				money_on_hand_change: 6000,
				complete_game_goal: 'borrow_money_from_colby',
				choices: [
					{
						id: 'short',
						name: 'I am still short by about a thousand'
					}
				]
			},
			{
				id: 'short',
				text: [
					'"It\'s just $1000. I know Joe to be kind man. Tell him you\'re my friend. He could accept a price reduction."',
				],
				speaker: 'Colby',
				choices: [
					{
						id: 'try',
						name: 'I\'ll try.'
					}
				]
			},
			{
				id: 'try',
				text: [
					'"You do that."',
				],
				speaker: 'Colby',
				continue_to: 'the_end'
			},
		]
	}

	startChoices () {

		if (this.gameData.gameAttrs.gender === 'female' && !this.gameData.colbyKnowsOfTF) {
			return [{
				id: 'tell-about-change',
				name: 'Hey Colby (Tell about your change)'
			}]
		}

		let baseChoices = [{
			id: 'nothing',
			name: 'Nothing. I am just leaving'
		}]

		if (this.gameData.metEsmeralda && !this.gameData.experiencedScripts.includes('Colby.talk-about-esmeralda')) {
			baseChoices.push({
				id: 'talk-about-esmeralda',
				name: 'I met Esmeralda'
			})
		}

		if (this.gameData.pendingGoalNames.includes('talk_to_colby_about_new_identity') && !this.gameData.experiencedScripts.includes('Colby.simple')) {
			baseChoices.push({
				id: 'ask-about-identity',
				name: 'I need a new identity.'
			})
		}
		if (this.gameData.colbyKnowsOfTF && !this.gameData.experiencedScripts.includes('Colby.talk-about-change-he-knows')) {
			baseChoices.push({
				id: 'talk-about-change-he-knows',
				name: 'Hey Colby (Talk about your change)'
			})
		}

		if (this.gameData.pendingGoalNames.includes('borrow_money_from_colby')) {
			baseChoices.push({
				id: 'ask-colby-for-a-loan',
				name: 'My bank has a withdrawal limit. Can I get a $7,000 loan?'
			})
		}

		return baseChoices
	}

	event (id) {
		return this.events().filter((ev) => {
			return ev['id'] === id
		})[0]
	}

}
