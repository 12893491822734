<template>
	<div class="grid sm:grid-cols-1 lg:grid-cols-4 mt-10 p-2 lg:p-10 gap-2 pb-20">
		<div v-for="arch in archetypes" class="shadow-md mx-2 border border-gray-50 bg-white mt-2">
			<div :class="{'card cursor-pointer grid grid-cols-5 lg:grid-cols-1': true, 'is-selected': archetype === arch.id }"
					 v-on:click="selectArchetype(arch.id)">
				<div :style="`background-image: url('${arch.image_path}')`"
						 class="col-span-2 h-full w-full lg:w-full lg:h-40 bg-cover bg-center"></div>
				<div class="col-span-3">
					<h4 class="font-ui text-2xl mt-2 px-2">{{ arch.title }}</h4>
					<p class="font-serif mb-2 p-2">{{ arch.description }}</p>
					<table class="w-full font-serif">
						<tr v-for="(val, skill) in skillSet(arch.id)" class="border-t  border-gray-200">
							<td class="uppercase p-2">{{ skill }}</td>
							<td class="text-right p-2">{{ val }}</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</div>

</template>
<script>

export default {
	name: 'Footer',
	props: [],
	data () {
		return {
			archetype: null
		}
	},
	computed: {
		archetypes () {
			return [
				{
					id: 'executive',
					image_path: require(`@/assets/images/archetypes/executive.jpeg`),
					title: 'Executive',
					description: 'The executive is good at getting things done but he is not everyone\'s favourite.',
				},
				{
					id: 'salesman',
					image_path: require(`@/assets/images/archetypes/salesman.jpeg`),
					title: 'Salesman',
					description: 'The salesman is good at persuasion and charm. Not so much in everything else.',
				},
				{
					id: 'strategist',
					image_path: require(`@/assets/images/archetypes/strategist.jpeg`),
					title: 'Strategist',
					description: 'The strategist is very clever but prone to laziness.',
				},
				{
					id: 'custom',
					image_path: require(`@/assets/images/archetypes/custom.jpeg`),
					title: 'Build your own',
					description: 'Select your preferred skill-set from across 3 different attributes',
				}
			]
		}
	},
	methods: {
		skillSet (archetype) {
			return {
				salesman: {
					physical: 2,
					mental: 2,
					social: 5
				},
				executive: {
					physical: 5,
					mental: 2,
					social: 2
				},
				strategist: {
					physical: 2,
					mental: 5,
					social: 2
				},
				custom: {
					physical: 0,
					mental: 0,
					social: 0
				}
			}[archetype]
		},

		selectArchetype (archetype) {
			this.archetype = archetype
			this.$emit('archetype-select', archetype)
			this.$store.commit('setSkillStartingBase', this.skillSet(archetype))
		},
	}
}
</script>

<style scoped lang="scss">

.card {
	@apply border-2 border-transparent;
	&.is-selected {
		@apply border-2 border-red-800;
	}
}

//

//
//	table {
//		tr:last-child td {
//			border: none;
//		}
//	}
//}
</style>
