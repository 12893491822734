export default class FirstMorningAsWoman {
	constructor (gameData) {
		this.gameData = gameData
	}

	events () {
		return [
			{
				id: 'start',
				speaker: 'Your Bed',
				text: ['You wake up after a good night\'s rest. You haven\'t slept like this in ages.'],
				continue_to: 'perception'
			},
			{
				id: 'perception',
				speaker: 'Perception',
				text: ['It wasn\'t a dream. The tits are still on your chest. You are still a woman'],
				continue_to: 'memory',
			},
			{
				id: 'memory',
				speaker: 'Memory',
				text: ['And most definitely remember being a man'],
				continue_to: 'willpower',
			},
			{
				id: 'willpower',
				speaker: 'Willpower',
				text: ['There\'s no time to waste. Shower, get dressed and go see Roger.'],
				choices: [{
					id: 'get_out_of_bed',
					name: 'Get out of bed'
				}],
			},
			{
				id: 'get_out_of_bed',
				speaker: 'Your Bed',
				text: ['You sit up and get out of the bed. Still naked.'],
				continue_to: 'the_end',
			},
		]
	}

	event (id) {
		return this.events().filter((ev) => {
			return ev['id'] === id
		})[0]
	}

}
