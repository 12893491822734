export default class Home {
	constructor (gameData) {
		this.gameData = gameData
		this.name = 'Home'
		this.bgImage = 'places/apartment.png'
		this.description = 'You are at your one-bedroom studio apartment that you have been living in since you have moved to Palmgrove'
	}

	actions () {
		let actions = [
			{
				name: 'Open your Laptop',
				scene: 'Computer',
				condition: true,
				icon: 'icons8-laptop-100.png'
			},
			{
				name: 'Inspect yourself in the mirror',
				scene: 'Mirror',
				condition: true,
				icon: 'icons8-reflection-100.png'
			},
			{
				name: 'Inspect your wardrobe',
				scene: 'Wardrobe',
				condition: true,
				icon: 'icons8-wardrobe-96.png'
			},
			{
				name: 'Go to bed',
				scene: 'FirstNightSleep',
				condition: this.isSleepTime(this.gameData.currentDateTime.hour()) && !this.gameData.gameAttrs['first_night_slept'],
				icon: 'icons8-sleeping-in-bed-96.png'
			},
			{
				name: 'Go to bed',
				scene: 'FirstNightAsWomanSleep',
				condition: this.isSleepTime(this.gameData.currentDateTime.hour()) && this.gameData.gameAttrs['gender'] === 'female',
				icon: 'icons8-sleeping-in-bed-96.png'
			}
		]
		return actions.filter((element) => {
			return element['condition']
		})
	}

	isSleepTime (hour) {
		return (hour >= 20 || hour < 6)
	}
}
