export default class Office {
    constructor(gameData) {
        this.gameData = gameData
        this.name = 'Office'
        this.bgImage = 'places/office.png'
        this.description = 'You are at the offices of Morse+Slattery, a leading Digital Marketing agency in Palmgrove, FL'
    }

    actions() {
        let actions = [
            {
                name: 'Talk to Jonah and Brenda',
                scene: 'JonahAndBrenda',
                icon: 'icons8-communicate-96.png',
                condition: true,
            },
            {
                name: 'Talk to Pete',
                scene: 'PeteMcLaren',
                icon: 'icons8-communicate-96.png',
                condition: true,
            },
            {
                name: 'Talk to Roger',
                scene: 'RogerSlattery',
                icon: 'icons8-communicate-96.png',
                condition: true,
            },
            {
                name: 'Go to the meeting room (starts meeting with client)',
                scene: 'MilenaMeeting',
                icon: 'icons8-meeting-room-96.png',
                condition: !this.gameData.gameAttrs['milena_meeting_completed'] && this.gameData.currentDateTime.isBetween('2030-04-19 07:00', '2030-04-19 13:00')
            },
        ]

        return actions.filter((element) => {
            return element['condition'];
        })

    }

    isSleepTime(hour) {
        return (hour >= 21 || hour < 6)
    }
}
