export default class CashWithdrawal {
	constructor (gameData) {
		this.gameData = gameData
	}

	events () {
		return [
			{
				id: 'start',
				speaker: 'The ATM',
				text: ['You go to your bank\'s ATM'],
				choices: [{
					id: 'insert_card',
					name: 'Insert the card'
				}]
			},
			{
				id: 'insert_card',
				speaker: 'ATM',
				text: [
					'The machine request you to enter your PIN'
				],
				choices: [{
					id: 'enter_pin',
					name: 'Enter your PIN'
				}]
			},
			{
				id: 'enter_pin',
				speaker: 'ATM',
				text: [
					'The machine request you to enter the amount needed'
				],
				choices: [{
					id: 'enter_amount',
					name: 'Enter the amount ($10,000)'
				}]
			},
			{
				id: 'enter_amount',
				speaker: 'ATM',
				text: [
					'The machine reads: Transaction Rejected. Amount exceeds the daily maximum withdrawable limit of $3000.'
				],
				continue_to: 'analysis'
			},

			{
				id: 'analysis',
				speaker: 'Analysis',
				text: [
					'Ok. That was unexpected. Not to worry. Colby should be able to give you a small loan to cover the rest of the required amount'
				],
				choices: [{
					id: this.gameData.experiencedScripts.includes('CashWithdrawal.enter_amount_3k') ? 'enter_amount_3k_again' : 'enter_amount_3k',
					name: 'Re-enter the amount ($3,000)'
				}]
			},
			{
				id: 'enter_amount_3k',
				speaker: 'Perception',
				text: [
					'There\'s that sweet sound of cash being disbursed to you'
				],
				money_on_hand_change: 3000,
				complete_game_goal: 'get_cash_from_atm',
				set_game_goal: 'borrow_money_from_colby',
				advance_time: 60,
				choices: [{
					id: 'take_cash',
					name: 'Take the cash and leave'
				}]
			},
			{
				id: 'enter_amount_3k_again',
				speaker: 'Perception',
				text: [
					'The machine reads: Transaction Rejected. Amount exceeds the daily maximum withdrawable limit of $3000.'
				],
				choices: [{
					id: 'leave',
					name: 'Leave'
				}]
			},
			{
				id: 'take_cash',
				speaker: 'ATM',
				text: [
					'You take the cash and leave'
				],
				continue_to: 'the_end'
			},
			{
				id: 'leave',
				speaker: 'ATM',
				text: [
					'You leave the ATM'
				],
				continue_to: 'the_end'
			},
		]
	}

	event (id) {
		return this.events().filter((ev) => {
			return ev['id'] === id
		})[0]
	}

}
