export default class AmandaDreamAwakening {
	constructor (gameData) {
		this.gameData = gameData
	}

	events () {
		return [
			{
				id: 'start',
				speaker: 'Analysis',
				text: ['That was a weird fucking dream'],
				choices: [
					{
						id: 'why_did_she_leave',
						name: 'Yes. But why did she leave me?'
					},
					{
						id: 'get_out_of_bed',
						name: 'Get out bed'
					}
				]
			},
			{
				id: 'why_did_she_leave',
				speaker: 'Analysis',
				text: ['I don\'t know. It\'s hard to tell. Women, right? They must be complex creatures'],
				continue_to: 'willpower',
			},
			{
				id: 'willpower',
				speaker: 'Willpower',
				text: ['After all, she loved you by her own admission.You need to find out the reason why she left. It\'s the only way for you to get closure and move on.'],
				set_game_goal: 'find_out_why_amanda_left',
				choices: [
					{
						id: 'get_out_of_bed',
						name: 'Get out bed'
					}
				]
			},
			{
				id: 'get_out_of_bed',
				speaker: 'The Bed',
				text: ['You feel dizzy. You hair feels longer. There are more changes.'],
				set_game_data: { change_score: 2 },
				continue_to: 'feeling_small',
			},
			{
				id: 'feeling_small',
				speaker: 'Perception',
				text: ['You feel smaller - like you shrank. Your pajamas feel oversized on your body.'],
				choices: [
					{
						id: 'look_at_hands',
						name: 'Examine your hands',
					},
				],
			},
			{
				id: 'look_at_hands',
				speaker: 'Perception',
				text: ['They are smaller. Dainty. Something serious is happening to you. You should undress yourself now and assess the full damage.'],
				choices: [
					{
						id: 'undress',
						name: 'Undress yourself',
					},
				],
			},
			this.undressEvent(),
			{
				id: 'carnality_on_boobs',
				speaker: 'Shivers',
				text: ['You\'ve read a lot of TG erotica. Could it be that it\'s happening to you for some reason?'],
				choices: [
					{
						id: 'yes_possible',
						name: 'Yes. It\'s possible. I am turning into a chick. It\'s going to be so hot.',
					},
					{
						id: 'not_possible',
						name: 'No. This could be serious medical issue.'
					}
				],
			},
			{
				id: 'yes_possible',
				speaker: 'Analysis',
				text: ['No. It\'s not possible. This could only be result of wild hormone imbalance. You should be seeing a doctor '],
				choices: [
					{
						id: 'touch_breasts',
						name: 'Touch them',
					},
					{
						id: 'what_else',
						name: 'What else has changed?',
					},

				],
			},
			{
				id: 'not_possible',
				speaker: 'Analysis',
				text: ['That\'s right. This could only be result of wild hormone imbalance. You should be seeing a doctor '],
				choices: [

					{
						id: 'touch_breasts',
						name: 'Touch them',
					},
					{
						id: 'what_else',
						name: 'What else has changed?',
					},

				],
			},
			{
				id: 'touch_breasts',
				speaker: 'Perception',
				text: ['You cup the mounds in your chest and feel them. They feel soft and squishy.'],
				choices: [
					{
						id: 'pinch_nipples',
						name: 'Pinch your nipples',
					},
				],
			},
			{
				id: 'pinch_nipples',
				speaker: 'Perception',
				text: ['A jolt of mildly pleasurable electricity hits your brain. They are sensitive.'],
				choices: [
					{
						id: 'what_else',
						name: 'What else?',
					},
				],
			},
			{
				id: 'what_else',
				speaker: 'Perception',
				text: [
					'You\'ve lost a few inches of height. Definitely a few pounds of weight along with it.',
					'Your shoulders have narrowed. So has your waist making your hips appear wide - Like a woman?',
				],
				continue_to: 'what_else2'

			},
			{
				id: 'what_else2',
				speaker: 'Shivers',
				text: ['Oh god! I don\'t even want to think what\'s next'],
				choices: [
					{
						id: 'what_else3',
						name: 'What else?',
					},
				],
			},
			{
				id: 'what_else3',
				speaker: 'Perception',
				text: [
					'Your penis is smaller. It\'s only 3 inches now. And your balls are noticeably smaller too.',
				],
				choices: [
					{
						id: 'calm_down',
						name: 'Try to calm down',
					},
				],
			},
			{
				id: 'calm_down',
				speaker: 'Shivers',
				text: ['There\'s no way you are calming down'],
				continue_to: 'freak_out',
			},
			{
				id: 'freak_out',
				speaker: 'Shivers',
				text: [
					'You head begins to hurt and you begin to feel hot.',
					'You are getting a fever again.'
				],
				choices: [
					{
						id: 'call_colby',
						name: 'Call Colby',
					},
					{
						id: 'call_911',
						name: 'Call 911',
					},
				],
			},
			{
				id: 'call_colby',
				speaker: 'Phone',
				text: ['... *the phone rings* ...'],
				continue_to: 'call_colby1'
			},
			{
				id: 'call_colby1',
				speaker: 'Phone',
				text: ['... *the phone rings* ...'],
				continue_to: 'call_colby2'
			},
			{
				id: 'call_colby2',
				speaker: 'Shivers',
				text: ['You\'re going to pass out anytime now.'],
				continue_to: 'call_colby3'
			},
			{
				id: 'call_colby3',
				speaker: 'Colby',
				text: ['Hey man!'],
				choices: [
					{
						id: 'going_to_passout',
						name: '"I feel like I am going to die..." (Pass out)',
					},
				],
			},
			{
				id: 'call_911',
				speaker: 'Phone',
				text: ['911. What\'s your emergency?'],
				choices: [
					{
						id: 'going_to_passout911',
						name: '"I feel like I am going to die..." (Pass out)',
					},
				],
			},
			{
				id: 'going_to_passout911',
				speaker: 'The Void',
				advance_time: 60,
				sound_file: 'going_to_passout',
				text: ['Darkness is everywhere'],
				continue_to: 'set_scene:EMTHomeAwakening',
			},
			{
				id: 'going_to_passout',
				speaker: 'The Void',
				advance_time: 60,
				text: ['Darkness is everywhere'],
				continue_to: 'set_scene:ColbyHomeAwakening',
			}
		]
	}

	undressEvent () {
		let node = {
			id: 'undress',
			speaker: 'Perception',
			text: [
				'OH. MY. GOD!',
				'Your chest. It looks like breasts on a developing young woman. With large areolas. And nipples. They are positively feminine.',
			],
		}
		if (this.gameData.autogynephelic) {
			node['continue_to'] = 'carnality_on_boobs'
		} else {
			node['choices'] = [
				{
					id: 'touch_breasts',
					name: 'Touch them',
				},
				{
					id: 'what_else',
					name: 'What else?',
				},

			]
		}
		return node
	}

	event (id) {
		return this.events().filter((ev) => {
			return ev['id'] === id
		})[0]
	}

}
