export default function goalData (goalId) {
	const data = [
		{
			id: 'visit_esmeralda',
			title: 'Visit Esmeralda at 7:30pm today',
			description: 'Your friend Colby insisted you see her and that she could help you. At the very least this should be entertaining',
		},
		{
			id: 'find_out_why_amanda_left',
			title: 'Find out why Amanda left you',
			description: 'The woman you believed to be the love of your life left you all of sudden. Find out why Amanda left you to fill that hole in your soul. (Not implemented yet)',
		}, {
			id: 'find_out_who_z_is',
			title: 'Find out who Z is and what his relationship with Amanda was',
			description: 'You found a note in Amanda\'s stuff insinuating that she was unfaithful to you. You have to get to the bottom of it (Not implemented yet)',
		},
		{
			id: 'find_esmeralda',
			title: 'Find Esmeralda',
			description: 'Esmeralda could possibly be the reason why you changed into a woman. Find her to talk to her about the change. (Not implemented yet)',
		},
		{
			id: 'find_out_the_reason_for_change',
			title: 'Find out the real reason for change',
			description: 'It isn\'t magic or gypsy\'s curse. There\'s a deeper mystery here. (Might take a bit of time to find out)',
		},
		{
			id: 'review_client_profile',
			title: 'Review the client\'s profile that you are expected to receive later today',
			description: 'You are meeting a prospective client tomorrow. It would be useful to learn about them in order to land this deal',
		},
		{
			id: 'setup_a_meeting_with_roger',
			title: 'Text Roger to setup a meeting with him',
			description: 'You need to tell Roger about your sudden change and see if you can still work at this company'
		},
		{
			id: 'meet_roger_at_his_home',
			title: 'Meet Roger at his house',
			description: 'You need to tell Roger about your sudden change and see if you can still work at this company'
		},
		{
			id: 'get_a_new_identity',
			title: 'Get a new identity',
			description: 'You need to get yourself a new identity to get paid, drive a car etc.'
		},
		{
			id: 'talk_to_colby_about_new_identity',
			title: 'Talk to Colby about new identity',
			description: 'Colby might have a few guys who can help you get a new identity'
		},
		{
			id: 'get_identity_from_joes_laundry',
			title: 'Buy identity from Joe\'s Laundry',
			description: 'Visit Joe\'s Laundry to get a new identity'
		},
		{
			id: 'tell_colby_about_change',
			title: 'Tell Colby about your change',
			description: 'You need to get Colby over and tell him about what happened and may be get him to give you a job too'
		},
		{
			id: 'get_cash_from_atm',
			title: 'Get Cash from ATM',
			description: 'You need to get cash from the ATM to pay for your identity'
		},
		{
			id: 'borrow_money_from_colby',
			title: 'Borrow money from Colby',
			description: 'You still need $7000 for getting your identity. Ask your best friend'
		},
		{
			id: 'visit_the_photographer',
			title: 'Visit the Photographer',
			description: 'You need money. He may be has a job for you'
		}
	]

	return data.filter((goal) => {
		return goal['id'] === goalId
	})[0]
}
