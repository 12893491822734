export default class Esmeralda {
    constructor (gameData) {
        this.gameData = gameData
    }

    events () {
        return [
            this.startNode(),

            {
                id: 'shes_hot',
                speaker: 'Allure',
                text: [
                    'Wait! She doesn\'t at look like what you thought. She looks younger and... hotter. Like a hot hippie girl.',
                    'May be that explains the charm'
                ],
                continue_to: 'how_help'
            },

            {
                id: 'how_help',
                speaker: 'Esmeralda',
                text: ['"Now how may Esmeralda help you?"'],
                choices: [
                    {
                        id: 'not_sure',
                        name: '"I am not really sure Ma\'am. My friend compelled me to come. May be you can tell me what you can do?"'
                    },
                    {
                        id: 'no_help_needed',
                        name: '"I really don\'t need any help. My life is perfectly fine"'
                    },
                ]
            },

            {
                id: 'not_sure',
                speaker: 'Esmeralda',
                text: ['"I see. You are a non-believer then."',
                    '"There are many things that I can do. I am guided by the spirits of this world. I can help people from simple things like finding a long lost item to helping them recover from critical illnesses"',
                    '"Surely, there must be something you want? What is it?"'],
                choices: [
                    {
                        id: 'no_help_needed',
                        name: '"I really don\'t need any help. My life is perfectly fine"'
                    },
                ]
            },

            {
                id: 'no_help_needed',
                speaker: 'Esmeralda',
                text: ['"Oh. But I can sense that there are troubles in your life. Recent ones. Troubles that are intimate in nature. Perhaps you feel shy or ashamed even. Don\'t you want to fix your troubles?"'],
                continue_to: 'the_promotion'
            },

            {
                id: 'the_promotion',
                speaker: 'Willpower',
                text: ['She\'s right. You have a motivation problem.',
                    'You have your dream job. You were the front-runner to become the VP. But ever since Amanda left you, you have been slacking. You don\'t care for work. All you do is the basic minimum so that you don\'t get fired.',
                    'It doesn\'t also help that your singular hobby is masturbating to novel forms of pornography every day.'],
                choices: [
                    {
                        id: 'logic_intervenes',
                        name: '"That\'s a real problem that I do have"'
                    },
                ]
            },
            {
                id: 'logic_intervenes',
                speaker: 'Analysis',
                text: [
                    '"It doesn\'t matter what your problems are. She is a charlatan. She can\'t help you. She\'s going to scam you. You should call her out"',
                    '"But then again, what do you have to lose? Except for a little bit of money. May be this will be entertaining."'
                ],
                choices: [
                    {
                        id: 'should_i_make_a_wish',
                        name: '"So how does this work? Should I make a wish or something?"'
                    },
                    {
                        id: 'call_her_out',
                        name: '"So how does your little scam work?"'
                    },

                ]
            },
            {
                id: 'call_her_out',
                skill_required: 'social.assertiveness',
                score_required: 7,
                check_type: 'onetime',
                fail_node: 'call_out_fail',
                speaker: 'Esmeralda',
                text: ['Excuse me?'],
                choices: [
                    {
                        id: 'you_heard_me',
                        name: 'You heard me. What\'s the scam? You sell me an overpriced lucky charm or something?'
                    }
                ]
            },
            {
                id: 'call_out_fail',
                speaker: 'Esmeralda',
                text: ['Excuse me?'],
                continue_to: 'words_stuck'
            },
            {
                id: 'words_stuck',
                speaker: 'Assertiveness',
                text: ['The words are stuck at your throat. Today\'s not your day. Give up and go home. May be she is really powerful and can actually help you.'],
                choices: [
                    {
                        id: 'should_i_make_a_wish',
                        name: 'Nothing. I was just wonder if I should make a wish or something.'
                    }
                ]
            },
            {
                id: 'you_heard_me',
                speaker: 'Esmeralda',
                text: ['How dare you accuse me of being a fraud?, she asks you with a stern tone.'],
                choices: [
                    {
                        id: 'magic_isnt_real',
                        name: '"Magic isn\'t real. You are preying on the gullible members of the society."'
                    },
                    {
                        id: 'magic_isnt_real',
                        name: '"I wasn\'t accusing. I am sort of a salesman too. I actually admire your methods."'
                    },
                ]
            },
            {
                id: 'magic_isnt_real',
                speaker: 'Esmeralda',
                text: [
                    '"Neither I nor the spirits of my ancestors will tolerate your accusations", she says passionately as she stands up.',
                ],
                choices: [
                    {
                        id: 'okay',
                        name: 'Okay..?'
                    }
                ]
            },
            {
                id: 'okay',
                speaker: 'Esmeralda',
                text: [
                    'She tilts her head backwards, lifts her arms in the air and says, "I call upon my ancestors to teach this arrogant man a lesson in humility"'
                ],
                choices: [
                    {
                        id: 'dramatic',
                        name: '"That was dramatic. Nicely done"'
                    },
                    {
                        id: 'dramatic',
                        name: '"Did you just curse me?"'
                    }
                ]
            },
            {
                id: 'dramatic',
                speaker: 'Esmeralda',
                text: ['"It doesn\'t matter. You should leave now Mr. Spade."'],
                xp_gain: 5,
                set_game_data: {
                    'esmeralda_angry_meeting': true,
                    'received_client_profile': true,
                    'client_profile_is_unread': true,
                },
                advance_time: 210,
                complete_game_goal: 'visit_esmeralda',
                continue_to: 'the_end'
            },
            {
                id: 'should_i_make_a_wish',
                speaker: 'Esmeralda',
                text: ['"No. Actually, you have to write down your wish and give it to me."',
                    '"I will then choose the spirit that will guide you to your goals."',
                    '"Then we drop the paper into a bottle containing the spirit"'],
                choices: [{
                    id: 'make_a_wish',
                    name: 'Write down, "I wish to become the Vice President at my company. And I don\'t want to keep stroking my cock all the time and fuck up my work."'
                }]
            },
            {
                id: 'make_a_wish',
                speaker: 'Esmeralda',
                text: [
                    '"That\'s an oddly specific wish. But I have seen worse. Nevertheless, I know just the Spirit who can fix your problems"',
                ],
                choices: [
                    {
                        id: 'you_know_a_spirit',
                        name: 'Wait! You know a *Spirit*? What spirit?'
                    }
                ]
            },
            {
                id: 'you_know_a_spirit',
                speaker: 'Esmeralda',
                text: [
                    'She gets up from her chair, turns around, bends over into a large strong box, putting her big shapely rear into your line of sight',
                ],
                continue_to: 'ass_is_phat'
            },
            {
                id: 'ass_is_phat',
                speaker: 'Deviance',
                text: [
                    'It would be so hot to flip her skirt back, pull down her panties and fuck her from behind',
                    'The thought increases your arousal by a few notches'
                ],
                continue_to: 'you_know_a_spirit2'
            },
            {
                id: 'you_know_a_spirit2',
                speaker: 'Esmeralda',
                text: [
                    'From the strong box, she picks up a bottle and places it on the table.',
                    '"This is the spirit of Ms. Dominique Adler. The notes here say that this is a spirit of a successful Executive Coach.",',
                    '"I think her spirit can guide you to your goals"'
                ],
                choices: [
                    {
                        id: 'is_this_possession',
                        name: '"Does this mean I am going to possessed by her ghost?"'
                    },
                ]
            },
            {
                id: 'is_this_possession',
                speaker: 'Esmeralda',
                text: ['She laughs.',
                    '"Not at all. Spirits are not ghosts. They are fragments of a dead person\'s soul that wasn\'t absorbed by the Universe"'],
                choices: [{
                    id: 'tell_me_more',
                    name: '"Hmmm... Fascinating"'
                },]
            },
            {
                id: 'tell_me_more',
                speaker: 'Esmeralda',
                text: [
                    '"Spirits are less like people and are more like... scarce resources. It\'s a common misconception that "psychics" can talk to spirits."',
                    '"For example, your friend Colby is being guided by the spirit of a very astute salesman. The spirit of Mr. Girard has made him one of the top user car dealers in the city"',
                    '"It is my hope that the spirit of Ms. Adler can do the same for you"'
                ],
                choices: [
                    {
                        id: 'what_do_you_mean_hope',
                        name: '"What do you mean, you hope? I thought that this stuff was supposed to work"'
                    },
                ]
            },
            {
                id: 'what_do_you_mean_hope',
                speaker: 'Esmeralda',
                text: [
                    '"And I thought you were smarter than that. The Spirits are real. But which spirit will work for you and which spirit won\'t - that\'s not an exact science. I am like a Doctor treating a patient. The Doctor prescribes medication based on a theory of the patient\'s condition and the nature of ailment. It doesn\'t work all the time. But mostly, it does"',
                ],
                choices: [
                    {
                        id: 'that_is_profound',
                        name: '"That is very profound. I did not expect you to be so..."'
                    },
                ]
            },
            {
                id: 'that_is_profound',
                speaker: 'Esmeralda',
                text: [
                    '"So educated? I get that all the time from people like yourself"',
                    '"I am guided by the Spirit of a scientist called Feynman. You may have heard of him."'
                ],
                continue_to: 'feynman',
            },
            {
                id: 'feynman',
                speaker: 'Memory',
                text: ['"Of course, you have heard of him. He\'s a Nobel laureate physicist who... wrote some good books among other things"'],
                continue_to: 'release_the_spirit',
            },
            {
                id: 'is_this_all',
                speaker: 'Esmeralda',
                text: ['"There\'s one last thing we need to do. We need to bind the spirit to you."'],
                continue_to: 'release_the_spirit'
            },

            {
                id: 'release_the_spirit',
                speaker: 'Esmeralda',
                text: [
                    '"Now to bind the spirit to you, all I need is a strand of your hair. Once I drop it in the bottle, Ms. Adler\'s spirit can never rest until it has helped you achieve your goal."'
                ],
                choices: [
                    {
                        id: 'provide_hair',
                        name: 'Give her a strand of your hair'
                    },
                ]
            },
            {
                id: 'provide_hair',
                speaker: 'Esmeralda',
                text: [
                    'She drops the hair in the bottle and seals it shut',
                    '"There. It\'s done! That will be $50"'
                ],
                choices: [{
                    id: 'pay_her',
                    name: 'Pay her the $50'
                },
                    {
                        id: 'think_about_not_paying',
                        name: 'Think about not paying'
                    },]
            },
            {
                id: 'think_about_not_paying',
                speaker: 'Analysis',
                text: ['"If you refused payment, she will simply remove the hair and that will be all. $50 is nothing to you. May be it\'s worth a try"'],
                choices: [
                    {
                        id: 'pay_her',
                        name: 'Pay her the $50'
                    },
                ]
            },
            {
                id: 'pay_her',
                speaker: 'Esmeralda',
                set_game_data: {
                    'received_client_profile': true,
                    'client_profile_is_unread': true,
                },
                advance_time: 210,
                money_on_hand_change: -50,
                complete_game_goal: 'visit_esmeralda',
                text: ['"Many thanks, kind sir"'],
                continue_to: 'the_end'
            }
        ]
    }

    startNode () {
        if (this.gameData.metEsmeralda && !this.gameData.esmeraldaAngryMeeting) {
            return {
                id: 'start',
                text: ['"It\'s time for you to leave Mr. Spade. There are other people waiting for me. I hope you will understand"'],
                sound_file: 'talk_again',
                speaker: 'Esmeralda',
                continue_to: 'the_end'
            }
        } else if (this.gameData.metEsmeralda && this.gameData.esmeraldaAngryMeeting) {
            return {
                id: 'start',
                text: ['"You are not welcome here Mr. Spade. Please leave."'],
                speaker: 'Esmeralda',
                continue_to: 'the_end'
            }
        } else {
            return {
                id: 'start',
                text: ['"Welcome Mr. Nathan Spade"'],
                speaker: 'Esmeralda',
                continue_to: 'shes_hot'
            }
        }

    }

    event (id) {
        return this.events().filter((ev) => {
            return ev['id'] === id
        })[0]
    }

}
