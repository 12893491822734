export default class AllWomanNow {
	constructor (gameData) {
		this.gameData = gameData
	}

	events () {
		return [
			{
				id: 'start',
				speaker: 'Perception',
				text: ['You lay there in your bed under a blanket. You can feel that you are naked.'],
				continue_to: 'all_woman_now',
			},
			{
				id: 'all_woman_now',
				speaker: 'Analysis',
				sound_file: this.gameData.postTFsaviour === 'Colby' ? 'all_woman_now_colby' : 'all_woman_now_emt',
				text: [
					`Based on what ${this.gameData.postTFsaviour} told you, it seems that your sudden transformation into a woman is complete.`,
					'It\'s time to take a look at what you\'ve become.'
				],
				choices: [
					{
						id: this.gameData.autogynephelic ? 'remove_the_blanket-pre' : 'remove_the_blanket',
						name: 'Remove the blanket'
					}
				],
			},
			{
				id: 'remove_the_blanket-pre',
				speaker: 'Shivers',
				text: [
					'So I was right after all. You\'ve become a chick now. You read all the TG erotica that you could get and it seems that you are living the fantasy.',
					'How do you feel?'
				],
				choices: [
					{
						id: 'excited',
						name: 'I\'ll be honest. I am excited.'
					},
					{
						id: 'scared',
						name: 'I am scared'
					},
				]
			},
			{
				id: 'excited',
				speaker: 'Shivers',
				text: ['Yes. I see a future filled with form-fitting dresses, bras, panties, fancy lingerie, and bikinis.'],
				choices: [
					{
						id: 'remove_the_blanket',
						name: 'Sure. Let\'s check out the package',
					},
				],
			},
			{
				id: 'scared',
				speaker: 'Shivers',
				text: ['Scared. May be it\'s your new hormones.'],
				choices: [
					{
						id: 'remove_the_blanket',
						name: 'What? No! Remove the blanket',
					},
				],
			},
			{
				id: 'remove_the_blanket',
				speaker: 'Perception',
				image_path: 'Wttu0J9.png',
				text: [
					'You cast aside the blanket and lean slightly forward to get a look at your crotch',
					'And you immediately feel it - the weight of your new breasts',
					'Two large mounds of flesh jiggle on your chest.',
				],
				choices: [
					{
						id: 'feel_them',
						name: 'Feel them',
					},
					{
						id: 'nvm_get_out_of_bed',
						name: 'Nevermind. Get out of bed'
					}
				],
			},
			{
				id: 'feel_them',
				speaker: 'Perception',
				image_path: 'Vv8HsXu.png',
				text: [
					'You gently hold them each of your hands.',
					'The only other pair of breasts you\'ve held is Amanda\'s. But holding your own breasts in your hands, it feels like yours are bigger than hers. They are too big even for your own hands.'
				],
				choices: [
					{
						id: 'squeeze_them',
						name: 'Squeeze them',
					},
					{
						id: 'nvm_get_out_of_bed',
						name: 'Nevermind. Get out of bed'
					}
				],
			},
			{
				id: 'squeeze_them',
				speaker: 'Perception',
				image_path: 'foPcJdY.png',
				text: [
					'You slowly press your tits and move them around and feel the weight as they hang off your chest',
					'It feels soft and fatty on the surface. But as you apply more pressure on them, you can feel something harder.'
				],
				continue_to: 'glands',
			},
			{
				id: 'glands',
				speaker: 'Analysis',
				text: ['Those would be your mammary glands. You know what they are used for.'],
				continue_to: 'milk_factory',
			},
			{
				id: 'milk_factory',
				speaker: 'Shivers',
				text: ['You now have milk factories in your chest. Big ones.'],
				choices: [
					{
						id: 'pinch_your_nipples',
						name: 'Pinch your nipples'
					},
					{
						id: 'nvm_get_out_of_bed',
						name: 'Nevermind. Get out of bed'
					}
				],
			},
			{
				id: 'pinch_your_nipples',
				speaker: 'Perception',
				text: ['That felt good. A strange hot sensation originates in your loins.'],
				image_path: 'TUJNxAM.png',
				choices: [
					{
						id: 'feel_your_crotch1',
						name: 'Feel your crotch'
					},

					{
						id: 'nvm_get_out_of_bed',
						name: 'Nevermind. Get out of bed'
					}
				],
			},
			{
				id: 'feel_your_crotch1',
				speaker: 'Perception',
				image_path: 'qQSHmgO.png',
				text: [
					'You reach your hand down to your crotch fully expecting emptiness.',
					'There is a small mound where your penis would have been.',

				],
				continue_to: 'feel_your_crotch2',
			},
			{
				id: 'feel_your_crotch2',
				speaker: 'Perception',
				image_path: 'CglKpnw.png',
				text: [
					'Sliding down further, the skin starts to split into a cleft.',
					'You instinctively lay back spread your legs to enable further explorations down under.',
				],
				continue_to: 'feel_your_crotch3',
			},
			{
				id: 'feel_your_crotch3',
				speaker: 'Shivers',
				image_path: 'Wuz2LDx.png',
				text: [
					'As you slide down, you rub over a small nub. Immediately an electric feeling washes over your entire body making the hair on your skin raise. You just rubbed your clitoris.',
				],
				continue_to: 'feel_your_crotch4'
			},
			{
				id: 'feel_your_crotch4',
				speaker: 'Perception',
				image_path: 'f5M0FuI.png',
				text: [
					'Going down further, you find your honeypot. Your fuck hole. It\'s wet with your pussy juice',
				],
				choices: [
					{
						id: 'continue_exploring',
						name: "You should know what it feels like - as a woman."
					},
					{
						id: 'nvm_get_out_of_bed',
						name: 'Nevermind. Get out of bed'
					},
				],
			},
			{
				id: 'continue_exploring',
				speaker: 'Deviance',
				skill_required: 'mental.deviance',
				score_required: 9,
				fail_node: 'not_important_now',
				text: [
					"Of course. It's exciting. You want to know what it feels like for a woman."
				],
				choices: [
					{
						id: 'further_down',
						name: 'Go further down',
					},
				],
			},
			{
				id: 'further_down',
				speaker: 'Perception',
				text: [
					'You slide your hands easily inside thanks to all the wetness',
					'You try to reach further down, but it seems your vaginal cavity is deeper than your fingers can reach',
				],
				choices: [
					{
						id: 'feel_the_clit_again',
						name: 'Feel the clitoris again',
					},
				],
			},
			{
				id: 'feel_the_clit_again',
				speaker: 'Perception',
				text: [
					'You slide back out and feel the small nub again to feel a small wave of pleasure hit your body',
				],
				choices: [
					{
						id: 'start_rubbing_it',
						name: 'Rub your clit',
					},
				],
			},
			{
				id: 'start_rubbing_it',
				speaker: 'Perception',
				text: [
					'You start to rub your clit slowly at first. Then you begin to increase the pace as you feel the pleasure building up.',
				],
				choices: [
					{
						id: 'continue_rubbing',
						name: 'Continue rubbing it',
					},
				],
			},
			{
				id: 'continue_rubbing',
				speaker: 'Perception',
				text: [
					'You start to rub your clit slowly at first. Then you begin to increase the pace as you feel the pleasure building up in your entire body.',
				],
				choices: [
					{
						id: 'continue_rubbing1',
						name: 'Continue rubbing it',
					},
				],
			},
			{
				id: 'continue_rubbing1',
				speaker: 'Perception',
				text: [
					'As the sensations flood your mind, you instinctively bite your lip and reach for your breasts and start stimulating your now hard nipples with one hand while the other one frantically rubs across your sensitive clit.',
				],
				choices: [
					{
						id: 'moaning',
						name: '(Moan)',
					},
				],
			},
			{
				id: 'moaning',
				speaker: 'Deviance',
				text: [
					'You just moaned. Like a woman.',
					'Hearing your own voice like that only makes your hornier pushing your over to edge toward the big release.',
					'It could be here. Anytime now. Your first one as a woman.'
				],
				choices: [
					{
						id: 'climax',
						name: 'Surrender to it',
					},
				],
			},
			{
				id: 'climax',
				speaker: 'Perception',
				text: [
					'A huuge wave of pleasure hits you. You feel it all over your body. The big O - It\'s here',
					'You lie back on the bed, feeling warm and giddy. Slightly sleepy, even.'
				],
				continue_to: "post-climax-guilt",
			},
			{
				id: 'post-climax-guilt',
				speaker: 'Willpower',
				text: [
					"Don't even think about it. You need to figure out the shit that you're in. Not jerking off like you just did.",
					"Go to the mirror now."
				],
				continue_to: "go_to_mirror",
			},
			{
				id: 'not_important_now',
				speaker: 'Shivers',
				text: ["That's not important now. There're more important things than this. Go to the mirror now."],
				choices: [
					{
						id: 'go_to_mirror',
						name: 'Go to the mirror',
					},
				],
			},
			{
				id: 'nvm_get_out_of_bed',
				speaker: 'Shivers',
				text: ['You need to go to the mirror now. Stat.'],
				choices: [
					{
						id: 'go_to_mirror',
						name: 'Go to the mirror',
					},
				],
			},
			{
				id: 'go_to_mirror',
				speaker: 'Mirror',
				image_path: 'PbEi0km.png',
				set_pc_image: 'people/nathan-fem.png',
				set_game_data: {
					gender: 'female',
					clothing: 'none',
					nameDescription: 'You are Nathan Spade.',
					workDescription: 'You worked as a Senior Designer at Morse+Slattery, a Digital Marketing consultancy. But now that you suddenly became a woman. It\'s no longer clear who you are and what job you have',
					headDescription: 'You have an attractive feminine face with medium length hair.',
					bodyDescription: 'Thanks to your mysterious transformation, on your lean body, you have an ample bosom, narrow waist, wide hips, and a round ass.',
				},
				xp_gain: 5,
				advance_time: 30,
				text: [
					'You stand before a mirror and take a look at yourself',
					'You could not be any taller than 5 feet and 3 inches',
					'Your hair has grown reaching below your shoulders.',
					'Your tits are ample. Given how they hang to the sides, you get the feeling that they would be visible from behind.',
					'Your waist is narrow. Your hips are wide giving your body an attractive feminine shape.',
					'Your thighs are thicker than you remember, thanks to all the fat the female body is configured to store in the thighs and buttocks.',
					'Between your legs your new sex silently exists without making it\'s presence felt.',
					'And finally, you are cute as a button',
				],
				continue_to: 'the_end',
			}
		]
	}

	event (id) {
		return this.events().filter((ev) => {
			return ev['id'] === id
		})[0]
	}

}
