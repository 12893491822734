export default class RogerSlattery {
    constructor (gameData) {
        this.gameData = gameData
    }

    events () {
        return [
            this.startNode(),
        ]
    }

    startNode () {
        if (this.gameData.rogerBackstoryConversationCompleted) {
            return {
                id: 'start',
                speaker: 'Roger',
                text: ['I am busy right now, Nathan. Whatever it is that you want to discuss, we can discuss after the meeting.'],
                continue_to: 'the_end'
            }
        } else {
            return {
                id: 'start',
                speaker: 'Roger',
                text: ['You walk up to the office of Roger Slattery, your boss and head of the Palmgrove Branch of Morse+Slattery. It\'s currently empty'],
                continue_to: 'the_end'
            }
        }
    }

    event (id) {
        return this.events().filter((ev) => {
            return ev['id'] === id
        })[0]
    }

}
