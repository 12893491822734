export default class FirstNightSleep {
	constructor (gameData) {
		this.gameData = gameData
	}

	events () {
		return [
			{
				id: 'start',
				speaker: 'Your Bed',
				text: ['You climb into your bed and close your eyes'],
				choices: this.sleepChoices()
			},
			{
				id: 'get_out',
				speaker: 'Your bed',
				text: ['You get out of your bed'],
				continue_to: 'the_end',
			},
			{
				id: 'sleep',
				speaker: 'Your Bed',
				text: ['You sleep peacefully for now'],
				advance_time: this.timeToAdvance(),
				continue_to: 'set_scene:FirstBodyChange',
			},
			{
				id: 'esmeralda-scene',
				speaker: 'The Bed',
				text: this.sceneFirstContent(),
				image_path: '7oVukWc.jpg',
				choices: [
					{
						id: 'esmeralda-scene-1',
						name: 'Continue imagining her'
					},
					{
						id: 'esmeralda-alt-scene-prologue',
						name: 'Continue imagining her. But imagine that you were her.'
					},
				],
			},
			{
				id: 'esmeralda-alt-scene-prologue',
				speaker: 'Deviance',
				text: ['It\'s your dirty little secret. Your kink is that you like to imagine yourself as a woman. Not that you don\'t like having sex with women or that you are gay. But for some strange reason that god only knows, you seem to be aroused easily by imagining yourself as a hot chick.'],
				set_game_data: { 'autogynephelic': true },
				continue_to: 'esmeralda-alt-scene-1'
			},
			{
				id: 'esmeralda-scene-1',
				speaker: 'The Bed',
				text: this.sceneSecondContent(),
				image_path: 'sWJrMBR.jpg',
				continue_to: 'esmeralda-scene-2',
			},
			{
				id: 'esmeralda-alt-scene-1',
				speaker: 'The Bed',
				text: this.sceneSecondContentAlt(),
				image_path: 'sWJrMBR.jpg',
				continue_to: 'esmeralda-alt-scene-2',
			},
			{
				id: 'esmeralda-scene-2',
				speaker: 'The Bed',
				text: [
					'You conjure an image of how she would look on all fours.',
					'Her tits would be hanging freely as her pert posterior would be enticingly positioned',
					'As you get close to climax, you get out of the bed and make your way into the bathroom with your erect dick firmly in your hand'
				],
				image_path: '9ZOsCYo.jpg',
				continue_to: 'esmeralda-scene-3',
			},
			{
				id: 'esmeralda-alt-scene-2',
				speaker: 'The Bed',
				text: [
					'You conjure an image of how you would look on all fours.',
					'You would feel the weight of your tits would be hanging freely as your tight posterior would be enticingly positioned',
					'As you get close to climax, you get out of the bed and make your way into the bathroom with your erect dick firmly in your hand'
				],
				image_path: '9ZOsCYo.jpg',
				continue_to: 'esmeralda-alt-scene-3',
			},
			{
				id: 'esmeralda-scene-3',
				speaker: 'The Bathroom',
				text: [
					'Once you are in the bathroom, you bring the same image to your head',
					'All you would now need is to pull those panties down, and stick your hard cock inside her pussy and pound against her soft ass',
					'Just as you think about it, you reach an intense orgasm, shooting your load all across the bathroom',
				],
				image_path: 'tbnMQXO.jpg',
				continue_to: 'esmeralda-post-orgasm-1',
			},
			{
				id: 'esmeralda-alt-scene-3',
				speaker: 'The Bathroom',
				text: [
					'Once you are in the bathroom, you bring the same image to your head',
					'All you would now need is for a man to pull your panties down, and stick his hard cock inside your pussy and pound your soft ass',
					'Just as you think about it, you reach an intense orgasm, shooting your load all across the bathroom',
				],
				image_path: 'tbnMQXO.jpg',
				continue_to: 'esmeralda-post-orgasm-1',
			},
			{
				id: 'esmeralda-post-orgasm-1',
				set_game_data: {
					'jerked_off_to_esmeralda': true,
				},
				speaker: 'Shivers',
				text: [
					'That was hot. And fun',
				],
				continue_to: 'esmeralda-post-orgasm-2'
			},
			{
				id: 'esmeralda-post-orgasm-2',
				speaker: 'Willpower',
				text: [
					'"Like I keep saying, you should get out more often. Real life is far more exciting that porn. Look at those glorious pearly white loads brimming with your vitality"'
				],
				choices: [{
					id: 'esmeralda_jerk_off_end',
					name: 'That may very well be. But I need to get some sleep right now'
				}]
			},
			{
				id: 'esmeralda_jerk_off_end',
				speaker: 'The Bathroom',
				text: ['You exit the bathroom'],
				continue_to: 'the_end',
			}
		]
	}

	sceneFirstContent () {
		if (this.gameData.esmeraldaAngryMeeting) {
			return [
				'Lying down in your bed, you close your eyes and slide your hand inside your pants and gently clutch your semi-erect penis',
				'You recollect your best image of Esmeralda in your head and imagine her in the sultriest version of her outfit',
				'The fact that your meeting turned into an unfriendly confrontation only makes this hotter',
				'You proceed to stroking your cock gently as you try to imagine more of her'
			]
		} else {
			return [
				'Lying down in your bed, you close your eyes and slide your hand inside your pants and gently clutch your semi-erect penis',
				'You recollect your best image of Esmeralda in your head and imagine her in the sultriest version of her outfit',
				'You proceed to stroking your cock gently as you try to imagine more of her'
			]
		}
	}

	sceneSecondContent () {
		if (this.gameData.esmeraldaAngryMeeting) {
			return [
				'Based on how her hips looked, you could tell she should have had a cute little bum. The kind that is small yet shapely.',
				'You picture her turned sideways as she displays her beautiful curves.',
				'The precum from your cock has made your stroking smooth and effortless'
			]
		} else {
			return [
				'Yes. She had a beautiful pert ass. You noticed it when she bent over to search her strong box. It was small yet shapely.',
				'You picture her turned sideways as she displays her beautiful curves.',
				'The precum from your cock has made your stroking smooth and effortless'
			]
		}

	}

	sceneSecondContentAlt () {
		if (this.gameData.esmeraldaAngryMeeting) {
			return [
				'You have a beautiful pert ass - small yet shapely.',
				'You picture yourself turned sideways displaying your beautiful curves.',
				'The precum from your cock has made your stroking smooth and effortless'
			]
		} else {
			return [
				'You have a beautiful pert ass - small yet shapely. It would appear round and plump if you bent over like she did to search her stong box.',
				'You picture yourself turned sideways displaying your beautiful curves.',
				'The precum from your cock has made your stroking smooth and effortless'
			]
		}

	}

	sleepChoices () {
		let choices = []

		if (!this.gameData.jerkedOffToEsmeralda) {
			choices.push({
				id: 'esmeralda-scene',
				name: 'Jerk off to your mental image of Esmeralda'
			})
		}
		choices.push({
			id: 'sleep',
			name: `Go to Sleep`,
		})
		return choices
	}

	timeToAdvance () {
		let wakingTime = this.gameData.currentDateTime.add(1, 'day').hour(8).minute(0)
		return (wakingTime.diff(this.gameData.currentDateTime, 'minutes'))
	}

	event (id) {
		return this.events().filter((ev) => {
			return ev['id'] === id
		})[0]
	}

}
