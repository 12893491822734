import Home from './locations/home'
import City from './locations/city'
import GypsyTent from './locations/gypsy_tent'
import Office from './locations/office'
import GapStore from './locations/gap_store'
import StripClub from './locations/strip_club'
import FitnessZone from './locations/fitness_zone'
import ATM from './locations/atm'
import ColbysApartment from './locations/colbys_apartment'
import NielsApartment from './locations/niels_apartment'
import RogersHouse from './locations/rogers_house'
import JoesLaundry from './locations/joes_laundry'
import Colby from './interactions/colby'

import Esmeralda from './interactions/esmeralda'
import ColbyFirstCall from './interactions/colby_first_call'
import Computer from './interactions/computer'
import Mirror from './interactions/mirror'
import Wardrobe from './interactions/wardrobe'
import Phone from './interactions/phone'
import FirstNightSleep from './interactions/first_night_sleep'
import FirstBodyChange from './interactions/first_body_change'
import JonahAndBrenda from './interactions/jonah_and_brenda'
import PeteMcLaren from './interactions/pete_mclaren'
import RogerSlattery from './interactions/roger_slattery'
import MilenaMeeting from './interactions/milena_meeting'
import Sickness from './interactions/sickness'
import AmandaDream from './interactions/amanda_dream'
import AmandaDreamAwakening from './interactions/amanda_dream_awakening'
import ColbyHomeAwakening from './interactions/colby_home_awakening'
import EMTHomeAwakening from './interactions/emt_home_awakening'
import AllWomanNow from './interactions/all_woman_now'
import EndOfContent from './interactions/end_of_content'
import Reorganizing from './interactions/reorganizing'
import MenCollection from './interactions/men_collection'
import WomenCollection from './interactions/women_collection'
import StripClubBouncer from './interactions/strip_club_bouncer'
import CoachPaulson from './interactions/coach_paulson'
import FirstNightAsWomanSleep from './interactions/first_night_as_woman_sleep'
import FirstMorningAsWoman from './interactions/first_morning_as_woman'
import RogerHomeMeeting from './interactions/roger_home_meeting'
import RogerJobUpdate from './interactions/roger_job_update'
import ColbyIdentityTalk from './interactions/colby_identity_talk'
import OldManJoe from './interactions/old_man_joe'
import CashWithdrawal from './interactions/cash_withdrawal'
import Niels from './interactions/niels'
import KatjaAtNiels from './interactions/katja_at_niels'
import PhotoShoot from './interactions/photoshoot'

const classes = {
	Home,
	City,
	GypsyTent,
	Office,
	GapStore,
	FitnessZone,
	StripClub,
	ATM,
	ColbysApartment,
	RogersHouse,
	JoesLaundry,
	NielsApartment,

	Colby,
	ColbyFirstCall,
	Computer,
	Mirror,
	Wardrobe,
	Phone,
	Esmeralda,
	FirstNightSleep,
	FirstBodyChange,
	JonahAndBrenda,
	PeteMcLaren,
	RogerSlattery,
	MilenaMeeting,
	Sickness,
	AmandaDream,
	AmandaDreamAwakening,
	ColbyHomeAwakening,
	EMTHomeAwakening,
	AllWomanNow,
	MenCollection,
	WomenCollection,
	StripClubBouncer,
	Reorganizing,
	CoachPaulson,
	FirstNightAsWomanSleep,
	FirstMorningAsWoman,
	RogerHomeMeeting,
	RogerJobUpdate,
	ColbyIdentityTalk,
	OldManJoe,
	CashWithdrawal,
	Niels,
	KatjaAtNiels,
	PhotoShoot,

	EndOfContent
}

export default function loadScript (name, data) {
	return new classes[name](data)
}
